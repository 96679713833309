import React, { useCallback, useRef } from "react";
import { useRefresher } from "../../../core/tools/Hooks";
import {
  AvailableMaterialItemDto,
  BomMaterialDetailsDto,
} from "../../../core/api/generated/warehouse";
import { PropsSQLWHBOMAvailableMaterialsResponse } from "../../../core/api/generated/conterra";
import DashboardGrid from "../../Dashboard/Grid";
import { IColumnSetting } from "../../Dashboard/interfaces";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { IAvailableMaterial } from "./interfaces";

export const MaterialsSelect = (props: {
  bomMaterials: BomMaterialDetailsDto[];
  allMaterials: IAvailableMaterial[];
  onChange: (value: number[]) => void;
}) => {
  const forceRefresher = useRefresher();
  const { bomMaterials, allMaterials } = props;
  const filteredDataRef = useRef<IAvailableMaterial[]>([]);
  const selectedIdsRef = useRef<number[]>([]);

  const OnChangeCheckAll = useCallback(
    (e: CheckboxChangeEvent) => {
      const { value: selectedAll } = e.target;
      selectedIdsRef.current = !selectedAll
        ? []
        : filteredDataRef.current.map((item) => item.id);
      props.onChange(selectedIdsRef.current);
      forceRefresher();
    },
    [props.onChange, forceRefresher]
  );

  const OnChangeCheck = useCallback(
    (e: CheckboxChangeEvent) => {
      const { value: selected, name: id } = e.target;
      if (selected) {
        selectedIdsRef.current.push(+id!);
      } else {
        selectedIdsRef.current = selectedIdsRef.current.filter(
          (sId) => sId !== +id!
        );
      }
      props.onChange(selectedIdsRef.current);
      forceRefresher();
    },
    [props.onChange, forceRefresher]
  );

  const renderCheckAll = useCallback(() => {
    return (
      <div style={{ textAlign: "center", pointerEvents: "all", width: 32 }}>
        <Checkbox
          id={"all-materials-check"}
          name={"all"}
          label={" "}
          onChange={OnChangeCheckAll}
        />
      </div>
    );
  }, [OnChangeCheckAll]);

  const renderColumnCheck = useCallback(
    ({ id }: AvailableMaterialItemDto) => {
      const checked =
        selectedIdsRef.current.findIndex((selectedId) => id === selectedId) >
        -1;
      return (
        <div style={{ textAlign: "center", pointerEvents: "all" }}>
          <Checkbox
            id={id + "check"}
            name={id + ""}
            label={" "}
            value={checked}
            checked={checked}
            onChange={OnChangeCheck}
          />
        </div>
      );
    },
    [OnChangeCheck]
  );

  const columnsRef = useRef<IColumnSetting[]>([
    {
      field: "checked",
      title: " ",
      type: "string",
      filterable: false,
      columnMenu: false,
      sortable: false,
      format: {
        type: "string",
        customFormat: true,
        getCustomFormat: renderColumnCheck,
      },
      titleHTML: renderCheckAll(),
      gridWidth: 40,
    },
    {
      field: PropsSQLWHBOMAvailableMaterialsResponse.category,
      title: "Category",
      type: "string",
      gridWidth: 150,
      format: {
        type: "string",
        fieldId: PropsSQLWHBOMAvailableMaterialsResponse.categoryId,
      },
    },
    {
      field: PropsSQLWHBOMAvailableMaterialsResponse.name,
      title: "Material",
      type: "string",
      gridWidth: 300,
      format: {
        type: "link",
        fieldId: PropsSQLWHBOMAvailableMaterialsResponse.id,
        refName: "FSMMaterials",
      },
    },
    {
      field: PropsSQLWHBOMAvailableMaterialsResponse.title,
      title: "Title",
      type: "string",
      gridWidth: 270,
      format: {
        type: "string",
      },
    },
    {
      field: PropsSQLWHBOMAvailableMaterialsResponse.uom,
      title: "UOM",
      type: "string",
      gridWidth: 100,
      format: {
        type: "string",
        fieldId: PropsSQLWHBOMAvailableMaterialsResponse.uomId,
      },
    },
    {
      title: "Available QTY",
      field: "",
      type: "parent",
      columns: [
        {
          field: PropsSQLWHBOMAvailableMaterialsResponse.warehouseQTY,
          title: "WH",
          type: "number",
          gridWidth: 90,
          format: {
            type: "integer",
          },
        },
        {
          field: PropsSQLWHBOMAvailableMaterialsResponse.totalQTY,
          title: "Total   ", // additional symbols in title needs for not
          // collapsing columns
          type: "number",
          gridWidth: 90,
          format: {
            type: "integer",
          },
        },
      ],
    },
    {
      field: PropsSQLWHBOMAvailableMaterialsResponse.cogs,
      title: "COGS $",
      type: "number",
      gridWidth: 100,
      format: {
        type: "currency",
      },
    },
    {
      field: PropsSQLWHBOMAvailableMaterialsResponse.internalPartNo,
      title: "Internal Part #",
      type: "string",
      gridWidth: 120,
      format: {
        type: "string",
        fieldId: PropsSQLWHBOMAvailableMaterialsResponse.internalPartNo,
      },
    },
    {
      field: PropsSQLWHBOMAvailableMaterialsResponse.preferredVendor,
      title: "Preffered Vendor",
      type: "string",
      gridWidth: 120,
      format: {
        type: "string",
        fieldId: PropsSQLWHBOMAvailableMaterialsResponse.preferredVendorId,
      },
    },
    {
      field: PropsSQLWHBOMAvailableMaterialsResponse.vendorPartNumbers,
      title: "Vendor Part #",
      type: "string",
      gridWidth: 120,
      format: {
        type: "string",
        fieldId: PropsSQLWHBOMAvailableMaterialsResponse.vendorPartNumbers,
      },
    },
    {
      field: PropsSQLWHBOMAvailableMaterialsResponse.isSNRequired + "Str",
      title: "SN Required",
      type: "string",
      gridWidth: 80,
      format: {
        type: "string",
        fieldId: PropsSQLWHBOMAvailableMaterialsResponse.isSNRequired,
      },
    },
  ]);

  const GetData = useCallback(async () => {
    return allMaterials.filter(
      (material) =>
        bomMaterials.findIndex((bm) => bm.material.id === material.id) === -1
    );
  }, [bomMaterials]);

  return (
    <div style={{ height: 550, margin: -8 }}>
      <DashboardGrid
        isActive={true}
        isParentPage={true}
        columnsSettings={columnsRef.current}
        getData={GetData}
        getRowKey={({ id }: AvailableMaterialItemDto) => id.toString()}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        onChangeGridData={(data: IAvailableMaterial[]) =>
          (filteredDataRef.current = data)
        }
      ></DashboardGrid>
    </div>
  );
};
