import {
  Button,
  DropDownButton,
  DropDownButtonItemClickEvent,
  ToolbarSpacer,
} from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import {
  DataResult,
  filterBy,
  process,
  SortDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { SplitPane } from "react-multi-split-pane";
import moment from "moment";
import debounce from "lodash.debounce";
import BaseComponent from "../../../Components/BaseComponent";
import { IComboboxItem, simpleObject } from "../../../helpers/interfaces";
import { RunScriptAsync } from "../../../helpers/runscripts";

import Loader from "../../../Components/Common/Loader";
import ClearableInput from "../../../Components/Common/Form/ClearableInput";
import {
  IColumnValue,
  IGridFilter,
  IGridFilterItem,
} from "../../../Components/Dashboard/interfaces";
import BooleanFilter from "../../../Components/Dashboard/BooleanFilter";
import {
  DEFAULT_OPERATOR,
  GetDefaultGridFilter,
  GridRowHeight,
  IsComplexGridFilter,
} from "../../../Components/Dashboard/helpers";
import ComboboxFilterVirtual from "../../../Components/Dashboard/ComboboxFilterVirtual";
import CustomColumnMenu from "../../../Components/Dashboard/ColumnMenu";
import gridStyles from "../../../Components/Dashboard/dashboard.module.scss";
import ComboboxFilter from "../../../Components/Dashboard/ComboboxFilter";
import { ModalRef } from "../../../Components/Common/Modal/Modal";

import {
  downloadCLResultsAction,
  IColumn,
  IDocumentItem,
  IFileItem,
  IUpdateIncludedParams,
  props,
} from "./interfaces";
import ChecklistResultHistory from "./ChecklistResultHistory"; // todo rename  ChecklistResultInfo
import ChecklistResultCarousel from "./PreviewerCarousel";
import IncludeCheckbox from "./IncludedCheckBox";
import styles from "./checklistResults.module.scss";
import React from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import {
  CreateResultFromVersion,
  FIELD_DETAIL_LIST,
  GetContextMenuItems,
  GetMultiZipDownloadURL,
  HAS_MEDIA_LIST,
  IsDisableChangeIncluded,
  SendCliIncluded,
  TYPES,
  UpdateCliComments,
} from "./helpers";
import CLPMSettings from "../../../stores/CLPMSettings";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import {
  GetDocumentUrl,
  showSomeError,
  UpdateDocument,
} from "../../../helpers/helpers";
import {
  ICLMSettings,
  ICLPMBPItem,
  ICLPMWOItem,
} from "../../../stores/interfaces";
import api from "../../../core/api/api";
import config from "../../../config";
import { settingsStorage } from "../../../helpers/settings";
import CardManagement from "../../../Components/Cards/CardManagement";

interface state {
  bploading: boolean;
  loading: boolean;
  documentLoading: boolean;
  imgLoading: boolean;
  documents: DataResult;
  sort: SortDescriptor[];
  buildPlans: Array<IComboboxItem>;
  filteredWorkOrders: ICLPMWOItem[];
  gridFilter: IGridFilter;
  contextMenu: null | { left: number; top: number };
  currentFileId: number | null;
  disableNext: boolean;
  disablePrev: boolean;
  remountFilterKey: number;
  gridLoading: boolean;
  collapsedIds: string[];
}

class Index extends BaseComponent<props, state> {
  contextMenuItem: IDocumentItem | null = null;
  columns: Array<IColumn> = [];
  gridRef: any;
  bpId: number | undefined = this.props.buildPlanId
    ? +this.props.buildPlanId
    : undefined;
  woId: number | undefined = this.props.workOrderId
    ? +this.props.workOrderId
    : undefined;
  selectedBP: ICLPMBPItem | undefined;
  selectedWorkOrder: ICLPMWOItem | undefined = undefined;
  selectedDocument: IDocumentItem | undefined;
  currentFileId: number | null = null;
  group: Array<{ field: string; dir?: "asc" | "desc" }> = [
    {
      field: "groupName",
      dir: "asc",
    },
  ];
  sort: SortDescriptor[] = [];
  gridFilter: IGridFilter = GetDefaultGridFilter();
  expandAll: boolean = false;
  filterChangeTimeout: any;
  documents: Array<IDocumentItem> = [];
  columnValues: { [key: string]: Array<IColumnValue> } = {
    status: [],
    HasMedia: HAS_MEDIA_LIST.map((item) => ({
      ...item,
      Selected: true,
      FieldId: "HasMedia",
    })),
    FieldDetailId: FIELD_DETAIL_LIST.map((item) => ({
      ...item,
      Selected: true,
      FieldId: "FieldDetailId",
    })),
  };
  expandedForBPId: number | null = null;
  expandedGroups: { [key: string]: boolean } = {};
  isInsideRightPane: boolean = false;
  IsFocusOnCommentField = false;
  settings: ICLMSettings | null = null;
  type: IComboboxItem | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      bploading: false,
      loading: false,
      documentLoading: false,
      gridLoading: false,
      imgLoading: false,
      buildPlans: [],
      filteredWorkOrders: [],

      documents: { data: [], total: 0 },
      gridFilter: this.gridFilter,
      sort: [],

      contextMenu: null,
      currentFileId: null,
      disableNext: false,
      disablePrev: false,
      remountFilterKey: +new Date(),
      collapsedIds: [],
    };
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.filterChangeTimeout) clearTimeout(this.filterChangeTimeout);
    window.document.removeEventListener("keydown", this.OnKeyDownDocument);
  }

  async componentDidMount() {
    window.document.addEventListener("keydown", this.OnKeyDownDocument);

    this.settings = await CLPMSettings.getSettings();
    this.SetColumnsSettings();
    this.SetStatusesList();
    this.LoadClmBuildPlansAndWorkOrders();
    window.helpers.onInactive(300000, this.AutoRefresh);
  }

  async componentDidUpdate(prevProps: props) {
    if (this.props.isActive) {
      let updateBPorWO = false;

      if (
        !this.props.isCLM &&
        this.props.buildPlanId &&
        this.bpId !== this.props.buildPlanId
      ) {
        this.bpId = this.props.buildPlanId;
        updateBPorWO = true;
      } else if (this.props.isCLM) {
        if (
          (this.props.buildPlanId &&
            this.bpId !== this.props.buildPlanId &&
            prevProps.buildPlanId !== this.props.buildPlanId) ||
          (this.props.workOrderId &&
            prevProps.workOrderId !== this.props.workOrderId)
        ) {
          this.bpId = this.props.buildPlanId;
          updateBPorWO = true;
        }
        let prevWoId = prevProps.workOrderId;
        let woId = this.props.workOrderId;
        if (
          /*woId && prevWoId && */ woId !== prevWoId &&
          this.selectedWorkOrder?.Id !== woId
        ) {
          updateBPorWO = true;
          this.woId = woId;
          this.selectedWorkOrder = undefined;
        }
      }
      if (updateBPorWO) {
        await this.GetSetFilteredWorkOrders();
        this.selectedDocument = undefined;
        this.LoadData();
      }
    }
  }

  initGridRef = (ref: any) => {
    if (!this.gridRef) {
      this.gridRef = ref;
    }
  };

  SetStatusesList = async () => {
    let statuses = await CLPMSettings.getStatusesList();
    this.columnValues.status = [];
    statuses.forEach((st) => {
      this.columnValues.status.push({
        Id: st.value,
        Name: st.text || "No Result",
        Selected: true,
        FieldId: "status",
      });
    });
  };

  SetColumnsSettings = () => {
    this.columns = [];

    if (this.settings?.IsReviewer || this.settings?.IsCustomer) {
      this.columns.push({
        field: "included",
        title: " ",
        width: 40,
      });
    }
    this.columns.push(
      {
        field: "index",
        title: "#",
        width: 50,
        filterable: false,
      },
      {
        field: "cliName",
        title: "Item",
        filterable: true,
      },
      {
        field: "statusName",
        fieldId: "status",
        title: "Status",
        width: 150,
        filterable: true,
      },
      {
        field: "HasMedia",
        fieldId: "HasMedia",
        title: "Media",
        width: 80,
        filterable: false,
      },
      {
        field: "FieldDetail",
        fieldId: "FieldDetailId",
        title: "Detail",
        width: 100,
        filterable: false,
      }
    );
  };

  render() {
    let contextItems =
      (!!this.contextMenuItem &&
        GetContextMenuItems(this.settings!, this.contextMenuItem)) ||
      [];
    const isSubmitter = this.settings?.IsSubmitter;
    const { collapsedIds, documents, gridFilter } = this.state;

    return (
      <>
        {this.state.loading && <Loader style={{ zIndex: 99999 }} />}
        <SplitPane
          split="vertical"
          defaultSizes={[1, 1, 0.2]}
          minSize={[0, 0, 170]}
        >
          <div style={{ position: "relative", width: "100%" }}>
            {this.state.gridLoading && <Loader style={{ zIndex: 99999 }} />}
            <Grid
              className={styles.Grid}
              style={{ height: "100%", width: "100%" }}
              data={setExpandedState({
                data: documents.data,
                collapsedIds,
              })}
              groupable={false}
              group={this.group}
              onExpandChange={this.ExpandChange}
              expandField="expanded"
              selectedField="Selected"
              onRowClick={this.OnClickRow}
              filterable={true}
              filterCellRender={this.renderFilterCell}
              filter={gridFilter}
              sortable={true}
              sort={this.state.sort}
              onSortChange={this.OnSort}
              rowHeight={GridRowHeight}
              cellRender={this.renderCell}
              rowRender={this.renderRow}
              ref={this.initGridRef}
            >
              <GridToolbar>
                {this.props.isCLM && (
                  <div className={styles.CLMFilterRow}>
                    <ComboboxFilterVirtual
                      key={this.state.remountFilterKey + "bps" + this.bpId}
                      data={this.state.buildPlans}
                      loading={this.state.bploading}
                      onChange={this.OnChangeBuildPlan}
                      filter={{
                        id: "buildPlans",
                        placeholder: "Filter by Build Plan",
                        type: "combobox",
                        width: 300,
                      }}
                      defaultValue={this.selectedBP}
                      required={true}
                    />
                    {!isSubmitter && !!this.bpId && (
                      <Button
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                          CardManagement.OpenBPCard(this.bpId!);
                        }}
                      >
                        Open BP Card
                      </Button>
                    )}
                    <ToolbarSpacer />
                    {this.renderRightButtons()}
                  </div>
                )}
                <div className={styles.CLMFilterRow}>
                  <ComboboxFilter
                    key={this.state.remountFilterKey + "wos"}
                    defaultValue={this.selectedWorkOrder}
                    filterData={this.state.filteredWorkOrders}
                    onChange={this.ChangeFilterByWO}
                    filter={{
                      id: "workOrders",
                      placeholder: "Filter by Work Order",
                      type: "combobox",
                      width: 300,
                    }}
                  />
                  <ComboboxFilter
                    key={this.state.remountFilterKey + "type"}
                    defaultValue={this.type}
                    filterData={TYPES}
                    onChange={this.OnChangeTypeFilter}
                    style={{ margin: "0 8px" }}
                    filter={{
                      id: "filterBeType",
                      placeholder: "Filter by Type",
                      type: "combobox",
                      width: 160,
                    }}
                  />
                  <ToolbarSpacer />
                  <Button
                    icon={"plus"}
                    title={"Add WO CheckList"}
                    onClick={this.OpenWOCheckListTemplateCard}
                    disabled={
                      this.selectedBP?.IsActive === false ||
                      this.selectedWorkOrder?.IsActive === false
                    }
                  />
                  {!isSubmitter && (
                    <DropDownButton
                      buttonClass={styles.DownloadButton}
                      text="Download"
                      icon="download"
                      items={["Approved Only", "All"]}
                      onItemClick={this.OnClickDownloadResults}
                    />
                  )}
                  {!this.props.isCLM && this.renderRightButtons()}
                </div>
              </GridToolbar>
              {this.columns.map(this.renderColumn)}
            </Grid>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className={styles.RightPanel}>
              {!!this.bpId && (
                <div className={styles.PreviewerCarouselBox}>
                  {this.selectedDocument && (
                    <ChecklistResultCarousel
                      key={
                        this.selectedDocument.id +
                        "_" +
                        this.selectedDocument.files.length
                      }
                      dataItem={this.selectedDocument}
                      buildPlanId={this.bpId}
                      pageId={this.props.pageId}
                      onSelectFile={this.OnSelectFile}
                      editComments={this.OpenCliCommentEdit}
                      settings={this.settings!}
                      abort={() => {
                        this.CancelPrevRequestUpdateToken();
                        return this.abortController.signal;
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <div
              style={{ width: "170px", flex: "0 0 auto" }}
              onMouseOver={this.OnMouseOverRightPanel}
              onMouseOut={this.OnMouseOutRightPanel}
            >
              <div
                className={styles.HistoryPanel}
                onWheel={debounce((event: any) => {
                  let action: "next" | "prev" =
                    event.nativeEvent.deltaY > 0 ? "next" : "prev";
                  this.NavResults(action);
                }, 200)}
              >
                {!!this.selectedDocument && (
                  <div className={styles.ResultsNav}>
                    <Button
                      icon="caret-double-alt-left"
                      data-action="prev"
                      onClick={this.OnNavResults}
                      fillMode="flat"
                      disabled={this.state.disablePrev}
                    />
                    <span>Checklist Result</span>
                    <Button
                      icon="caret-double-alt-right"
                      data-action="next"
                      onClick={this.OnNavResults}
                      fillMode="flat"
                      disabled={this.state.disableNext}
                    />
                  </div>
                )}
                {!!this.selectedDocument &&
                  !!this.selectedDocument.resultId && (
                    <ChecklistResultHistory
                      key={this.selectedDocument.id}
                      dataItem={this.selectedDocument}
                      currentFileId={this.state.currentFileId}
                      settings={this.settings!}
                      refreshGrid={this.refreshGridFromChecklistResultHistory}
                      onCommentChangeFocus={this.OnChangeCommentFocus}
                      createResultFromVersion={this.CreateResultFromVersion}
                    />
                  )}
              </div>
            </div>
          </div>
        </SplitPane>

        {!!contextItems && !!this.state.contextMenu && (
          <Popup show={true} offset={this.state.contextMenu}>
            <Menu vertical={true} onSelect={this.OnSelectContextItem}>
              {contextItems.map((item) => {
                let key = item.Id;
                return (
                  <MenuItem
                    key={key}
                    text={item.Name}
                    data={item.Id + ""}
                    cssClass={"cssClass"}
                  ></MenuItem>
                );
              })}
            </Menu>
          </Popup>
        )}
      </>
    );
  }

  renderCell = (el: any, props: any) => {
    if (props.rowType === "groupHeader") {
      if (el === null) return el;
      let items: Array<IDocumentItem> = props.dataItem.items;
      let itemsWithStatus = items.filter((item) => !!item.statusName);
      let dataItem = props.dataItem;
      let includeCheckbox: any = null;
      if (this.settings?.IsReviewer) {
        let item = items[0];
        let enableItemIndex = items.findIndex(
          (item) => !IsDisableChangeIncluded(item, this.settings!)
        );
        let notIncludeditem = items.findIndex((item) => !item.included);
        let included = notIncludeditem === -1;
        let groupName = item.groupName.replace(/\s/g, "") + item.clId;
        includeCheckbox = (
          <IncludeCheckbox
            key={groupName}
            className={styles.GroupTDCheckbox}
            checked={included}
            disabled={enableItemIndex === -1}
            groupName={groupName}
            queryProps={{
              Included: included,
              CLID: item.clId,
            }}
            onChange={this.SendCliIncluded}
          />
        );
      }

      const isDownloadAvailable =
        !this.settings?.IsSubmitter &&
        dataItem.items.findIndex((x: any) => !!x.files.length) > -1;
      return (
        <td colSpan={props.columnsCount}>
          <div className={styles.GroupTD}>
            {el.props.children.props.children[0]}
            {dataItem.value}
            <span className={styles.GroupTDNumbers}>
              ({itemsWithStatus.length} - {props.dataItem.items.length})
            </span>
            {includeCheckbox}
            {isDownloadAvailable && (
              <DropDownButton
                buttonClass={styles.DownloadGroupButton}
                icon="download"
                fillMode={"flat"}
                items={["Approved Only", "All"]}
                size={"small"}
                onItemClick={(event: DropDownButtonItemClickEvent) =>
                  this.handleDownloadGroupResults(event.item, dataItem.items)
                }
              />
            )}
          </div>
        </td>
      );
    }

    if (props.field === "included") {
      let dataItem: IDocumentItem = props.dataItem;
      let groupName = dataItem.groupName.replace(/\s/g, "") + dataItem.cliId;
      return (
        <td key={groupName + dataItem.included.toString()}>
          <IncludeCheckbox
            key={groupName}
            checked={dataItem.included}
            groupName={groupName}
            disabled={IsDisableChangeIncluded(dataItem, this.settings!)}
            queryProps={{
              Included: dataItem.included,
              CLID: dataItem.clId,
              CLIID: dataItem.cliId,
            }}
            onChange={this.SendCliIncluded}
          />
        </td>
      );
    }
    return el;
  };

  renderColumn = (column: IColumn) => {
    return (
      <Column
        key={column.field}
        field={column.field}
        title={column.title}
        width={column.width}
        columnMenu={column.fieldId ? this.renderColumnMenu : undefined}
        headerClassName={
          column.fieldId
            ? `${column.field} ${this.GetColumnHeaderClass(
                column.field,
                column.fieldId
              )}`
            : ""
        }
        filterable={column.filterable}
      />
    );
  };

  renderRow = (row: any, props: any) => {
    const isAvailableContextMenu =
      this.settings?.IsReviewer || this.settings?.IsSubmitter;
    if (!isAvailableContextMenu) return row;
    return (
      <tr
        {...row.props}
        onContextMenu={
          isAvailableContextMenu
            ? (e: any) => this.OnContextMenuRow(e, props.dataItem)
            : undefined
        } // todo use kendo grid context menu event
        className={row.props.className}
      >
        {row.props.children}
      </tr>
    );
  };

  renderRightButtons = () => {
    return (
      <>
        <Button
          style={{ marginLeft: 8 }}
          themeColor={
            this.gridFilter.filters.length || this.woId ? "primary" : undefined
          }
          icon={"filter-clear"}
          title={"Set Default Filters"}
          onClick={this.SetDefaultFilters}
        />
        <Button
          style={{ marginLeft: 8 }}
          iconClass={`${styles.ExpandIcon} mdi mdi-${
            this.expandAll ? "collapse-all-outline" : "expand-all-outline"
          }`}
          title={this.expandAll ? "Collapse All" : "Expand All"}
          onClick={this.ToggleExpandGroup}
        />
        <Button
          style={{ marginLeft: 8 }}
          icon="refresh"
          onClick={this.Refresh}
        />
      </>
    );
  };

  renderFilterCell = (el: any, props: simpleObject) => {
    let field = props.field;
    let key = field + this.state.remountFilterKey;
    if (field === "included") {
      return (
        <BooleanFilter
          key={key}
          trueText="Included"
          falseText="Excluded"
          id="Included"
          onChange={this.OnIncludedFilterChange}
        />
      );
    }
    let filter = this.gridFilter.filters.find(
      (f) => !IsComplexGridFilter(f) && f.field === field
    );
    const value = filter && !IsComplexGridFilter(filter) ? filter.value : "";

    return (
      <ClearableInput
        key={key}
        defaultValue={value}
        dataprops={field}
        onChange={this.OnChangeTextGridFilter}
        clear={this.ClearTextGridFilter}
      />
    );
  };

  OnMouseOverRightPanel = () => {
    this.isInsideRightPane = true;
  };

  OnMouseOutRightPanel = () => {
    this.isInsideRightPane = false;
  };

  OnChangeCommentFocus = (focus: boolean) => {
    this.IsFocusOnCommentField = focus;
  };

  OnKeyDownDocument = (event: any) => {
    if (this.props.isActive && !this.IsFocusOnCommentField) {
      let keyCodes = [61, 107, 173, 109, 187, 189];
      if (event.ctrlKey === true && keyCodes.indexOf(event.which) !== -1) {
        event.preventDefault();
      }

      let action: "next" | "prev" | "" =
        event.which == 40 ? "next" : event.which == 38 ? "prev" : "";
      if (this.isInsideRightPane && action) {
        this.NavResults(action);
        event.preventDefault();
      }
    }
  };

  OnSelectFile = (fileId: number) => {
    if (this.selectedDocument) {
      this.currentFileId = fileId;
      this.setState({ currentFileId: fileId });
    }
  };

  OpenWOCheckListTemplateCard = () => {
    CardManagement.OpenWOChecklistCard({
      buildPlanId: this.bpId,
      workOrderId: this.woId,
      onFinish: this.Refresh,
    });
  };

  OnContextMenuRow = (e: any, dataItem: IDocumentItem) => {
    e.preventDefault();
    this.contextMenuItem = dataItem;
    let left = e.nativeEvent.clientX;
    let top = e.nativeEvent.clientY;
    this.setState({ contextMenu: { left, top } });
    document.addEventListener("click", this.CloseContextMenu);
  };

  CreateResultFromVersion = async () => {
    try {
      var fileId = this.currentFileId;
      var dataItem = this.selectedDocument;
      if (
        !fileId ||
        (fileId &&
          dataItem?.approvedFileId &&
          +fileId === +dataItem.approvedFileId)
      )
        return false;
      let scrollPosition = this.gridRef.vs.container.scrollTop;
      this.setState({ loading: true });
      await CreateResultFromVersion(fileId);
      this.LoadData("grid", scrollPosition);
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  renderColumnMenu = (props: any) => {
    let field = props.column.field;
    let column = this.columns.find((c) => c.field === field);
    return (
      <CustomColumnMenu
        defaultProps={props}
        getColumnValues={(field, fieldId) => this.columnValues[fieldId] || []}
        filterByValues={this.OnChangeFilterByvalue}
        fieldId={column?.fieldId}
      />
    );
  };

  OnChangeFilterByvalue = (
    filters: IGridFilterItem[],
    values: IColumnValue[],
    fieldId: string,
    fieldName: string
  ) => {
    this.columnValues[fieldId] = values;
    let gridFilter = this.gridFilter;
    let complexFilter = gridFilter.filters.find(
      (f) =>
        IsComplexGridFilter(f) &&
        !IsComplexGridFilter(f.filters[0]) &&
        f.filters[0].field === fieldId
    );
    if (filters.length) {
      gridFilter.filters = gridFilter.filters.filter(
        (f) =>
          (IsComplexGridFilter(f) &&
            !IsComplexGridFilter(f.filters[0]) &&
            f.filters[0].field !== fieldId) ||
          (!IsComplexGridFilter(f) && f.field !== fieldName)
      );
      complexFilter = GetDefaultGridFilter();
      complexFilter.logic = "or";
      complexFilter.filters = filters;
      gridFilter.filters.push(complexFilter);
    } else {
      gridFilter.filters = gridFilter.filters.filter(
        (f) =>
          !IsComplexGridFilter(f) ||
          (IsComplexGridFilter(f) &&
            !IsComplexGridFilter(f.filters[0]) &&
            f.filters[0].field !== fieldId)
      );
    }
    this.gridFilter = gridFilter;
    this.SetDocuments();
  };

  CloseContextMenu = () => {
    this.contextMenuItem = null;
    this.setState({ contextMenu: null });
    document.removeEventListener("click", this.CloseContextMenu);
  };

  ClearTextGridFilter = (field: string) => {
    this.gridFilter.filters = this.gridFilter.filters.filter(
      (f) => !IsComplexGridFilter(f) && f.field !== field
    );
    this.SetDocuments();
  };

  ClearAllData = () => {
    this.documents = [];
    this.selectedDocument = undefined;
    this.currentFileId = null;
    this.setState({
      documents: { data: [], total: 0 },
      remountFilterKey: +new Date(),
    });
  };

  LoadData = async (mode?: "grid" | "background", scrollPosition?: number) => {
    if (!this.bpId) {
      this.ClearAllData();
      return;
    }
    let isFullUpdate = !mode;
    let isOnlyGridUpdate = mode === "grid";
    if (isFullUpdate) this.setState({ loading: true });
    else if (isOnlyGridUpdate) this.setState({ gridLoading: true });
    try {
      const result: any = await this.GetSQLData({
        spName: "CLPM_GetChecklistsResults",
        params: {
          buildPlanId: this.bpId,
          workOrderId: this.woId,
        },
      });
      this.documents = result[0] || [];
      const files: Array<IFileItem> = result[1] || [];
      files.forEach((file) => {
        // @ts-ignore
        file.e = file.e?.toUpperCase() || null;
        file.name = `Version ${file.v}. Uploaded (${
          file.d ? moment(file.d).format("L") : ""
        }) `;
      });

      const CLPMStatuses = await CLPMSettings.getCLPMStatusesLib();
      this.documents.forEach((item) => {
        if (this.selectedDocument && this.selectedDocument.id === item.id) {
          item.Selected = true;
          this.selectedDocument = item;
        }
        // @ts-ignore
        item.sampleExt = item.sampleExt?.toUpperCase() || null;
        item.files = files.filter((f) => f.resId === item.resultId);
        item.HasMedia = item.files.length ? "Yes" : "No";
        item.FieldDetailId = item.FieldDetail || "null";
        // @ts-ignore
        item.statusName = CLPMStatuses[item.status];
      });
      const groupedGridData = process(this.documents, {
        group: this.group,
        filter: this.gridFilter,
      });
      setGroupIds({ data: groupedGridData.data, group: this.group });
      if (this.expandedForBPId !== this.bpId) {
        this.expandedGroups = {};
        this.expandedForBPId = this.bpId;
      }
      const collapsedIds: string[] = [];
      groupedGridData.data.forEach(({ groupId }) => {
        if (this.expandedGroups[groupId] === undefined) {
          this.expandedGroups[groupId] = false;
        }
        if (!this.expandedGroups[groupId]) collapsedIds.push(groupId);
      });
      this.setState(
        {
          documents: groupedGridData,
          collapsedIds,
          ...this.GetDisabledArrows(groupedGridData),
          remountFilterKey: +new Date(),
        },
        () => {
          if (scrollPosition && scrollPosition > 0) {
            if (this.gridRef && this.gridRef.vs.container.scroll) {
              this.gridRef.vs.container.scroll(0, scrollPosition);
            } else if (
              this.gridRef &&
              this.gridRef.vs.container.scrollTop !== undefined
            ) {
              this.gridRef.vs.container.scrollTop = scrollPosition;
            }
          }
        }
      );
    } catch (e) {
      showSomeError(e);
    } finally {
      if (isFullUpdate) this.setState({ loading: false });
      else if (isOnlyGridUpdate) this.setState({ gridLoading: false });
    }
  };

  GetSetFilteredWorkOrders = async () => {
    if (this.bpId) {
      const buildPlans = await CLPMSettings.getActiveBuildPlans();
      this.selectedBP = this.bpId
        ? buildPlans.find((bp) => bp.Id === this.bpId)
        : undefined;
    }
    const workOrders = await CLPMSettings.getActiveWorkOrders();
    const filteredWorkOrders = this.bpId
      ? workOrders.filter((wo) => wo.BuildPlanId === this.bpId)
      : workOrders;
    this.selectedWorkOrder = this.woId
      ? filteredWorkOrders.find((wo) => wo.Id === this.woId)
      : undefined;
    this.woId = this.selectedWorkOrder && +this.selectedWorkOrder.Id;
    this.setState({ filteredWorkOrders, remountFilterKey: +new Date() });
  };

  LoadClmBuildPlansAndWorkOrders = async () => {
    this.setState({ bploading: true });
    try {
      const { buildPlans, workOrders } =
        await CLPMSettings.getActiveBuildPlansAndWorkOrders();
      if (this.props.isCLM) {
        this.selectedBP = CLPMSettings.getSelectedBP(this.bpId) || undefined;
        this.bpId = this.selectedBP && +this.selectedBP.Id;
      }
      const filteredWorkOrders = this.bpId
        ? workOrders.filter((wo) => wo.BuildPlanId === this.bpId)
        : workOrders;
      this.selectedWorkOrder = this.woId
        ? filteredWorkOrders.find((wo) => wo.Id === this.woId)
        : undefined;
      this.woId = this.selectedWorkOrder && +this.selectedWorkOrder.Id;
      this.setState({
        buildPlans: this.props.isCLM ? buildPlans : [],
        filteredWorkOrders,
        remountFilterKey: +new Date(),
      });
      if (this.bpId) this.LoadData();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ bploading: false });
    }
  };

  SendCliIncluded = async (queryParams: IUpdateIncludedParams) => {
    try {
      this.setState({ loading: true });
      await SendCliIncluded(queryParams);
    } catch (e) {
      showSomeError(e);
    } finally {
      this.LoadData("grid");
      this.setState({ loading: false });
    }
  };

  OnChangeBuildPlan = async (value: ICLPMBPItem | null) => {
    this.expandAll = false;
    this.selectedBP = value || undefined;
    this.bpId = value ? +value.Id : undefined;
    if (this.props.isCLM) {
      if (value)
        localStorage.setItem(
          CLPMSettings.CLM_BUILDPLAN_LS,
          JSON.stringify(value)
        );
      else localStorage.removeItem(CLPMSettings.CLM_BUILDPLAN_LS);
    }
    const workOrders = await CLPMSettings.getActiveWorkOrders();
    const filteredWorkOrders = this.bpId
      ? workOrders.filter((wo) => wo.BuildPlanId === this.bpId)
      : [];
    this.selectedWorkOrder =
      this.woId && this.bpId
        ? filteredWorkOrders.find((wo) => wo.Id === this.woId)
        : undefined;
    if (!this.selectedWorkOrder) this.woId = undefined;

    this.setState({ filteredWorkOrders, remountFilterKey: +new Date() });
    this.selectedDocument = undefined;
    this.LoadData();
  };

  OnSort = (e: any) => {
    this.setState({ sort: e.sort });
    this.sort = e.sort;
    this.group[0].dir = e.sort[0]?.dir;
    this.SetDocuments();
  };

  OnClickRow = async (e: any) => {
    let selected = e.dataItem;
    if (selected.field === "groupName") return;
    if (this.selectedDocument && this.selectedDocument.id === selected.id)
      return;
    this.SelectRow(selected);
  };

  SelectRow = (selected: IDocumentItem) => {
    try {
      this.selectedDocument = selected;
      for (let item of this.documents) {
        item.Selected = item.id === selected.id;
      }
      this.setState((state) => ({
        ...this.GetDisabledArrows(state.documents),
      }));
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ documentLoading: false });
    }
  };

  OnNavResults = (e: any) => {
    let selected = this.selectedDocument;
    if (!selected) return;
    let action: "next" | "prev" = e.currentTarget.dataset.action;
    this.NavResults(action);
  };

  NavResults = (action: "next" | "prev") => {
    let selected = this.selectedDocument;
    if (!selected) return;

    if (
      (action === "next" && this.state.disableNext) ||
      (action === "prev" && this.state.disablePrev)
    )
      return;

    let selectedGroupName = selected.groupName;
    if (selectedGroupName && selectedGroupName.trim)
      selectedGroupName = selectedGroupName.trim();
    let groupedData = this.state.documents.data;
    for (let i = 0; i < groupedData.length; i++) {
      let group = groupedData[i];
      let groupName = group.value;
      if (groupName && groupName.trim) groupName = groupName.trim();
      if (groupName === selectedGroupName) {
        for (let j = 0; j < group.items.length; j++) {
          let item = group.items[j];
          if (item.id === selected.id) {
            if (action === "next") {
              let nextGroup = groupedData[i + 1];
              let nextItem = group.items[j + 1] || nextGroup.items[0];
              selected.Selected = false;
              nextItem.Selected = true;
              this.selectedDocument = nextItem;
            } else if (action === "prev") {
              let prevGroup = groupedData[i - 1];
              let prevItem =
                group.items[j - 1] ||
                prevGroup.items[prevGroup.items.length - 1];
              selected.Selected = false;
              prevItem.Selected = true;
              this.selectedDocument = prevItem;
            }
            this.setState(this.GetDisabledArrows(this.state.documents));
            break;
          }
        }
      }
    }
  };

  refreshGridFromChecklistResultHistory = debounce((auto?: boolean) => {
    let data = filterBy([this.selectedDocument], this.state.gridFilter);
    let disabledNext = this.state.disableNext;
    if (!data.length) {
      if (!disabledNext) this.NavResults("next");
      else {
        this.selectedDocument!.Selected = false;
        this.selectedDocument = undefined;
        this.setState({
          disableNext: true,
          disablePrev: true,
        });
      }
    }
    this.LoadData(auto ? "background" : "grid");
  }, 1000);

  GetDisabledArrows = (documents: DataResult) => {
    let selected = this.selectedDocument;
    let disableNext = false;
    let disablePrev = false;
    if (selected) {
      let selectedGroupName = selected.groupName;
      if (selectedGroupName && selectedGroupName.trim)
        selectedGroupName = selectedGroupName.trim();
      let groupedData = documents.data;
      for (let i = 0; i < groupedData.length; i++) {
        let group = groupedData[i];
        let groupName = group.value;
        if (groupName && groupName.trim) groupName = groupName.trim();
        if (groupName && selectedGroupName && groupName === selectedGroupName) {
          for (let j = 0; j < group.items.length; j++) {
            let item = group.items[j];
            if (item.id === selected.id) {
              let nextGroup = groupedData[i + 1];
              disableNext = j === group.items.length - 1 && !nextGroup;
              disablePrev = i === 0 && j === 0;
              break;
            }
          }
        }
      }
    }
    return { disableNext, disablePrev };
  };

  SetDocuments = () => {
    const documents = process(this.documents, {
      group: this.group,
      sort: this.sort,
      filter: this.gridFilter,
    });
    setGroupIds({ data: documents.data, group: this.group });
    this.setState({ documents, gridFilter: this.gridFilter });
  };

  SetDefaultFilters = () => {
    this.type = null;
    this.gridFilter = GetDefaultGridFilter();
    for (let fieldId in this.columnValues) {
      for (let value of this.columnValues[fieldId]) value.Selected = true;
    }
    if (this.woId) {
      this.selectedWorkOrder = undefined;
      this.woId = undefined;
      this.LoadData();
    } else {
      this.SetDocuments();
      this.setState({ remountFilterKey: +new Date() });
    }
  };

  ChangeFilterByWO = (value: ICLPMWOItem | undefined) => {
    if (
      !value ||
      (this.selectedDocument && this.selectedDocument.woId !== value.Id)
    ) {
      this.selectedDocument = undefined;
      this.currentFileId = null;
    }
    this.selectedWorkOrder = value;
    this.woId = value ? +value.Id : undefined;
    this.LoadData();
  };

  OnChangeTypeFilter = (value: IComboboxItem | null) => {
    this.type = value;
    let isDocCl = value?.Id === "Documents";
    let field: keyof IDocumentItem = "docCl";
    if (
      value &&
      this.selectedDocument &&
      this.selectedDocument[field] === isDocCl
    ) {
      this.selectedDocument = undefined;
      this.currentFileId = null;
    }
    let gridFilter = this.gridFilter;
    this.gridFilter.filters = gridFilter.filters.filter((f) =>
      !IsComplexGridFilter(f) && f.field === field ? false : true
    );
    if (value) {
      this.gridFilter.filters.push({
        field,
        value: isDocCl,
        operator: "eq",
      });
    }
    this.SetDocuments();
  };

  OnChangeTextGridFilter = (e: any, field: any) => {
    let gridFilter = this.gridFilter;
    let value = e.value;
    if (field === "statusName") {
      gridFilter.filters = gridFilter.filters.filter(
        (f) => !IsComplexGridFilter(f)
      );
      this.columnValues.status.forEach((s) => (s.Selected = true));
    }
    let oldFilter = gridFilter.filters.find(
      (f) => !IsComplexGridFilter(f) && f.field === field
    );
    if (oldFilter && !IsComplexGridFilter(oldFilter)) {
      oldFilter.value = value;
    } else {
      this.gridFilter.filters.push({
        field,
        value,
        operator: DEFAULT_OPERATOR.text,
      });
    }
    this.SetDocuments();
  };

  ExpandChange = (event: any) => {
    this.setState((state) => {
      const { groupId, expanded } = event.dataItem;
      this.expandedGroups[groupId] = !expanded;
      let collapsedIds = state.collapsedIds;
      if (!this.expandedGroups[groupId]) {
        collapsedIds.push(groupId);
      } else {
        collapsedIds = collapsedIds.filter((id) => id !== groupId);
      }
      if (collapsedIds.length === state.documents.data.length) {
        this.expandAll = false;
      } else if (!collapsedIds.length) {
        this.expandAll = true;
      }
      return { collapsedIds };
    });
  };

  ToggleExpandGroup = () => {
    this.expandAll = !this.expandAll;

    const listData = this.state.documents.data;
    const collapsedIds: string[] = [];
    for (let group of listData) {
      this.expandedGroups[group.groupId] = this.expandAll;
      if (!this.expandAll) collapsedIds.push(group.groupId);
    }
    this.setState({ collapsedIds });
  };

  AutoRefresh = () => {
    if (this.props.isActive) this.BackgroundRefresh();
  };

  BackgroundRefresh = () => {
    this.LoadData("background");
  };

  Refresh = () => {
    this.LoadData();
  };

  RefreshOnActivateCLMTab = () => {
    // external function call
    let bp = settingsStorage.getForCurrentUser(CLPMSettings.CLM_BUILDPLAN_LS);
    let selectedBp = bp ? JSON.parse(bp) : undefined;
    let newBpId = selectedBp && +selectedBp.Id;
    if (this.bpId !== newBpId) {
      this.selectedWorkOrder = undefined;
      this.woId = undefined;
      this.selectedBP = selectedBp;
      this.bpId = selectedBp && +selectedBp.Id;
      this.LoadData();
    }
  };

  OnIncludedFilterChange = (value: boolean | null) => {
    let gridFilter = this.gridFilter;
    let field = "included";
    if (value === null) {
      gridFilter.filters = gridFilter.filters.filter(
        (f) => IsComplexGridFilter(f) || f.field !== field
      );
    } else {
      let oldFilter = gridFilter.filters.find(
        (f) => !IsComplexGridFilter(f) && f.field === field
      );
      if (oldFilter && !IsComplexGridFilter(oldFilter)) oldFilter.value = value;
      else gridFilter.filters.push({ field, operator: "eq", value });
    }
    this.SetDocuments();
  };

  OnSelectContextItem = (e: any) => {
    let action = e.item.data;
    this.DoAction(action);
    this.CloseContextMenu();
  };

  DoAction = async (
    action: string,
    dataItem: IDocumentItem = this.contextMenuItem!
  ) => {
    let accept = "";
    if (action === "UploadResult" || action === "UploadVersion") {
      accept = dataItem.docCl
        ? ".doc,.docx,.xlsx,.xls,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        : "image/*,video/*";
    }
    const downLoadUrl = dataItem.sampleDocumentId
      ? await GetDocumentUrl(dataItem.sampleDocumentId, false)
      : "";
    switch (action) {
      case "DownloadSample":
        if (downLoadUrl) document.location.href = downLoadUrl;
        return;

      case "DeleteResult":
        ModalRef.showDialog({
          title: "Confirmation",
          text:
            "Are you sure that you want to delete result? Versions count to be deleted: " +
            dataItem.files.length,
          buttons: [
            {
              text: "Cancel",
              action: () => {
                ModalRef.hideDialog();
              },
            },
            {
              text: "Ok",
              color: "primary",
              action: () => {
                this.DeleteResult(dataItem.resultId!); // ??
                ModalRef.hideDialog();
              },
            },
          ],
        });
        return;

      case "UploadResult":
        await this.UploadResult(
          dataItem.clId,
          dataItem.cliId,
          dataItem.dsId,
          accept!
        );
        return;

      case "UploadVersion":
        this.UploadNewVersion(dataItem.resultId!, accept);
        return;

      case "EditCLIComments":
        this.OpenCliCommentEdit(dataItem);
        return;
    }
    // todo onSuccess ??
    /*
                                                    function onSuccess(result) {
                                                      if (result) {
                                                        window.currentResultId = result;
                                                        window.currentSelectId = dataItem.id;
                                                        reloadChecklists();
                                                      }
                                                    }
                                                    */
  };

  OpenCliCommentEdit = (dataItem: IDocumentItem) => {
    let commentRef: any = React.createRef();
    let oldValue = dataItem.comments || "";
    let disabled = !this.settings?.IsReviewer;
    ModalRef.showDialog({
      title: "Comments",
      width: 800,
      buttons: [
        {
          text: "Ok",
          color: "primary",
          disabled,
          action: async () => {
            let value = commentRef.element.current.value;
            if (value !== oldValue) {
              try {
                await UpdateCliComments(value, dataItem);
              } catch (e) {
                showSomeError(e);
              } finally {
                this.AutoRefresh();
              }
            }
            ModalRef.hideDialog();
          },
        },
        {
          text: "Cancel",
          action: () => {
            ModalRef.hideDialog();
          },
        },
      ],
      children: (
        <div>
          <TextArea
            ref={(ref) => (commentRef = ref)}
            rows={20}
            style={{ width: "100%" }}
            defaultValue={oldValue}
            readOnly={disabled}
            maxLength={4096}
          />
        </div>
      ),
    });
  };

  GetColumnHeaderClass = (field: string, fieldId: string) => {
    let filtered = this.state.gridFilter.filters.find((filter) => {
      if (
        !IsComplexGridFilter(filter) &&
        filter.field === field &&
        filter.value
      )
        return true;
      if (IsComplexGridFilter(filter)) {
        let firstFilter = filter.filters[0];
        if (!IsComplexGridFilter(firstFilter) && firstFilter.field === fieldId)
          return true;
      }
      return false;
    });
    return filtered ? gridStyles.FilteredColumnTH : "";
  };

  UploadNewVersion = (resultId: number, accept: string) => {
    const $ = window.$;
    $("#uploadFile").remove();
    $("body").append(
      '<input id="uploadFile" name="uploadFile" type="file" data-resultid="' +
        resultId +
        '" style="display:none;" accept="' +
        (accept || "") +
        '"/>'
    );
    var input = $("#uploadFile");
    input.on("change", async () => {
      var selectedFile = input[0].files[0];
      let resultId = $("#uploadFile").data("resultid");
      if (resultId) {
        const documentId = await api.script.woCreateChecklistResultFile({
          ChecklistResultId: +resultId,
        });
        await UpdateDocument(+documentId!, selectedFile);
        this.LoadData();
      }
    });
    input.trigger("click");
  };

  UploadResult = (
    checklistId: number,
    itemId: number,
    dispatchId: number | null,
    accept: string
  ) => {
    const $ = window.$;
    $("#uploadFile").remove();
    $("body").append(
      '<input id="uploadFile" name="uploadFile" type="file" data-checklistid="' +
        checklistId +
        '" data-dispatchid="' +
        (dispatchId || "") +
        '" data-itemid="' +
        itemId +
        '" style="display:none;" accept="' +
        (accept || "") +
        '"/>'
    );
    const input = $("#uploadFile");
    input.on("change", async () => {
      this.setState({ loading: true });
      const selectedFile = input[0].files[0];
      const checklistId = $("#uploadFile").data("checklistid");
      const itemId = $("#uploadFile").data("itemid");
      const dispatchId = $("#uploadFile").data("dispatchid");
      const resultId = await api.script.woCreateChecklistResult({
        ChecklistItemId: +itemId,
        DispatchId: dispatchId ? +dispatchId : undefined,
        ChecklistId: +checklistId,
      });
      const documentId = await api.script.woCreateChecklistResultFile({
        ChecklistResultId: +resultId!,
      });
      await UpdateDocument(+documentId!, selectedFile);
      this.setState({ loading: false });
      this.LoadData(); // grid??
      return +resultId!;
    });
    input.trigger("click");
  };

  DeleteResult = async (resultId: number) => {
    try {
      this.setState({ loading: true });
      await RunScriptAsync("CLPM_DeleteResult", { ID: resultId });
      this.NavResults("next");
      this.setState({ loading: false });
      this.Refresh();
    } catch (e) {
      showSomeError(e);
    }
  };

  OnClickDownloadResults = (e: any) => {
    let action: downloadCLResultsAction = e.item;
    this.handleDownloadAllResults(action);
  };

  handleDownloadAllResults = async (action: downloadCLResultsAction) => {
    const buildPlanId = this.bpId;
    if (!buildPlanId) return false;
    const zipName = this.selectedWorkOrder
      ? this.selectedWorkOrder.Name
      : this.selectedBP?.Name;
    const response = await api.sql.clpmCreateZipLink({
      approvedOnly: action !== "All",
      buildPlanId: this.bpId,
      workOrderId: this.selectedWorkOrder ? +this.selectedWorkOrder.Id : null,
    });
    const linkId = response?.[0]?.linkId;
    if (!linkId) {
      ModalRef.showDialog({ text: "No files to download", type: "info" });
    } else {
      window.location.href = GetMultiZipDownloadURL(
        zipName || "ReviewInterface",
        linkId
      );
    }
  };

  handleDownloadGroupResults = async (
    action: downloadCLResultsAction,
    groupItems: any[]
  ) => {
    const includeAll = action === "All";
    const docIds: number[] = [];
    for (const item of groupItems) {
      for (let file of item.files) {
        if (includeAll || file.id === item.approvedFileId) {
          docIds.push(file.docId);
        }
      }
    }

    if (!docIds.length) {
      ModalRef.showDialog({ text: "No files to download", type: "info" });
      return;
    }

    const url = await api.documents.getMultiDocZipUrl(
      {
        zipFileName: groupItems[0]?.groupName
          .replace(/\//g, ".")
          .replace(/\#/g, ""),
      },
      docIds
    );
    window.location.href = config.API_URL.replace(/\/+$/, "") + url;
  };
}

export default Index;
