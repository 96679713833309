import { createContext } from "react";
import { simpleObject } from "./interfaces";

import diContainer from "../inversify.config";
import {
  BBPromise as BBPromiseInterface,
  ISettingsStorage,
} from "../core/interfaces";
import DI_TYPES from "../core/diTypes";
import { IInitialTabSettings, tabId } from "../Components/Tabs/interfaces";
import {
  SQL_DB_Calendar_GetData_Dashboard_Request,
  SQL_DB_Calendar_GetData_Dashboard_Response,
  SQL_DB_Calendar_GetData_Request,
  SQL_DB_Calendar_GetData_Response,
} from "../core/api/generated/conterra";

const __DEV__ = window.location.host === "localhost:3000";
const IntegrationWithDesktop = "IntegrationWithDesktop";
const IntegrationWithDesktopContext = createContext<{
  value: boolean;
  setValue: (value: boolean) => void;
}>({
  value: false,
  setValue: (newValue: boolean) => undefined,
});

declare global {
  interface Window {
    isTouchDevice: boolean;
    helpers:
      | {
          openRecord(refName: string, id: number, params?: simpleObject): void;
          runScriptLocal: any;
          runScript: any;
          runScriptAsync: any;
          getSQLData: any;
          getRecordLink: any;
          addParameterToURL: any;
          onInactive: any;
          openDispatchCard: any;
          newDispatch: any;
          openBPCard: any;
          openWebBPCard(
            buildPlanId: number,
            initialTab: tabId | IInitialTabSettings,
            onFinish?: any
          ): void;
          newWO: any;
          openClmReviewCard: any;
          // TODO remove after refactoring calendar
          dbCalendarGetData: (
            params: SQL_DB_Calendar_GetData_Request
          ) => BBPromiseInterface<SQL_DB_Calendar_GetData_Response[]>;
          dbCalendarGetDataDashboard: (
            params: SQL_DB_Calendar_GetData_Dashboard_Request
          ) => BBPromiseInterface<SQL_DB_Calendar_GetData_Dashboard_Response[]>;
          liveMapPortal: any;
          liveMapPortalRemove: any;
          openGBPCard: any;
          livemapOpened: boolean;
          IsDesktopIntegrationOn(): boolean;
          CLPMRouteToReview?(woId: number, bpId: number): void;
          lsGetForCurrentUser(key: string): null | undefined | string;
          lsSetForCurrentUser(key: string, value: string): void;
          lsRemoveForCurrentUser(key: string): void;
        }
      | simpleObject;
    $: any;
    L: any;
  }
}
window.helpers = {};
const settingsStorage = diContainer.get<ISettingsStorage>(
  DI_TYPES.ISettingsStorage
);
const getParamsFromUrl = () => {
  var strParams = window.location.search.replace("?", "").split("&");
  var params: any = {};
  strParams.forEach(function (str) {
    var t = str.split("=");
    params[t[0]] = t[1];
  });
  return params;
};

const IS_TOUCH_DEVICE = window.isTouchDevice;
//@ts-ignore
let isMobile = window.matchMedia("only screen and (max-width: 1200px)").matches;

export {
  IS_TOUCH_DEVICE,
  IntegrationWithDesktop,
  IntegrationWithDesktopContext,
  __DEV__,
  isMobile,
  settingsStorage,
};
