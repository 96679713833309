import { Component } from "react";
import {
  ComboBox,
  ComboBoxChangeEvent,
  DropDownsPopupSettings,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

interface props<I> {
  opened?: boolean;
  dataAttr?: any;
  data?: Array<I>;
  placeholder: string;
  className?: string;
  loading?: boolean;
  defaultValue?: I | null;
  value?: I | null;
  disabled?: boolean;
  width?: number;
  required?: boolean;
  popupSettings?: DropDownsPopupSettings;
  textField?: string;
  dataItemKey?: string;
  getData?: () => Promise<any>;
  size?: "small" | "medium" | "large" | null;
  groupMode?: string;
  groupField?: string;
  fillMode?: "solid" | "flat" | "outline" | null;

  onChange(value: I | null, dataAttr?: any): void;

  itemRender?(
    li: React.ReactElement<
      HTMLLIElement,
      string | React.JSXElementConstructor<any>
    >,
    itemProps: ListItemProps
  ): React.ReactNode | undefined;
}

interface IState<I> {
  isLoading: boolean;
  filter: any;
  data: I[] | null;
}

class FilterCombobox<I> extends Component<props<I>, IState<I>> {
  constructor(props: props<I>) {
    super(props);
    this.state = {
      isLoading: false,
      filter: null,
      data: null,
    };
  }

  componentDidMount() {
    if (this.props.getData) this.LoadData(this.props.getData);
  }

  LoadData = async (func: () => Promise<any>) => {
    try {
      this.setState({ isLoading: true });
      let result = await func();
      this.setState({ data: result as I[] });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const data = this.state.data || this.props.data || [];
    const { fillMode } = this.props;
    return (
      <ComboBox
        style={{ width: this.props.width }}
        className={this.props.className}
        data={filterBy(data, this.state.filter)}
        defaultValue={this.props.defaultValue}
        value={this.props.value}
        textField={this.props.textField || "Name"}
        dataItemKey={this.props.dataItemKey || "Id"}
        onChange={this.OnChange}
        filterable={true}
        onFilterChange={this.Filter}
        onOpen={this.OnOpen}
        placeholder={this.props.placeholder}
        disabled={!!this.props.disabled}
        loading={this.state.isLoading || this.props.loading}
        itemRender={this.props.itemRender}
        opened={this.props.opened}
        required={this.props.required}
        popupSettings={{
          popupClass: "dropdown-scroll-popup",
          ...(this.props.popupSettings || {}),
        }}
        size={this.props.size}
        groupMode={this.props.groupMode}
        groupField={this.props.groupField}
        fillMode={fillMode}
      />
    );
  }

  Filter = (e: any) => {
    this.setState({ filter: e.filter });
  };

  OnOpen = () => {
    this.setState({ filter: null });
  };

  OnChange = (e: ComboBoxChangeEvent) => {
    let value = e.value;
    this.props.onChange(value, this.props.dataAttr);
  };
}

export default FilterCombobox;
