import { PropsSQLDBTKReviewRequest } from "../../core/api/generated/conterra";
import { INewComboboxItem } from "../../helpers/interfaces";

export enum ETCPerformAction {
  ChangeTime = "ChangeTime",
  Reassign = "Reassign",
  Reject = "Reject",
  Complete = "Complete",
  Confirm = "Confirm",
  NoChanges = "NoChanges",
}

export interface IFilters {
  [PropsSQLDBTKReviewRequest.resourceId]?: INewComboboxItem | null;
  [PropsSQLDBTKReviewRequest.reviewerId]?: INewComboboxItem | null;
  [PropsSQLDBTKReviewRequest.periodId]?: INewComboboxItem | null;
  [PropsSQLDBTKReviewRequest.dispatchId]?: INewComboboxItem | null;
  [PropsSQLDBTKReviewRequest.reviewStateId]?: INewComboboxItem | null;
  [PropsSQLDBTKReviewRequest.departmentId]?: INewComboboxItem | null;
  [PropsSQLDBTKReviewRequest.employeeClassId]?: INewComboboxItem | null;
  [PropsSQLDBTKReviewRequest.date]?: Date | null;
  [PropsSQLDBTKReviewRequest.finishDate]?: Date | null;
}

export type TKFilterKey =
  (typeof PropsSQLDBTKReviewRequest)[keyof typeof PropsSQLDBTKReviewRequest];

export interface IDispatchDataFilter {
  dispatch?: INewComboboxItem | null;
  resource?: INewComboboxItem | null;
  date?: Date | null;
  finishDate?: Date | null;
}

export type onFilterChange = (
  value: INewComboboxItem | null,
  name: TKFilterKey
) => void;
