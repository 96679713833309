import React from "react";
import BaseComponent from "../../Components/BaseComponent";
import DashboardGrid from "../../Components/Dashboard/Grid";
import { IColumnSetting } from "../../Components/Dashboard/interfaces";
import { simpleObject } from "../../helpers/interfaces";
import { IDetailsProps } from "./interfaces";

interface props {
  queryProps: IDetailsProps;
  toolbarBefore?: any;
  className?: string;
  confirmedOnly?: boolean;

  onChangeGridData?(data: Array<simpleObject>): void;
}

const COLUMNS: Array<IColumnSetting> = [
  {
    field: "Date",
    title: "Date",
    type: "date",
    format: {
      type: "date",
    },
  },
  {
    field: "Confirmed",
    title: "Confirmed",
    type: "string",
    gridWidth: 95,
    format: {
      type: "string",
      fieldId: "Confirmed",
    },
  },
  {
    field: "SourceTypeName",
    title: "Source Type",
    type: "string",
    gridWidth: 150,
    format: {
      type: "string",
      fieldId: "SourceTypeName",
    },
  },
  {
    field: "RecordName",
    title: "Record Name",
    type: "string",
    minWidth: 180,
    format: {
      type: "link",
      fieldId: "RecordId",
      refNameField: "ReferenceName",
    },
  },
  {
    field: "MainAccountName",
    title: "Main FA",
    type: "string",
    gridWidth: 150,
    format: {
      type: "string",
      fieldId: "MainAccountId",
    },
  },
  {
    field: "AccountName",
    title: "Financial Account",
    type: "string",
    gridWidth: 150,
    format: {
      type: "string",
      fieldId: "AccountId",
    },
  },
  {
    field: "Hours",
    title: "Hours",
    type: "number",
    aggregate: "sum",
    format: {
      type: "number",
    },
  },
  {
    field: "Amount",
    title: "Amount",
    type: "number",
    aggregate: "sum",
    format: {
      type: "currency",
    },
  },
  {
    field: "EmployeeName",
    title: "Employee Name",
    type: "string",
    gridWidth: 150,
    format: {
      type: "string",
      fieldId: "EmployeeId",
    },
  },
  {
    field: "Organization",
    title: "Organization",
    gridWidth: 180,
    type: "string",
    format: {
      type: "string",
      fieldId: "Organization",
    },
  },
  {
    field: "CustomerName",
    title: "Customer Name",
    type: "string",
    gridWidth: 150,
    format: {
      type: "string",
      fieldId: "CustomerId",
    },
  },
  {
    field: "ClassName",
    title: "Class",
    type: "string",
    gridWidth: 150,
    format: {
      type: "string",
      fieldId: "ClassId",
    },
  },
  {
    field: "ProjectName",
    title: "Project",
    type: "string",
    gridWidth: 100,
    format: {
      type: "string",
      fieldId: "ProjectId",
    },
  },
  {
    field: "ScenarioName",
    title: "Scenario",
    gridWidth: 120,
    type: "string",
    format: {
      type: "string",
      fieldId: "ScenarioId",
    },
  },
  {
    field: "RegionName",
    title: "Region",
    gridWidth: 120,
    type: "string",
    format: {
      type: "string",
      fieldId: "RegionId",
    },
  },
  {
    field: "SiteName",
    title: "Site",
    gridWidth: 150,
    type: "string",
    format: {
      type: "string",
      fieldId: "SiteId",
    },
  },
  {
    field: "BuildPlanNumber",
    title: "BP #",
    gridWidth: 200,
    type: "string",
    format: {
      type: "string",
      fieldId: "BuildPlanId",
    },
  },
  {
    field: "ProfitCenterName",
    title: "Profit Center",
    gridWidth: 120,
    type: "string",
    format: {
      type: "string",
      fieldId: "ProfitCenterId",
    },
  },
  {
    field: "MarketName",
    title: "Market",
    gridWidth: 100,
    type: "string",
    format: {
      type: "string",
      fieldId: "MarketId",
    },
  },
  {
    field: "Notes",
    title: "Notes",
    type: "string",
    format: {
      type: "icon",
      iconClass: "k-icon k-font-icon k-i-file-txt",
    },
  },
];

class PLDetails extends BaseComponent<props> {
  gridRef: any = React.createRef();

  render() {
    return (
      <DashboardGrid
        ref={this.gridRef}
        isActive={true}
        className={this.props.className}
        key={this.props.queryProps.dataItem.Key}
        columnsSettings={COLUMNS}
        getData={this.LoadGridData}
        excelFileName="FinancialDistribution.xlsx"
        columnMenu={{
          columns: false,
          filters: true,
        }}
        getRowKey={(dataItem: simpleObject) => dataItem.Date}
        toolbar={{
          switcher: this.props.toolbarBefore,
        }}
        onChangeGridData={this.props.onChangeGridData}
      />
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    const { dataItem, dataTo, dataFrom, periodType, statement } =
      this.props.queryProps;
    const { confirmedOnly } = this.props;
    const params = {
      confirmedOnly,
      dateFrom: dataFrom,
      dateTo: dataTo,
      periodType: periodType,
      statementId: statement.id,
      buildPlanId: dataItem.BPID === null ? 0 : dataItem.BPID,
      projectId: dataItem.ProjectID === null ? 0 : dataItem.ProjectID,
      regionId: dataItem.RegionID === null ? 0 : dataItem.RegionID,
      profitCenterId:
        dataItem.ProfitCenterID === null ? 0 : dataItem.ProfitCenterID,
      customerId: dataItem.CustomerID === null ? 0 : dataItem.CustomerID,
      siteId: dataItem.SiteID === null ? 0 : dataItem.SiteID,
      classId: dataItem.ClassID === null ? 0 : dataItem.ClassID,
      ...filters,
    };
    const result = await this.GetSQLData({
      spName: "GetFDDetailing",
      params,
    });
    return result[0];
  };
}

export default PLDetails;
