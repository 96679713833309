import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBooleanState, useLazyAsync } from "../../core/tools/Hooks";
import { process, State } from "@progress/kendo-data-query";
import styles from "./tkReview.module.scss";
import TKReviewGrid from "./Grid";
import { IFilters } from "./interfaces";
import TKListToolbar from "./Toolbar";
import { TKReviewRow } from "./TimeCard";
import {
  PropsSQLDBTKReviewRequest,
  PropsSQLDBTKReviewResponse,
  SQL_DB_TK_Review_Request,
  SQL_DB_TK_Review_Response,
} from "../../core/api/generated/conterra";
import { INewComboboxItem } from "../../helpers/interfaces";
import { settingsStorage } from "../../helpers/settings";
import moment from "moment/moment";
import { ISO_DATE_FORMAT } from "../../core/tools/formats";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { TimeCardsActions } from "./Toolbar/TimeCardsActions";
import { CompareControl } from "./Toolbar/CompareControl";
import { tabId } from "../../Components/Tabs/interfaces";
import api from "../../core/api/api";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import { SortDescriptor } from "@progress/kendo-data-query/dist/npm/sort-descriptor";
import LoaderComponent from "../../Components/Common/Loader";
import TCCard from "../../Components/Cards/TCCard/TCCard";
import { CardsStackRef } from "../../Components/Common/FXCard/Cards";

const localStorageKeys = {
  [PropsSQLDBTKReviewRequest.resourceId]: "TKResource",
  [PropsSQLDBTKReviewRequest.departmentId]: "TKDepartment",
  [PropsSQLDBTKReviewRequest.employeeClassId]: "TKEmployeeClassName",
  [PropsSQLDBTKReviewRequest.reviewerId]: "TKReviewer",
  [PropsSQLDBTKReviewRequest.periodId]: "TKPayroll",
  [PropsSQLDBTKReviewRequest.dispatchId]: "TKDispatch",
  [PropsSQLDBTKReviewRequest.reviewStateId]: "TKReviewState",
  [PropsSQLDBTKReviewRequest.date]: "TKDate",
  [PropsSQLDBTKReviewRequest.finishDate]: "TKDateTo",
} as const;

export default function TKReview() {
  const [filtersValues, setFiltersValues] = useState<IFilters>(
    settingsStorage.getValuesForCurrentUser(localStorageKeys)
  );
  const isDateSortAsc = useBooleanState(true);
  const isHideRelatedTCs = useBooleanState(true);
  const kendoGridState: State = useMemo(() => {
    const filter: CompositeFilterDescriptor | undefined = isHideRelatedTCs.value
      ? {
          logic: "and",
          filters: [
            {
              field: PropsSQLDBTKReviewResponse.isRelated,
              operator: "eq",
              value: false,
            },
          ],
        }
      : undefined;

    const sort: SortDescriptor[] = [];
    if (!isHideRelatedTCs.value) {
      sort.push({ field: PropsSQLDBTKReviewResponse.employeeName, dir: "asc" });
    }
    sort.push({
      field: PropsSQLDBTKReviewResponse.date,
      dir: !isHideRelatedTCs.value || isDateSortAsc.value ? "asc" : "desc",
    });

    return {
      sort,
      filter,
    };
  }, [isDateSortAsc.value, isHideRelatedTCs.value]);

  const isToolbarExpanded = useBooleanState(true);
  const [checkedTCs, setCheckedTCs] = useState<number[]>([]);
  const [tcIdsToCompare, setTcIdsToCompare] = useState<number[]>([]);
  const isCompareMode = useBooleanState(false);
  const [selectedTCId, setSelectedTCId] = useState<number>();
  const [initialTabId, setInitialTabId] = useState<tabId>();
  useEffect(() => {
    CardsStackRef.closeAllCards();
  }, [selectedTCId]);
  useEffect(() => {
    if (!isCompareMode.value) setTcIdsToCompare([]);
  }, [isCompareMode.value]);

  const queryParams = useMemo(() => {
    const params: SQL_DB_TK_Review_Request = {};
    for (let filterId in filtersValues) {
      const value = filtersValues[filterId as keyof IFilters];
      if (!value) continue;
      const paramName = !(value instanceof Date)
        ? value.ServerParamName || filterId
        : filterId;
      params[paramName as keyof SQL_DB_TK_Review_Request] =
        value instanceof Date
          ? moment(value).format(ISO_DATE_FORMAT)
          : value.ServerParamValue !== undefined
          ? value.ServerParamValue
          : value.id;
    }
    return params;
  }, [filtersValues]);
  const [getRowsMethod, { data: timeCards, loading }, partialReloadMethod] =
    useLazyAsync(api.sql.dbTkReview);
  const getRows = useCallback(
    () => getRowsMethod(queryParams),
    [getRowsMethod, queryParams]
  );
  const reloadTCRow = useCallback(
    (tcId: number) => partialReloadMethod("id", { tcId }),
    [partialReloadMethod]
  );
  useEffect(getRows, [getRows]);

  const setDispatchFilter = useCallback(
    (dispatch: INewComboboxItem) => {
      settingsStorage.setForCurrentUser(
        localStorageKeys[PropsSQLDBTKReviewRequest.dispatchId],
        JSON.stringify(dispatch)
      );
      setFiltersValues((prevState) => ({
        ...prevState,
        [PropsSQLDBTKReviewRequest.dispatchId]: dispatch,
      }));
    },
    [setFiltersValues]
  );
  const onFilterChange = useCallback(
    (value: INewComboboxItem | null, filterName: keyof IFilters) => {
      if (value) {
        settingsStorage.setForCurrentUser(
          localStorageKeys[filterName],
          JSON.stringify(value)
        );
      } else {
        settingsStorage.removeForCurrentUser(localStorageKeys[filterName]);
      }
      setFiltersValues((prevState) => {
        return {
          ...prevState,
          [PropsSQLDBTKReviewRequest.dispatchId]:
            filterName === PropsSQLDBTKReviewRequest.resourceId
              ? null
              : prevState[PropsSQLDBTKReviewRequest.dispatchId],
          [filterName]: value,
        };
      });
    },
    [setFiltersValues]
  );
  const onDateFilterChange = useCallback(
    (value: SelectionRange | null) => {
      const { start, end } = value || {};
      const startLSKey = localStorageKeys[PropsSQLDBTKReviewRequest.date];
      if (start) {
        settingsStorage.setForCurrentUser(
          startLSKey,
          moment(start).format(ISO_DATE_FORMAT)
        );
      } else {
        settingsStorage.removeForCurrentUser(startLSKey);
      }

      const endLSKey = localStorageKeys[PropsSQLDBTKReviewRequest.finishDate];
      if (end) {
        settingsStorage.setForCurrentUser(
          endLSKey,
          moment(end).format(ISO_DATE_FORMAT)
        );
      } else {
        settingsStorage.removeForCurrentUser(endLSKey);
      }
      setFiltersValues((prevState) => {
        return {
          ...prevState,
          [PropsSQLDBTKReviewRequest.date]: start,
          [PropsSQLDBTKReviewRequest.finishDate]: end,
          [PropsSQLDBTKReviewRequest.dispatchId]: null,
        };
      });
    },
    [setFiltersValues]
  );

  const clearFilters = useCallback(() => {
    settingsStorage.removeValuesForCurrentUser(localStorageKeys);
    setFiltersValues({});
  }, [setFiltersValues]);

  useEffect(() => {
    setTcIdsToCompare([]);
    setCheckedTCs([]);
  }, [filtersValues]);

  const filteredTimeCards: SQL_DB_TK_Review_Response[] = useMemo(() => {
    if (!timeCards) return [];
    if (!kendoGridState) return timeCards;
    return process(timeCards, kendoGridState).data;
  }, [timeCards, kendoGridState]);

  const { selectPrev, selectNext, selectedTCIndex } = useMemo(() => {
    const currentIndex = filteredTimeCards.findIndex(
      (x) => x.id === selectedTCId
    );
    const prevTCId = filteredTimeCards[currentIndex - 1]?.id;
    const nextTCId = filteredTimeCards[currentIndex + 1]?.id;
    return {
      selectedTCIndex: currentIndex,
      selectNext: nextTCId ? () => setSelectedTCId(nextTCId) : undefined,
      selectPrev: prevTCId ? () => setSelectedTCId(prevTCId) : undefined,
    };
  }, [selectedTCId, filteredTimeCards]);
  const availableToActionsTimeCardsCount = useMemo(
    () => filteredTimeCards.filter(({ isPermitted }) => isPermitted).length,
    [filteredTimeCards]
  );

  const handleToggleCheckTC = useCallback(
    (tcId: number) => {
      setCheckedTCs((prevState) =>
        prevState.includes(tcId)
          ? prevState.filter((Id) => Id !== tcId)
          : [...prevState, tcId]
      );
    },
    [setCheckedTCs]
  );

  const handleToggleCheckAll = useCallback(() => {
    setCheckedTCs(
      checkedTCs.length === 0
        ? filteredTimeCards.filter((tc) => tc.isPermitted).map(({ id }) => id)
        : []
    );
  }, [setCheckedTCs, filteredTimeCards, checkedTCs]);

  const handleClearAllChecked = useCallback(() => {
    setCheckedTCs([]);
  }, [setCheckedTCs]);

  const handleToggleCompareCheckTC = useCallback(
    (tcId: number) => {
      setTcIdsToCompare((prevState) =>
        !prevState.includes(tcId)
          ? [...prevState, tcId]
          : prevState.filter((x) => x !== tcId)
      );
    },
    [setTcIdsToCompare]
  );

  const renderRow = useCallback(
    (tc: SQL_DB_TK_Review_Response) => {
      const handleSelectTC = (initialTabId?: tabId) => {
        setSelectedTCId(tc.id);
        if (initialTabId) setInitialTabId(initialTabId);
      };
      return (
        <TKReviewRow
          key={tc.id}
          data={tc}
          isSelected={tc.id === selectedTCId}
          onToggleCheck={handleToggleCheckTC}
          isCheckedForAction={checkedTCs.includes(tc.id)}
          setDispatchFilter={setDispatchFilter}
          onClick={handleSelectTC}
          onToggleCompareCheck={handleToggleCompareCheckTC}
          isCheckedForComparing={tcIdsToCompare.includes(tc.id)}
          refreshTC={reloadTCRow}
          isCompareMode={isCompareMode.value}
        />
      );
    },
    [
      handleToggleCheckTC,
      checkedTCs,
      setDispatchFilter,
      setTcIdsToCompare,
      isCompareMode.value,
      tcIdsToCompare,
      handleToggleCompareCheckTC,
      selectedTCId,
    ]
  );
  const unselect = useCallback(() => {
    setSelectedTCId(undefined);
    setInitialTabId(undefined);
  }, []);

  const selectedTC = filteredTimeCards[selectedTCIndex];
  return (
    <div className={styles.Container}>
      <TKListToolbar
        isDateSortAsc={isDateSortAsc.value}
        isHideRelatedTCs={isHideRelatedTCs.value}
        handleToggleShowRelatedTCs={isHideRelatedTCs.toggle}
        handleToggleSortByDate={isDateSortAsc.toggle}
        refreshList={getRows}
        toolbarExpandState={isToolbarExpanded.value}
        onToggleToolbar={isToolbarExpanded.toggle}
        onClearFilters={clearFilters}
        onServerFilterChange={onFilterChange}
        onDateFilterChange={onDateFilterChange}
        filters={filtersValues}
        filteredTimeCards={filteredTimeCards}
        timecardsActions={
          <TimeCardsActions
            onToggleCheckAll={handleToggleCheckAll}
            clearCheckedIds={handleClearAllChecked}
            checkedTCS={checkedTCs}
            enableTCSCount={availableToActionsTimeCardsCount}
            refreshList={getRows}
            isCompareMode={isCompareMode.value}
          />
        }
        compare={
          <CompareControl compareMode={isCompareMode} ids={tcIdsToCompare} />
        }
      ></TKListToolbar>
      <div className={styles.SelectedContainer}>
        {loading ? <LoaderComponent /> : null}
        <TKReviewGrid
          selectedTCId={selectedTCId}
          toolbarExpandState={isToolbarExpanded.value}
          timeCards={filteredTimeCards}
          renderRow={renderRow}
        />

        {!!selectedTCId && (
          <TCCard
            tcInfo={selectedTC}
            handleNext={selectNext}
            handlePrev={selectPrev}
            handleClose={unselect}
            reload={() => reloadTCRow(selectedTCId)}
            initialTabId={initialTabId}
            cardStageDefault={"FULLSCREEN"}
            isLoading={loading}
          />
        )}
      </div>
    </div>
  );
}
