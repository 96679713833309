import CardManagement from "../Components/Cards/CardManagement";
import { ModalRef } from "../Components/Common/Modal/Modal";
import { ModalComponentInIframeRef } from "../Components/PortalLiveMap/PortalLiveMap";
import { ILiveMapProps } from "../Pages/LiveMap/interfaces";
import {
  calendarId,
  IComboboxItem,
  INewComboboxItem,
  simpleObject,
} from "./interfaces";
import { __DEV__, IntegrationWithDesktop, settingsStorage } from "./settings";

import moment from "moment";
import { IInitialTabSettings, tabId } from "../Components/Tabs/interfaces";
import api from "../core/api/api";
import config from "../config";
import { sasTokenService } from "../core/tools/sasTokenService";
import { SasTokenAccessType } from "../core/api/generated/documents";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Components/Common/Form/form.module.scss";
import { ApiCancellationError, ApiError } from "../core/api/apiExceptions";
import { CancellationError } from "bluebird";

export const IsDesktopIntegrationOn = () => {
  return settingsStorage.getForCurrentUser(IntegrationWithDesktop) === "true";
};

export function showRequestError(error: any) {
  if (error instanceof ApiCancellationError) return;
  ModalRef.showDialog({
    type: error.type,
    width: 620,
    title: error.name,
    text: error.message,
  });
}

export const showSomeError = (
  error: any,
  title: string = "Unknown Error",
  text?: string
) => {
  if (error instanceof ApiError || error instanceof CancellationError) return;
  const errorString: any = JSON.stringify(JSON.parse(JSON.stringify(error)));
  const modalTitle =
    title === "Unexpected Error" ? (
      <>
        {title}:
        <Button
          style={{ marginLeft: "auto" }}
          className={styles.HeaderLinkBtn}
          fillMode="flat"
          icon="refresh"
          title="Reload"
          onClick={() => {
            window.location.reload();
          }}
        />
      </>
    ) : (
      title
    );
  if (__DEV__) console.log(error);
  ModalRef.showDialog({
    type: "error",
    width: 620,
    title: modalTitle,
    text: text || "Syntax Error",
    children: __DEV__ ? (
      <div>
        {!!error?.name && <div>{error.name}</div>}
        {!!error?.message && <div>{error.message}</div>}
        {!!error?.code && <div>{error.code}</div>}
        {!!error?.response && (
          <div>
            Status: {error.response.status} {error.response.statusText}
          </div>
        )}
        {!!error.stack && <div>Stack: {error.stack}</div>}
      </div>
    ) : undefined,
    details: __DEV__
      ? {
          title: "Details",
          content: errorString,
        }
      : undefined,
  });
};

export const sortByNameCombobox = (a: IComboboxItem, b: IComboboxItem) =>
  a.Name.localeCompare(b.Name);

export const sortByNameNewCombobox = (
  a: INewComboboxItem,
  b: INewComboboxItem
) => a.name.localeCompare(b.name);

export const formattedHoursToDuration = (durationString: string) => {
  let hours = +durationString.substring(0, 2);
  let minutes = +durationString.substring(3, 5);
  if (isNaN(hours) || isNaN(minutes)) return null;
  const duration = hours + minutes / 60;
  return +duration;
};

export const formatHoursDuration = (duration: number) => {
  const [h, m] = Math.abs(duration).toString().split(".");
  let hours = +h;
  const minutesDuration = +`0.${m ? m : 0}`;
  let minutes = Math.round(60 * minutesDuration);
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }
  let minutesString = `${minutes < 10 ? "0" : ""}${minutes}`;
  let hoursString = `${hours < 10 ? "0" : ""}${hours}`;
  let minus =
    hoursString !== "00" && minutesString !== "00" && duration < 0 ? "-" : "";
  return `${minus}${hoursString}:${minutesString}`;
};

export const formatTimeDiff = (s: number) => {
  let hrs = Math.floor(s / 3600);
  let min = Math.floor((s % 3600) / 60);
  return `${hrs ? hrs + "h " : ""}${min}m`;
};
export const formatTimeDiffWithSeconds = (s: number) => {
  let hrs = Math.floor(s / 3600);
  let min = Math.floor((s % 3600) / 60);
  const seconds = s % 60;
  return `${hrs ? hrs + "h " : ""}${min ? min + "m " : ""}${seconds}s`;
};

export const getActualDuration = (
  start: string,
  finish: string
): [number, string] => {
  let startMoment = moment(start);
  let finishMoment = moment(finish);
  const [, time] = finish.split("T");
  if (time === "23:59:59") {
    finishMoment.add(1, "seconds");
  }
  const diff = finishMoment.diff(startMoment, "m");
  const hours: number = diff / 60;
  return [hours, formatHoursDuration(hours)];
};

window.helpers.livemapOpened = false;

export const ResetSecondsDateString = (dateTime: string) =>
  dateTime.slice(0, -2) + "00";

export const formatFinancial = (value: number | null | undefined) => {
  if (value === null || value === undefined) return "";
  var formatValue = +Math.abs(value).toFixed(2);
  var format = formatValue.toLocaleString("en");
  var float = format.split(".")[1];
  float = float ? (float.length === 2 ? float : float + "0") : "00";
  var result = format.split(".")[0] + "." + float;
  if (value < 0) {
    return "(" + result + ")";
  }
  return result;
};

export const formatFinancialRound = (value: number) => {
  var minus = value < 0 ? "-" : "";
  var kValue = kmbFormatter(Math.abs(value));
  return minus + "$" + kValue;
};

export const kmbFormatter = (n: number) => {
  if (n < 1e3) return n.toFixed(2);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "k";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "m";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "b";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const PLFormatPercentage = (value: number) => {
  if (!isNaN(value) && !isFinite(value)) return "0.00 %";
  else if (isNaN(value)) return "n/a";
  else return `${value.toFixed(2)} %`;
};

export const TYPE_TO_EXCEL_FORMAT = {
  number: "#,##0.00",
  integer: "#,##0",
  percent: "#0.00\\%;-#0.00\\%",
  currency:
    '_([$$-en-US]* #,##0.00_);_([$$-en-US]* (#,##0.00);_([$$-en-US]* ""-""??_);_(@_)',
  date: "MM/dd/yyyy",
  datetime: "MM/dd/yyyy h:mm AM/PM",
  time: "h:mm:ss AM/PM",
  // boolean TODO
};

export const getParamsFromUrl = () => {
  var strParams = window.location.search.replace("?", "").split("&");
  var params: any = {};
  strParams.forEach(function (str) {
    var t = str.split("=");
    params[t[0]] = t[1];
  });
  return params;
};

export const addParamsToUrl = (params: simpleObject, url: string) => {
  let paramsArray: Array<string> = [];
  for (let key in params) {
    if (key !== "folder")
      paramsArray.push(`${key ? key + "=" : ""}${params[key]}`);
  }
  let f = url.indexOf("?") > -1 ? "&" : "?";
  if (paramsArray.length) return url + f + paramsArray.join("&");
  return url;
};

window.helpers.onInactive = function (ms: number, cb: any) {
  var newcb = function () {
    cb();
    window.helpers.onInactive(ms, cb);
  };
  var wait = setTimeout(newcb, ms);
  document.onmousemove =
    //@ts-ignore
    document.mousedown =
    //@ts-ignore
    document.mouseup =
    document.onkeydown =
    document.onkeyup =
    //@ts-ignore
    document.focus =
      function () {
        clearTimeout(wait);
        wait = setTimeout(newcb, ms);
      };
};

window.helpers.IsDesktopIntegrationOn = () => {
  return IsDesktopIntegrationOn();
};

window.helpers.getRecordLink = function (
  text: string,
  refName: string,
  recordId: string | number,
  callbackName: string
) {
  let onclick = "";
  if (refName !== "FSMBuildPlans" && IsDesktopIntegrationOn()) {
    let params = "'" + refName + "', '" + recordId + "'";
    onclick =
      "window.helpers.openRecord(" + params + "); event.stopPropagation();";
  } else if (refName === "FSMBuildPlans") {
    onclick =
      "window.helpers.openBPCard(" +
      recordId +
      ", " +
      callbackName +
      "); event.stopPropagation();";
  }
  let className =
    refName === "FSMBuildPlans" || IsDesktopIntegrationOn() ? "link" : "";
  return (
    '<span class="' +
    className +
    '" onclick="' +
    onclick +
    '">' +
    text +
    "</span>"
  );
};

export const GetDocumentUrl = async (
  documentId: number,
  inline: boolean,
  preview?: boolean
) => {
  try {
    const sasToken = await sasTokenService.getSasToken(
      SasTokenAccessType.DocumentRead
    );
    return (
      config.API_URL.replace(/\/+$/, "") +
      `/Documents/Document?documentId=${documentId}&sas=${sasToken}&inline=${inline}&preview=${!!preview}`
    );
  } catch (e) {
    showSomeError(e);
    return null;
    // throw e;
  }
};

export const UpdateDocument = async (
  documentId: number,
  file: File,
  onUploadProgress?: (pecent: number) => void
) => {
  try {
    const onProgress = onUploadProgress
      ? (e: any) => {
          let percent = (e.loaded / e.total) * 100;
          onUploadProgress(+percent.toFixed(0));
        }
      : undefined;
    return await api.documents.updateFileBody(
      { documentId },
      { document: file },
      { onUploadProgress: onProgress }
    );
  } catch (e: any) {
    showSomeError(e);
  }
};

window.helpers.addParameterToURL = (url: string, param: string) => {
  return url + (url.split("?")[1] ? "&" : "?") + param;
};

window.helpers.openDispatchCard = function (dsId: number, afterSave?: any) {
  window.focus();
  CardManagement.OpenDispatchCard({
    newDispatch: false,
    dsId,
    afterSave,
  });
};

window.helpers.newDispatch = function (
  dsId?: number,
  bpId?: number,
  afterSave?: any
) {
  window.focus();
  CardManagement.OpenDispatchCard({
    newDispatch: true,
    buildPlanId: bpId,
    dsId,
    afterSave,
  });
};

window.helpers.openWebBPCard = function (
  buildPlanId: number,
  initialTab?: tabId | IInitialTabSettings,
  onFinish?: any
) {
  CardManagement.OpenBPCard(buildPlanId, onFinish, initialTab);
};

window.helpers.openBPCard = function (buildPlanId: number, onFinish?: any) {
  window.focus();
  CardManagement.OpenBPCard(buildPlanId, onFinish);
};

window.helpers.openGBPCard = function () {
  window.focus();
  CardManagement.OpenGBPCard();
};

window.helpers.newWO = function (bpId?: number, onFinish?: any) {
  window.focus();
  CardManagement.NewWO(bpId, onFinish);
};

window.helpers.openClmReviewCard = function (
  buildPlanId: number,
  workOrderId?: number
) {
  window.focus();
  CardManagement.OpenClmReviewInterface(buildPlanId, workOrderId);
};

window.helpers.liveMapPortalRemove = function () {
  ModalComponentInIframeRef.hideLiveMap();
};

window.helpers.liveMapPortal = function (
  CalendarMode: calendarId,
  settings: ILiveMapProps
) {
  ModalComponentInIframeRef.showLiveMap(CalendarMode, {
    ...settings,
    isActive: true,
    mode: "calendar",
    prefix: "calendar",
  });
};

window.helpers.lsGetForCurrentUser = function (key: string) {
  return settingsStorage.getForCurrentUser(key);
};

window.helpers.lsSetForCurrentUser = function (key: string, value: string) {
  settingsStorage.setForCurrentUser(key, value);
};
window.helpers.lsRemoveForCurrentUser = function (key: string) {
  settingsStorage.removeForCurrentUser(key);
};
export const GetComboboxYearsList = () => {
  let year = new Date().getFullYear();
  let startYear = year - 10;
  let endYear = year + 11;
  let years: Array<IComboboxItem> = [];
  while (startYear < endYear) {
    years.push({
      Id: startYear,
      Name: startYear + "",
    });
    startYear += 1;
  }
  return years;
};
