import React from "react";
import { ReferenceRecordsDataSource } from "../../helpers/queries";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { IComboboxItem, simpleObject } from "../../helpers/interfaces";
import styles from "./materials.module.scss";
import TransactionJournal from "./TransactionJournal";
import { tabId } from "../../Components/Tabs/interfaces";
import {
  IColumnSetting,
  IFilterSetting,
} from "../../Components/Dashboard/interfaces";
import ButtonLink from "../../Components/Common/Buttons/ButtonLink";
import CardManagement from "../../Components/Cards/CardManagement";
import DashboardGrid from "../../Components/Dashboard/Grid";
import BaseComponent from "../../Components/BaseComponent";

interface state {
  materialId: number | null;
}

interface props {
  isActive: boolean;

  switchTab(tabId: tabId): void;

  onChangeGridData?(data: Array<simpleObject>): void;
}

const FILTERS: Array<IFilterSetting> = [
  {
    id: "filterCategory",
    placeholder: "Filter by Category",
    type: "combobox",
    width: 200,
    serverFilter: {
      param: "categoryId",
      getData: async () => {
        return (await ReferenceRecordsDataSource(
          "MaterialCategories"
        )) as IComboboxItem[];
      },
    },
    toolbarRow: 1,
  },
];

const COLUMNS: Array<IColumnSetting> = [
  {
    field: "RowNum",
    title: "#",
    type: "number",
    gridWidth: 40,
    filterable: false,
    aggregate: "count",
    columnMenu: false,
  },
  {
    field: "RootCategoryName",
    title: "Root Category",
    type: "string",
    gridWidth: 150,
    format: {
      type: "string",
      fieldId: "RootCategoryId",
    },
  },
  {
    field: "CategoryName",
    title: "Category",
    type: "string",
    gridWidth: 150,
    format: {
      type: "string",
      fieldId: "CategoryId",
    },
  },
  {
    field: "MAName",
    title: "Material",
    type: "string",
    minWidth: 300,
    format: {
      type: "link",
      fieldId: "MAId",
      refName: "FSMMaterials",
    },
  },
  {
    field: "Title",
    title: "Title",
    type: "string",
    gridWidth: 270,
  },
  {
    field: "CurrentBalance",
    title: "QTY",
    type: "number",
    gridWidth: 100,
    format: {
      type: "number",
      customFormat: true,
      getCustomFormat: (dataItem: simpleObject) => {
        if (
          dataItem.CurrentBalance === undefined ||
          dataItem.CurrentBalance === null
        ) {
          return <div className={styles.BalanceCell}>0</div>;
        }
        return (
          <div className={styles.BalanceCell}>
            <ButtonLink
              text={dataItem.CurrentBalance.toFixed(2)}
              onClick={() =>
                CardManagement.ShowMaterialBalance(
                  dataItem.MAId,
                  dataItem.MAName
                )
              }
            />
          </div>
        );
      },
    },
  },
  {
    field: "UOMName",
    title: "UOM",
    type: "string",
    gridWidth: 100,
    format: {
      type: "string",
      fieldId: "UOMName",
    },
  },
  {
    field: "COGS",
    title: "COGS $",
    type: "number",
    gridWidth: 100,
    format: {
      type: "currency",
    },
  },
  {
    field: "InternalPartNumber",
    title: "Internal Part #",
    type: "string",
    gridWidth: 120,
    format: {
      type: "string",
      fieldId: "InternalPartNumber",
    },
  },
  {
    field: "VendorName",
    title: "Vendor",
    type: "string",
    gridWidth: 180,
    format: {
      type: "string",
      fieldId: "VendorName",
    },
  },
  {
    field: "VendorPartNumbers",
    title: "Vendor Part #",
    type: "string",
    gridWidth: 180,
    format: {
      type: "string",
      fieldId: "VendorPartNumbers",
    },
  },
  {
    field: "Description",
    title: "Descr.",
    type: "string",
    gridWidth: 70,
    format: {
      type: "string",
      customFormat: true,
      getCustomFormat: (dataItem: simpleObject) => {
        return (
          <div className={styles.DescriptionCell}>
            {dataItem.Description && (
              <Tooltip
                anchorElement="target"
                position="left"
                style={{ maxWidth: "300px" }}
              >
                <div
                  className={styles.NotesIconBox}
                  title={dataItem.Description}
                >
                  <span className="k-icon k-font-icon k-i-file-txt"></span>
                </div>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  },
  {
    field: "IsCPEStr",
    title: "Is CPE",
    type: "string",
    format: {
      type: "string",
      fieldId: "IsCPE",
    },
  },
  {
    field: "IsSNRequiredStr",
    title: "SN Required",
    type: "string",
    gridWidth: 100,
    format: {
      type: "string",
      fieldId: "IsSNRequired",
    },
  },
];

class Materials extends BaseComponent<props, state> {
  gridRef: any = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      materialId: null,
    };
  }

  render() {
    return (
      <DashboardGrid
        isActive={this.props.isActive}
        ref={this.gridRef}
        getData={this.LoadGridData}
        saveSettings={{ prefix: "Materials", saveFilters: true }}
        excelFileName="Materials.xlsx"
        initRefresh={this.InitRefresh}
        columnsSettings={COLUMNS}
        filters={FILTERS}
        columnMenu={{
          filters: true,
          columns: false,
        }}
        getRowKey={(dataItem: simpleObject) => dataItem.MAId}
        selectSettings={{
          Component: TransactionJournal,
          pageId: "InventoryDashboard",
        }}
        onChangeGridData={this.props.onChangeGridData}
      />
    );
  }

  LoadGridData = async (filters: simpleObject) => {
    const result = await this.GetSQLData({
      spName: "DB_MaterialList",
      params: filters,
    });
    return result[0].map((x: simpleObject) => ({
      ...x,
      IsCPEStr: x.IsCPE ? "Yes" : "No",
      IsSNRequiredStr: x.IsSNRequired ? "Yes" : "No",
    }));
  };

  Refresh = () => {};

  InitRefresh = (refresh: any) => {
    this.Refresh = refresh;
  };
}

export default Materials;
