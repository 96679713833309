/* eslint-disable */
/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** SQL_ASSETS_AllowedHolders_Response */
export interface SQL_ASSETS_AllowedHolders_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLASSETSAllowedHoldersResponse = {
  id: "id",
  name: "name",
} as const;

/** SQL_ASSETS_My_Response */
export interface SQL_ASSETS_My_Response {
  /** @format  */
  name: string;
  /** @format  */
  number: string;
  /** @format  */
  serialNumber: string | null;
  /** @format double */
  estimatedCost: number | null;
  /** @format  */
  id: number;
  /** @format  */
  category: string;
  /** @format  */
  transferStatus: string | null;
  /** @format  */
  currentHolderName: string;
  /** @format  */
  transferToName: string | null;
  /** @format  */
  imageDocumentId: number | null;
}

export const PropsSQLASSETSMyResponse = {
  name: "name",
  number: "number",
  serialNumber: "serialNumber",
  estimatedCost: "estimatedCost",
  id: "id",
  category: "category",
  transferStatus: "transferStatus",
  currentHolderName: "currentHolderName",
  transferToName: "transferToName",
  imageDocumentId: "imageDocumentId",
} as const;

/** SQL_ASSETS_TransferChecklist_Response */
export interface SQL_ASSETS_TransferChecklist_Response {
  /** @format  */
  itemId: number;
  /** @format  */
  name: string;
  /** @format  */
  description: string | null;
}

export const PropsSQLASSETSTransferChecklistResponse = {
  itemId: "itemId",
  name: "name",
  description: "description",
} as const;

/** SQL_ASSETS_TransferChecklist_Request */
export interface SQL_ASSETS_TransferChecklist_Request {
  /** @format  */
  assetId?: number | null;
}

export const PropsSQLASSETSTransferChecklistRequest = {
  assetId: "assetId",
} as const;

/** SQL_CLPM_CreateZipLink_Response */
export interface SQL_CLPM_CreateZipLink_Response {
  /** @format  */
  linkId: number;
}

export const PropsSQLCLPMCreateZipLinkResponse = {
  linkId: "linkId",
} as const;

/** SQL_CLPM_CreateZipLink_Request */
export interface SQL_CLPM_CreateZipLink_Request {
  /** @format  */
  buildPlanId?: number | null;
  /** @format  */
  workOrderId?: number | null;
  /** @format boolean */
  approvedOnly?: boolean | null;
}

export const PropsSQLCLPMCreateZipLinkRequest = {
  buildPlanId: "buildPlanId",
  workOrderId: "workOrderId",
  approvedOnly: "approvedOnly",
} as const;

/** SQL_COD_ForNewRequest_Response_Categories */
export interface SQL_COD_ForNewRequest_Response_Categories {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLCODForNewRequestResponseCategories = {
  id: "id",
  name: "name",
} as const;

/** SQL_COD_ForNewRequest_Response_Cards_Categories */
export interface SQL_COD_ForNewRequest_Response_Cards_Categories {
  /** @format  */
  categoryId: number;
}

export const PropsSQLCODForNewRequestResponseCardsCategories = {
  categoryId: "categoryId",
} as const;

/** SQL_COD_ForNewRequest_Response_Cards */
export interface SQL_COD_ForNewRequest_Response_Cards {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  categories: SQL_COD_ForNewRequest_Response_Cards_Categories[];
}

export const PropsSQLCODForNewRequestResponseCards = {
  id: "id",
  name: "name",
  categories: "categories",
} as const;

/** SQL_COD_ForNewRequest_Response_BuildPlans */
export interface SQL_COD_ForNewRequest_Response_BuildPlans {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLCODForNewRequestResponseBuildPlans = {
  id: "id",
  name: "name",
} as const;

/** SQL_COD_ForNewRequest_Response */
export interface SQL_COD_ForNewRequest_Response {
  categories: SQL_COD_ForNewRequest_Response_Categories[];
  cards: SQL_COD_ForNewRequest_Response_Cards[];
  buildPlans: SQL_COD_ForNewRequest_Response_BuildPlans[];
}

export const PropsSQLCODForNewRequestResponse = {
  categories: "categories",
  cards: "cards",
  buildPlans: "buildPlans",
} as const;

/** SQL_COD_My_Response_Card */
export interface SQL_COD_My_Response_Card {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLCODMyResponseCard = {
  id: "id",
  name: "name",
} as const;

/** SQL_COD_My_Response_Category */
export interface SQL_COD_My_Response_Category {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLCODMyResponseCategory = {
  id: "id",
  name: "name",
} as const;

/** SQL_COD_My_Response_BuildPlan */
export interface SQL_COD_My_Response_BuildPlan {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLCODMyResponseBuildPlan = {
  id: "id",
  name: "name",
} as const;

/** SQL_COD_My_Response_Documents */
export interface SQL_COD_My_Response_Documents {
  /** @format  */
  id: number;
}

export const PropsSQLCODMyResponseDocuments = {
  id: "id",
} as const;

/** SQL_COD_My_Response */
export interface SQL_COD_My_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format double */
  amount: number;
  /** @format  */
  description: string | null;
  /** @format date-time */
  date: string;
  /** @format  */
  stage: string;
  /** @format  */
  store: string | null;
  card?: SQL_COD_My_Response_Card;
  category?: SQL_COD_My_Response_Category;
  buildPlan?: SQL_COD_My_Response_BuildPlan;
  documents: SQL_COD_My_Response_Documents[];
}

export const PropsSQLCODMyResponse = {
  id: "id",
  name: "name",
  amount: "amount",
  description: "description",
  date: "date",
  stage: "stage",
  store: "store",
  card: "card",
  category: "category",
  buildPlan: "buildPlan",
  documents: "documents",
} as const;

/** SQL_DB_Audits_Response */
export interface SQL_DB_Audits_Response {
  /** @format int64 */
  rowNum: number;
  /** @format  */
  tcId: number;
  /** @format date-time */
  date: string;
  /** @format  */
  employeeId: number;
  /** @format  */
  employeeName: string;
  /** @format  */
  periodId: number;
  /** @format  */
  periodName: string;
  /** @format date-time */
  firstClockIn: string | null;
  /** @format date-time */
  lastClockOut: string | null;
  /** @format double */
  clockedHours: number | null;
  /** @format double */
  auditLunchHours: number | null;
  /** @format double */
  approvedHours: number;
  /** @format  */
  auditRuleId: number;
  /** @format  */
  auditRuleName: string;
  /** @format boolean */
  isImportant: boolean;
  /** @format  */
  resultText: string | null;
  /** @format double */
  adjustment: number;
  /** @format double */
  manualLunchHours: number | null;
}

export const PropsSQLDBAuditsResponse = {
  rowNum: "rowNum",
  tcId: "tcId",
  date: "date",
  employeeId: "employeeId",
  employeeName: "employeeName",
  periodId: "periodId",
  periodName: "periodName",
  firstClockIn: "firstClockIn",
  lastClockOut: "lastClockOut",
  clockedHours: "clockedHours",
  auditLunchHours: "auditLunchHours",
  approvedHours: "approvedHours",
  auditRuleId: "auditRuleId",
  auditRuleName: "auditRuleName",
  isImportant: "isImportant",
  resultText: "resultText",
  adjustment: "adjustment",
  manualLunchHours: "manualLunchHours",
} as const;

/** SQL_DB_Audits_Request */
export interface SQL_DB_Audits_Request {
  /** @format date-time */
  dateFrom?: string | null;
  /** @format date-time */
  dateTo?: string | null;
  /** @format  */
  periodId?: number | null;
  /** @format  */
  tcId?: number | null;
}

export const PropsSQLDBAuditsRequest = {
  dateFrom: "dateFrom",
  dateTo: "dateTo",
  periodId: "periodId",
  tcId: "tcId",
} as const;

/** SQL_DB_Calendar_DispatchInfo_Response_DispatchInfo */
export interface SQL_DB_Calendar_DispatchInfo_Response_DispatchInfo {
  /** @format boolean */
  isDictionaryRow: boolean;
  /** @format  */
  workOrderId: number;
  /** @format  */
  workOrderName: string;
  /** @format date-time */
  date: string;
  /** @format  */
  crewLeadId: number;
  /** @format  */
  crewLeadName: string;
  /** @format date-time */
  arriveAfter: string | null;
  /** @format date-time */
  arriveBefore: string | null;
  /** @format date-time */
  departAfter: string | null;
  /** @format date-time */
  departBefore: string | null;
  /** @format date-time */
  clockInAfter: string | null;
  /** @format date-time */
  clockOutBefore: string | null;
  /** @format boolean */
  allowOvernight: boolean;
  /** @format  */
  workTypeTitle: string;
  /** @format boolean */
  remoteHotel: boolean;
  /** @format boolean */
  prevailingWage: boolean;
  /** @format  */
  comments: string | null;
  /** @format boolean */
  published: boolean;
  /** @format boolean */
  active: boolean;
  /** @format boolean */
  canPublish: boolean;
  /** @format  */
  crewContainerId: number | null;
  /** @format  */
  crewContainerName: string | null;
}

export const PropsSQLDBCalendarDispatchInfoResponseDispatchInfo = {
  isDictionaryRow: "isDictionaryRow",
  workOrderId: "workOrderId",
  workOrderName: "workOrderName",
  date: "date",
  crewLeadId: "crewLeadId",
  crewLeadName: "crewLeadName",
  arriveAfter: "arriveAfter",
  arriveBefore: "arriveBefore",
  departAfter: "departAfter",
  departBefore: "departBefore",
  clockInAfter: "clockInAfter",
  clockOutBefore: "clockOutBefore",
  allowOvernight: "allowOvernight",
  workTypeTitle: "workTypeTitle",
  remoteHotel: "remoteHotel",
  prevailingWage: "prevailingWage",
  comments: "comments",
  published: "published",
  active: "active",
  canPublish: "canPublish",
  crewContainerId: "crewContainerId",
  crewContainerName: "crewContainerName",
} as const;

/** SQL_DB_Calendar_DispatchInfo_Response_CrewMembers */
export interface SQL_DB_Calendar_DispatchInfo_Response_CrewMembers {
  /** @format  */
  crewMemberId: number;
  /** @format  */
  crewMemberName: string;
}

export const PropsSQLDBCalendarDispatchInfoResponseCrewMembers = {
  crewMemberId: "crewMemberId",
  crewMemberName: "crewMemberName",
} as const;

/** SQL_DB_Calendar_DispatchInfo_Response */
export interface SQL_DB_Calendar_DispatchInfo_Response {
  dispatchInfo: SQL_DB_Calendar_DispatchInfo_Response_DispatchInfo[];
  crewMembers: SQL_DB_Calendar_DispatchInfo_Response_CrewMembers[];
}

export const PropsSQLDBCalendarDispatchInfoResponse = {
  dispatchInfo: "dispatchInfo",
  crewMembers: "crewMembers",
} as const;

/** SQL_DB_Calendar_DispatchInfo_Request */
export interface SQL_DB_Calendar_DispatchInfo_Request {
  /** @format  */
  dsId?: number | null;
}

export const PropsSQLDBCalendarDispatchInfoRequest = {
  dsId: "dsId",
} as const;

/** SQL_DB_Calendar_GetData_Response_DispatchInfo */
export interface SQL_DB_Calendar_GetData_Response_DispatchInfo {
  /** @format  */
  id: number;
  /** @format  */
  bpId: number;
  /** @format  */
  woId: number;
  /** @format date-time */
  date: string;
  /** @format  */
  title: string;
  /** @format boolean */
  publishAllowed: boolean;
  /** @format  */
  state: string;
  /** @format boolean */
  isPastChanged: boolean;
  /** @format  */
  woType: string;
  /** @format  */
  project: string;
  /** @format  */
  scenario: string;
  /** @format  */
  comments: string | null;
  /** @format  */
  woCatColor: string | null;
  /** @format boolean */
  allowOvernight: boolean;
  /** @format boolean */
  isImportant: boolean;
  /** @format boolean */
  isMatchCertRequirements: boolean;
}

export const PropsSQLDBCalendarGetDataResponseDispatchInfo = {
  id: "id",
  bpId: "bpId",
  woId: "woId",
  date: "date",
  title: "title",
  publishAllowed: "publishAllowed",
  state: "state",
  isPastChanged: "isPastChanged",
  woType: "woType",
  project: "project",
  scenario: "scenario",
  comments: "comments",
  woCatColor: "woCatColor",
  allowOvernight: "allowOvernight",
  isImportant: "isImportant",
  isMatchCertRequirements: "isMatchCertRequirements",
} as const;

/** SQL_DB_Calendar_GetData_Response_DispatchCrew */
export interface SQL_DB_Calendar_GetData_Response_DispatchCrew {
  /** @format  */
  id: number;
  /** @format boolean */
  isActive: boolean;
  /** @format date-time */
  date: string;
  /** @format  */
  employeeId: number;
}

export const PropsSQLDBCalendarGetDataResponseDispatchCrew = {
  id: "id",
  isActive: "isActive",
  date: "date",
  employeeId: "employeeId",
} as const;

/** SQL_DB_Calendar_GetData_Response_Resources */
export interface SQL_DB_Calendar_GetData_Response_Resources {
  /** @format date-time */
  date: string;
  /** @format  */
  id: number;
  /** @format  */
  label: string;
  /** @format boolean */
  isLimited: boolean;
  /** @format boolean */
  isSuspended: boolean;
  /** @format  */
  category: string;
  /** @format  */
  tcId: number | null;
  /** @format  */
  reason: string | null;
  /** @format  */
  dsCnt: number;
  /** @format double */
  weekHours: number | null;
  /** @format double */
  dayHours: number | null;
}

export const PropsSQLDBCalendarGetDataResponseResources = {
  date: "date",
  id: "id",
  label: "label",
  isLimited: "isLimited",
  isSuspended: "isSuspended",
  category: "category",
  tcId: "tcId",
  reason: "reason",
  dsCnt: "dsCnt",
  weekHours: "weekHours",
  dayHours: "dayHours",
} as const;

/** SQL_DB_Calendar_GetData_Response */
export interface SQL_DB_Calendar_GetData_Response {
  dispatchInfo: SQL_DB_Calendar_GetData_Response_DispatchInfo[];
  dispatchCrew: SQL_DB_Calendar_GetData_Response_DispatchCrew[];
  resources: SQL_DB_Calendar_GetData_Response_Resources[];
}

export const PropsSQLDBCalendarGetDataResponse = {
  dispatchInfo: "dispatchInfo",
  dispatchCrew: "dispatchCrew",
  resources: "resources",
} as const;

/** SQL_DB_Calendar_GetData_Request */
export interface SQL_DB_Calendar_GetData_Request {
  /** @format  */
  resourceId?: number | null;
  /** @format date-time */
  beginDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format  */
  regionId?: number | null;
  /** @format  */
  marketId?: number | null;
  /** @format  */
  ourOrgId?: number | null;
  /** @format  */
  bpOwnerId?: number | null;
  /** @format boolean */
  onlyMy?: boolean | null;
  /** @format  */
  viewName?: string | null;
  /** @format  */
  buildPlanId?: number | null;
  /** @format  */
  workOrderId?: number | null;
  /** @format  */
  classId?: number | null;
  /** @format  */
  customerId?: number | null;
  /** @format  */
  crewContainerId?: number | null;
}

export const PropsSQLDBCalendarGetDataRequest = {
  resourceId: "resourceId",
  beginDate: "beginDate",
  endDate: "endDate",
  regionId: "regionId",
  marketId: "marketId",
  ourOrgId: "ourOrgId",
  bpOwnerId: "bpOwnerId",
  onlyMy: "onlyMy",
  viewName: "viewName",
  buildPlanId: "buildPlanId",
  workOrderId: "workOrderId",
  classId: "classId",
  customerId: "customerId",
  crewContainerId: "crewContainerId",
} as const;

/** SQL_DB_Calendar_GetData_Dashboard_Response */
export interface SQL_DB_Calendar_GetData_Dashboard_Response {
  /** @format  */
  id: number;
  /** @format date-time */
  date: string;
  /** @format  */
  title: string;
  /** @format  */
  project: string;
  /** @format  */
  scenario: string;
  /** @format  */
  market: string;
  /** @format  */
  bpNumber: string;
  /** @format  */
  woNumber: string;
  /** @format boolean */
  isImportant: boolean;
  /** @format  */
  woType: string;
  /** @format  */
  crewLead: string;
  /** @format  */
  progress: string;
  /** @format  */
  scheduledClockIn: string | null;
  /** @format  */
  clockedIn: string | null;
  /** @format boolean */
  clockedInCorrect: boolean;
  /** @format  */
  scheduledArrival: string | null;
  /** @format date-time */
  arrived: string | null;
  /** @format boolean */
  arrivedCorrect: boolean;
  /** @format  */
  scheduledDepart: string;
  /** @format date-time */
  departed: string | null;
  /** @format boolean */
  departedCorrect: boolean;
  /** @format  */
  bpId: number;
  /** @format  */
  woId: number;
  /** @format  */
  woStatus: string;
  /** @format  */
  comment: string | null;
  /** @format boolean */
  allowOvernight: boolean;
  /** @format boolean */
  isMatchCertRequirements: boolean;
}

export const PropsSQLDBCalendarGetDataDashboardResponse = {
  id: "id",
  date: "date",
  title: "title",
  project: "project",
  scenario: "scenario",
  market: "market",
  bpNumber: "bpNumber",
  woNumber: "woNumber",
  isImportant: "isImportant",
  woType: "woType",
  crewLead: "crewLead",
  progress: "progress",
  scheduledClockIn: "scheduledClockIn",
  clockedIn: "clockedIn",
  clockedInCorrect: "clockedInCorrect",
  scheduledArrival: "scheduledArrival",
  arrived: "arrived",
  arrivedCorrect: "arrivedCorrect",
  scheduledDepart: "scheduledDepart",
  departed: "departed",
  departedCorrect: "departedCorrect",
  bpId: "bpId",
  woId: "woId",
  woStatus: "woStatus",
  comment: "comment",
  allowOvernight: "allowOvernight",
  isMatchCertRequirements: "isMatchCertRequirements",
} as const;

/** SQL_DB_Calendar_GetData_Dashboard_Request */
export interface SQL_DB_Calendar_GetData_Dashboard_Request {
  /** @format  */
  resourceId?: number | null;
  /** @format date-time */
  beginDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format  */
  regionId?: number | null;
  /** @format  */
  marketId?: number | null;
  /** @format  */
  ourOrgId?: number | null;
  /** @format  */
  bpOwnerId?: number | null;
  /** @format boolean */
  onlyMy?: boolean | null;
  /** @format  */
  buildPlanId?: number | null;
  /** @format  */
  workOrderId?: number | null;
  /** @format  */
  classId?: number | null;
  /** @format  */
  customerId?: number | null;
  /** @format  */
  crewContainerId?: number | null;
}

export const PropsSQLDBCalendarGetDataDashboardRequest = {
  resourceId: "resourceId",
  beginDate: "beginDate",
  endDate: "endDate",
  regionId: "regionId",
  marketId: "marketId",
  ourOrgId: "ourOrgId",
  bpOwnerId: "bpOwnerId",
  onlyMy: "onlyMy",
  buildPlanId: "buildPlanId",
  workOrderId: "workOrderId",
  classId: "classId",
  customerId: "customerId",
  crewContainerId: "crewContainerId",
} as const;

/** SQL_DB_Calendar_Resources_Response_Resources_EmployeeCertificates */
export interface SQL_DB_Calendar_Resources_Response_Resources_EmployeeCertificates {
  /** @format  */
  certificateId: number;
}

export const PropsSQLDBCalendarResourcesResponseResourcesEmployeeCertificates =
  {
    certificateId: "certificateId",
  } as const;

/** SQL_DB_Calendar_Resources_Response_Resources */
export interface SQL_DB_Calendar_Resources_Response_Resources {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format boolean */
  isLimited: boolean;
  employeeCertificates: SQL_DB_Calendar_Resources_Response_Resources_EmployeeCertificates[];
}

export const PropsSQLDBCalendarResourcesResponseResources = {
  id: "id",
  name: "name",
  isLimited: "isLimited",
  employeeCertificates: "employeeCertificates",
} as const;

/** SQL_DB_Calendar_Resources_Response_CrewContainers_CrewMembers */
export interface SQL_DB_Calendar_Resources_Response_CrewContainers_CrewMembers {
  /** @format  */
  crewMemberId: number;
}

export const PropsSQLDBCalendarResourcesResponseCrewContainersCrewMembers = {
  crewMemberId: "crewMemberId",
} as const;

/** SQL_DB_Calendar_Resources_Response_CrewContainers */
export interface SQL_DB_Calendar_Resources_Response_CrewContainers {
  /** @format  */
  name: string;
  /** @format  */
  crewLeadId: number | null;
  /** @format  */
  crewContainerId: number;
  crewMembers: SQL_DB_Calendar_Resources_Response_CrewContainers_CrewMembers[];
}

export const PropsSQLDBCalendarResourcesResponseCrewContainers = {
  name: "name",
  crewLeadId: "crewLeadId",
  crewContainerId: "crewContainerId",
  crewMembers: "crewMembers",
} as const;

/** SQL_DB_Calendar_Resources_Response */
export interface SQL_DB_Calendar_Resources_Response {
  resources: SQL_DB_Calendar_Resources_Response_Resources[];
  crewContainers: SQL_DB_Calendar_Resources_Response_CrewContainers[];
}

export const PropsSQLDBCalendarResourcesResponse = {
  resources: "resources",
  crewContainers: "crewContainers",
} as const;

/** SQL_DB_Calendar_Resources_Request */
export interface SQL_DB_Calendar_Resources_Request {
  /** @format  */
  woId?: number | null;
}

export const PropsSQLDBCalendarResourcesRequest = {
  woId: "woId",
} as const;

/** SQL_DB_Calendar_WOCertificateInfo_Response */
export interface SQL_DB_Calendar_WOCertificateInfo_Response {
  /** @format  */
  certificateId: number;
  /** @format  */
  certificateName: string;
  /** @format  */
  minOfCrewReq: number;
}

export const PropsSQLDBCalendarWOCertificateInfoResponse = {
  certificateId: "certificateId",
  certificateName: "certificateName",
  minOfCrewReq: "minOfCrewReq",
} as const;

/** SQL_DB_Calendar_WOCertificateInfo_Request */
export interface SQL_DB_Calendar_WOCertificateInfo_Request {
  /** @format  */
  woId?: number | null;
}

export const PropsSQLDBCalendarWOCertificateInfoRequest = {
  woId: "woId",
} as const;

/** SQL_DB_GetCounters_Response */
export interface SQL_DB_GetCounters_Response {
  /** @format  */
  unReadJobs: number;
  /** @format  */
  reviewTimeCards: number;
  /** @format  */
  vimInvoiceCount: number;
}

export const PropsSQLDBGetCountersResponse = {
  unReadJobs: "unReadJobs",
  reviewTimeCards: "reviewTimeCards",
  vimInvoiceCount: "vimInvoiceCount",
} as const;

/** SQL_DB_Inbox_Response */
export interface SQL_DB_Inbox_Response {
  /** @format  */
  jobId: number;
  /** @format  */
  linkId: number;
  /** @format  */
  subject: string;
  /** @format date-time */
  created: string;
  /** @format date-time */
  completed: string | null;
  /** @format date-time */
  deadline: string | null;
  /** @format  */
  kind: string;
  /** @format  */
  state: string;
  /** @format boolean */
  isRead: boolean;
  /** @format  */
  initiator: string | null;
  /** @format  */
  route: string;
  /** @format  */
  performer: string | null;
  /** @format boolean */
  canBeDeleted: boolean;
}

export const PropsSQLDBInboxResponse = {
  jobId: "jobId",
  linkId: "linkId",
  subject: "subject",
  created: "created",
  completed: "completed",
  deadline: "deadline",
  kind: "kind",
  state: "state",
  isRead: "isRead",
  initiator: "initiator",
  route: "route",
  performer: "performer",
  canBeDeleted: "canBeDeleted",
} as const;

/** SQL_DB_Inbox_GetJobInfo_Response_Attachments */
export interface SQL_DB_Inbox_GetJobInfo_Response_Attachments {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format  */
  type: string;
}

export const PropsSQLDBInboxGetJobInfoResponseAttachments = {
  id: "id",
  name: "name",
  type: "type",
} as const;

/** SQL_DB_Inbox_GetJobInfo_Response_JobResults */
export interface SQL_DB_Inbox_GetJobInfo_Response_JobResults {
  /** @format  */
  jobResult: string;
  /** @format boolean */
  commentsRequired: boolean;
}

export const PropsSQLDBInboxGetJobInfoResponseJobResults = {
  jobResult: "jobResult",
  commentsRequired: "commentsRequired",
} as const;

/** SQL_DB_Inbox_GetJobInfo_Response */
export interface SQL_DB_Inbox_GetJobInfo_Response {
  attachments: SQL_DB_Inbox_GetJobInfo_Response_Attachments[];
  jobResults: SQL_DB_Inbox_GetJobInfo_Response_JobResults[];
}

export const PropsSQLDBInboxGetJobInfoResponse = {
  attachments: "attachments",
  jobResults: "jobResults",
} as const;

/** SQL_DB_Inbox_GetJobInfo_Request */
export interface SQL_DB_Inbox_GetJobInfo_Request {
  /** @format  */
  jobId?: number | null;
}

export const PropsSQLDBInboxGetJobInfoRequest = {
  jobId: "jobId",
} as const;

/** SQL_DB_ShortCuts_Response */
export interface SQL_DB_ShortCuts_Response {
  /** @format  */
  shortCutId: number;
  /** @format  */
  shortCutName: string;
  /** @format  */
  shortCutType: string;
}

export const PropsSQLDBShortCutsResponse = {
  shortCutId: "shortCutId",
  shortCutName: "shortCutName",
  shortCutType: "shortCutType",
} as const;

/** SQL_DB_Status_Response_Roles */
export interface SQL_DB_Status_Response_Roles {
  /** @format  */
  id: number;
  /** @format  */
  crewLead: string | null;
  /** @format  */
  copLead: string | null;
}

export const PropsSQLDBStatusResponseRoles = {
  id: "id",
  crewLead: "crewLead",
  copLead: "copLead",
} as const;

/** SQL_DB_Status_Response_Milestones */
export interface SQL_DB_Status_Response_Milestones {
  /** @format  */
  id: number;
  /** @format  */
  constStart_Projected: string | null;
  /** @format  */
  constStart_Actual: string | null;
  /** @format  */
  consCompl_Projected: string | null;
  /** @format  */
  consCompl_Actual: string | null;
  /** @format  */
  commStart_Projected: string | null;
  /** @format  */
  commStart_Actual: string | null;
  /** @format  */
  commCompl_Projected: string | null;
  /** @format  */
  commCompl_Actual: string | null;
  /** @format  */
  integStart_Projected: string | null;
  /** @format  */
  integStart_Actual: string | null;
  /** @format  */
  integCompl_Projected: string | null;
  /** @format  */
  integCompl_Actual: string | null;
  /** @format  */
  copSubmit_Projected: string | null;
  /** @format  */
  copSubmit_Actual: string | null;
  /** @format  */
  copReject_Projected: string | null;
  /** @format  */
  copReject_Actual: string | null;
  /** @format  */
  copResubm_Projected: string | null;
  /** @format  */
  copResubm_Actual: string | null;
  /** @format  */
  copAppr_Projected: string | null;
  /** @format  */
  copAppr_Actual: string | null;
  /** @format  */
  finInvSubm_Projected: string | null;
  /** @format  */
  finInvSubm_Actual: string | null;
  /** @format  */
  steelOrder_Projected: string | null;
  /** @format  */
  steelOrder_Actual: string | null;
  /** @format  */
  craneLift_Projected: string | null;
  /** @format  */
  craneLift_Actual: string | null;
}

export const PropsSQLDBStatusResponseMilestones = {
  id: "id",
  constStart_Projected: "constStart_Projected",
  constStart_Actual: "constStart_Actual",
  consCompl_Projected: "consCompl_Projected",
  consCompl_Actual: "consCompl_Actual",
  commStart_Projected: "commStart_Projected",
  commStart_Actual: "commStart_Actual",
  commCompl_Projected: "commCompl_Projected",
  commCompl_Actual: "commCompl_Actual",
  integStart_Projected: "integStart_Projected",
  integStart_Actual: "integStart_Actual",
  integCompl_Projected: "integCompl_Projected",
  integCompl_Actual: "integCompl_Actual",
  copSubmit_Projected: "copSubmit_Projected",
  copSubmit_Actual: "copSubmit_Actual",
  copReject_Projected: "copReject_Projected",
  copReject_Actual: "copReject_Actual",
  copResubm_Projected: "copResubm_Projected",
  copResubm_Actual: "copResubm_Actual",
  copAppr_Projected: "copAppr_Projected",
  copAppr_Actual: "copAppr_Actual",
  finInvSubm_Projected: "finInvSubm_Projected",
  finInvSubm_Actual: "finInvSubm_Actual",
  steelOrder_Projected: "steelOrder_Projected",
  steelOrder_Actual: "steelOrder_Actual",
  craneLift_Projected: "craneLift_Projected",
  craneLift_Actual: "craneLift_Actual",
} as const;

/** SQL_DB_Status_Response_BOM */
export interface SQL_DB_Status_Response_BOM {
  /** @format  */
  id: number;
  /** @format double */
  total: number | null;
  /** @format double */
  kitted: number | null;
  /** @format  */
  opened: number | null;
  /** @format  */
  initialSent: string | null;
}

export const PropsSQLDBStatusResponseBOM = {
  id: "id",
  total: "total",
  kitted: "kitted",
  opened: "opened",
  initialSent: "initialSent",
} as const;

/** SQL_DB_Status_Response_Financial */
export interface SQL_DB_Status_Response_Financial {
  /** @format  */
  id: number;
  /** @format double */
  salesOrder: number | null;
  /** @format double */
  unallocatedCustomerPO: number | null;
  /** @format double */
  allocatedCustomerPO: number | null;
  /** @format double */
  sopoVariance: number | null;
  /** @format double */
  soProjectedRevenueVariance: number | null;
  /** @format double */
  budgetHrs: number | null;
  /** @format double */
  totalSpend: number | null;
  /** @format double */
  totalSpendPercent: number | null;
  /** @format double */
  submittedInvoices: number | null;
  /** @format double */
  remainingSpend: number | null;
  /** @format double */
  projectedSpend: number | null;
  /** @format double */
  projectedRevenue: number | null;
  /** @format  */
  budgetStatus: string | null;
  /** @format  */
  budgetApproved: string | null;
  /** @format  */
  lastQuoteDate: string | null;
  /** @format  */
  lastQuoteSubmitted: string | null;
  /** @format  */
  lastPODate: string | null;
  /** @format double */
  hoursConsumed: number | null;
  /** @format double */
  hoursRemaining: number | null;
  /** @format  */
  lastInvoiceDate: string | null;
  /** @format double */
  remainingToInvoice: number | null;
  /** @format double */
  readyToBill: number | null;
  /** @format  */
  projectedBillingDate: string | null;
  /** @format double */
  vimPoBalance: number | null;
  /** @format double */
  wip: number | null;
}

export const PropsSQLDBStatusResponseFinancial = {
  id: "id",
  salesOrder: "salesOrder",
  unallocatedCustomerPO: "unallocatedCustomerPO",
  allocatedCustomerPO: "allocatedCustomerPO",
  sopoVariance: "sopoVariance",
  soProjectedRevenueVariance: "soProjectedRevenueVariance",
  budgetHrs: "budgetHrs",
  totalSpend: "totalSpend",
  totalSpendPercent: "totalSpendPercent",
  submittedInvoices: "submittedInvoices",
  remainingSpend: "remainingSpend",
  projectedSpend: "projectedSpend",
  projectedRevenue: "projectedRevenue",
  budgetStatus: "budgetStatus",
  budgetApproved: "budgetApproved",
  lastQuoteDate: "lastQuoteDate",
  lastQuoteSubmitted: "lastQuoteSubmitted",
  lastPODate: "lastPODate",
  hoursConsumed: "hoursConsumed",
  hoursRemaining: "hoursRemaining",
  lastInvoiceDate: "lastInvoiceDate",
  remainingToInvoice: "remainingToInvoice",
  readyToBill: "readyToBill",
  projectedBillingDate: "projectedBillingDate",
  vimPoBalance: "vimPoBalance",
  wip: "wip",
} as const;

/** SQL_DB_Status_Response */
export interface SQL_DB_Status_Response {
  /** @format int64 */
  rowNum: number;
  /** @format  */
  id: number;
  /** @format boolean */
  active: boolean;
  /** @format  */
  bpState: string;
  /** @format  */
  number: string;
  /** @format  */
  started: string | null;
  /** @format  */
  completion: number;
  /** @format boolean */
  pwByDefault: boolean;
  /** @format  */
  pwLastUpdate: string | null;
  /** @format boolean */
  excludeFromWIP: boolean;
  /** @format  */
  permitStatusComment: string | null;
  /** @format  */
  cxStatusComment: string | null;
  /** @format  */
  project: string;
  /** @format  */
  scenario: string;
  /** @format  */
  site: string;
  /** @format  */
  siteAddress: string;
  /** @format  */
  profitCenter: string | null;
  /** @format  */
  region: string;
  /** @format  */
  market: string;
  /** @format  */
  customer: string | null;
  /** @format  */
  customerJob: string | null;
  /** @format  */
  carrier: string;
  /** @format  */
  owner: string;
  /** @format  */
  class: string | null;
  /** @format  */
  category: string | null;
  /** @format  */
  cxStatus: string | null;
  /** @format  */
  permitStatus: string | null;
  roles: SQL_DB_Status_Response_Roles;
  milestones: SQL_DB_Status_Response_Milestones;
  bom: SQL_DB_Status_Response_BOM;
  financial: SQL_DB_Status_Response_Financial;
}

export const PropsSQLDBStatusResponse = {
  rowNum: "rowNum",
  id: "id",
  active: "active",
  bpState: "bpState",
  number: "number",
  started: "started",
  completion: "completion",
  pwByDefault: "pwByDefault",
  pwLastUpdate: "pwLastUpdate",
  excludeFromWIP: "excludeFromWIP",
  permitStatusComment: "permitStatusComment",
  cxStatusComment: "cxStatusComment",
  project: "project",
  scenario: "scenario",
  site: "site",
  siteAddress: "siteAddress",
  profitCenter: "profitCenter",
  region: "region",
  market: "market",
  customer: "customer",
  customerJob: "customerJob",
  carrier: "carrier",
  owner: "owner",
  class: "class",
  category: "category",
  cxStatus: "cxStatus",
  permitStatus: "permitStatus",
  roles: "roles",
  milestones: "milestones",
  bom: "bom",
  financial: "financial",
} as const;

/** SQL_DB_Status_Request */
export interface SQL_DB_Status_Request {
  /** @format date-time */
  onDate?: string | null;
}

export const PropsSQLDBStatusRequest = {
  onDate: "onDate",
} as const;

/** SQL_DB_Status_Milestones_Response */
export interface SQL_DB_Status_Milestones_Response {
  /** @format  */
  name: string;
  /** @format  */
  code: string;
}

export const PropsSQLDBStatusMilestonesResponse = {
  name: "name",
  code: "code",
} as const;

/** SQL_DB_TimeCardTimeDetails_Response */
export interface SQL_DB_TimeCardTimeDetails_Response {
  /** @format int64 */
  rowNum: number;
  /** @format  */
  tcId: number;
  /** @format date-time */
  date: string;
  /** @format  */
  employeeId: number;
  /** @format  */
  employeeName: string;
  /** @format  */
  classId: number | null;
  /** @format  */
  className: string | null;
  /** @format  */
  departmentId: number | null;
  /** @format  */
  departmentName: string | null;
  /** @format  */
  categoryId: number;
  /** @format  */
  categoryName: string;
  /** @format date-time */
  start: string | null;
  /** @format date-time */
  finish: string | null;
  /** @format  */
  costTypeName: string | null;
  /** @format  */
  woId: number | null;
  /** @format  */
  woName: string | null;
  /** @format  */
  stateId: number | null;
  /** @format  */
  stateName: string | null;
  /** @format double */
  regularHours: number | null;
  /** @format double */
  overtimeHours: number | null;
  /** @format double */
  doubleHours: number | null;
  /** @format double */
  totalHours: number | null;
  /** @format boolean */
  isPW: boolean | null;
  /** @format  */
  overnight: string | null;
  /** @format boolean */
  isLockedWageType: boolean | null;
  /** @format boolean */
  isOTExclude: boolean | null;
  /** @format double */
  lunchHours: number | null;
}

export const PropsSQLDBTimeCardTimeDetailsResponse = {
  rowNum: "rowNum",
  tcId: "tcId",
  date: "date",
  employeeId: "employeeId",
  employeeName: "employeeName",
  classId: "classId",
  className: "className",
  departmentId: "departmentId",
  departmentName: "departmentName",
  categoryId: "categoryId",
  categoryName: "categoryName",
  start: "start",
  finish: "finish",
  costTypeName: "costTypeName",
  woId: "woId",
  woName: "woName",
  stateId: "stateId",
  stateName: "stateName",
  regularHours: "regularHours",
  overtimeHours: "overtimeHours",
  doubleHours: "doubleHours",
  totalHours: "totalHours",
  isPW: "isPW",
  overnight: "overnight",
  isLockedWageType: "isLockedWageType",
  isOTExclude: "isOTExclude",
  lunchHours: "lunchHours",
} as const;

/** SQL_DB_TimeCardTimeDetails_Request */
export interface SQL_DB_TimeCardTimeDetails_Request {
  /** @format  */
  tcId?: number | null;
  /** @format date-time */
  dateFrom?: string | null;
  /** @format date-time */
  dateTo?: string | null;
  /** @format  */
  periodId?: number | null;
  /** @format  */
  reviewerId?: number | null;
  /** @format  */
  reviewStateId?: string | null;
  /** @format  */
  auditRuleId?: string | null;
  /** @format  */
  periodTypeId?: number | null;
}

export const PropsSQLDBTimeCardTimeDetailsRequest = {
  tcId: "tcId",
  dateFrom: "dateFrom",
  dateTo: "dateTo",
  periodId: "periodId",
  reviewerId: "reviewerId",
  reviewStateId: "reviewStateId",
  auditRuleId: "auditRuleId",
  periodTypeId: "periodTypeId",
} as const;

/** SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo */
export interface SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo {
  /** @format date-time */
  date: string;
  /** @format  */
  employeeId: number;
  /** @format  */
  employeeName: string;
}

export const PropsSQLDBTKGetTCAdjustmentInfoResponseTimeCardInfo = {
  date: "date",
  employeeId: "employeeId",
  employeeName: "employeeName",
} as const;

/** SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentInfo */
export interface SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentInfo {
  /** @format  */
  id: number;
  /** @format boolean */
  isActive: boolean;
  /** @format  */
  authorName: string;
  /** @format  */
  lastChangeByName: string;
  /** @format date-time */
  lastChangeDate: string;
  /** @format double */
  lunchDuration: number | null;
  /** @format  */
  comment: string | null;
  /** @format boolean */
  canChange: boolean;
  /** @format boolean */
  canDelete: boolean;
  /** @format double */
  clockedHours: number | null;
  /** @format double */
  approvedHours: number | null;
  /** @format double */
  totalHours: number | null;
}

export const PropsSQLDBTKGetTCAdjustmentInfoResponseAdjustmentInfo = {
  id: "id",
  isActive: "isActive",
  authorName: "authorName",
  lastChangeByName: "lastChangeByName",
  lastChangeDate: "lastChangeDate",
  lunchDuration: "lunchDuration",
  comment: "comment",
  canChange: "canChange",
  canDelete: "canDelete",
  clockedHours: "clockedHours",
  approvedHours: "approvedHours",
  totalHours: "totalHours",
} as const;

/** SQL_DB_TK_GetTCAdjustmentInfo_Response_AuditAdjustmentInfo */
export interface SQL_DB_TK_GetTCAdjustmentInfo_Response_AuditAdjustmentInfo {
  /** @format double */
  lunchDuration: number | null;
  /** @format boolean */
  canChange: boolean;
}

export const PropsSQLDBTKGetTCAdjustmentInfoResponseAuditAdjustmentInfo = {
  lunchDuration: "lunchDuration",
  canChange: "canChange",
} as const;

/** SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentTimeLines */
export interface SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentTimeLines {
  /** @format  */
  rowNumber: number;
  /** @format double */
  actualDuration: number;
  /** @format double */
  approvedDuration: number;
  /** @format date-time */
  start: string;
  /** @format date-time */
  finish: string;
  /** @format  */
  costTypeCode: string;
  /** @format  */
  costTypeName: string;
  /** @format  */
  costTypeColor: string;
  /** @format  */
  stateCode: string | null;
}

export const PropsSQLDBTKGetTCAdjustmentInfoResponseAdjustmentTimeLines = {
  rowNumber: "rowNumber",
  actualDuration: "actualDuration",
  approvedDuration: "approvedDuration",
  start: "start",
  finish: "finish",
  costTypeCode: "costTypeCode",
  costTypeName: "costTypeName",
  costTypeColor: "costTypeColor",
  stateCode: "stateCode",
} as const;

/** SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation */
export interface SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation {
  /** @format  */
  rowNumber: number;
  /** @format  */
  workOrderId: number;
  /** @format  */
  workOrderCode: string;
  /** @format  */
  workOrderNumber: string;
  /** @format  */
  workOrderName: string;
  /** @format  */
  percentage: number;
}

export const PropsSQLDBTKGetTCAdjustmentInfoResponseAdjustmentAllocation = {
  rowNumber: "rowNumber",
  workOrderId: "workOrderId",
  workOrderCode: "workOrderCode",
  workOrderNumber: "workOrderNumber",
  workOrderName: "workOrderName",
  percentage: "percentage",
} as const;

/** SQL_DB_TK_GetTCAdjustmentInfo_Response_TETimeLines */
export interface SQL_DB_TK_GetTCAdjustmentInfo_Response_TETimeLines {
  /** @format  */
  rowNumber: number;
  /** @format double */
  actualDuration: number;
  /** @format double */
  approvedDuration: number;
  /** @format date-time */
  start: string;
  /** @format date-time */
  finish: string;
  /** @format  */
  costTypeCode: string | null;
  /** @format  */
  costTypeName: string | null;
  /** @format  */
  costTypeColor: string | null;
  /** @format  */
  stateCode: string | null;
}

export const PropsSQLDBTKGetTCAdjustmentInfoResponseTETimeLines = {
  rowNumber: "rowNumber",
  actualDuration: "actualDuration",
  approvedDuration: "approvedDuration",
  start: "start",
  finish: "finish",
  costTypeCode: "costTypeCode",
  costTypeName: "costTypeName",
  costTypeColor: "costTypeColor",
  stateCode: "stateCode",
} as const;

/** SQL_DB_TK_GetTCAdjustmentInfo_Response_TEAllocation */
export interface SQL_DB_TK_GetTCAdjustmentInfo_Response_TEAllocation {
  /** @format  */
  rowNumber: number;
  /** @format  */
  workOrderId: number;
  /** @format  */
  workOrderCode: string;
  /** @format  */
  workOrderNumber: string;
  /** @format  */
  workOrderName: string;
  /** @format  */
  percentage: number;
}

export const PropsSQLDBTKGetTCAdjustmentInfoResponseTEAllocation = {
  rowNumber: "rowNumber",
  workOrderId: "workOrderId",
  workOrderCode: "workOrderCode",
  workOrderNumber: "workOrderNumber",
  workOrderName: "workOrderName",
  percentage: "percentage",
} as const;

/** SQL_DB_TK_GetTCAdjustmentInfo_Response */
export interface SQL_DB_TK_GetTCAdjustmentInfo_Response {
  timeCardInfo: SQL_DB_TK_GetTCAdjustmentInfo_Response_TimeCardInfo[];
  adjustmentInfo: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentInfo[];
  auditAdjustmentInfo: SQL_DB_TK_GetTCAdjustmentInfo_Response_AuditAdjustmentInfo[];
  adjustmentTimeLines: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentTimeLines[];
  adjustmentAllocation: SQL_DB_TK_GetTCAdjustmentInfo_Response_AdjustmentAllocation[];
  teTimeLines: SQL_DB_TK_GetTCAdjustmentInfo_Response_TETimeLines[];
  teAllocation: SQL_DB_TK_GetTCAdjustmentInfo_Response_TEAllocation[];
}

export const PropsSQLDBTKGetTCAdjustmentInfoResponse = {
  timeCardInfo: "timeCardInfo",
  adjustmentInfo: "adjustmentInfo",
  auditAdjustmentInfo: "auditAdjustmentInfo",
  adjustmentTimeLines: "adjustmentTimeLines",
  adjustmentAllocation: "adjustmentAllocation",
  teTimeLines: "teTimeLines",
  teAllocation: "teAllocation",
} as const;

/** SQL_DB_TK_GetTCAdjustmentInfo_Request */
export interface SQL_DB_TK_GetTCAdjustmentInfo_Request {
  /** @format  */
  TCId?: number | null;
}

export const PropsSQLDBTKGetTCAdjustmentInfoRequest = {
  TCId: "TCId",
} as const;

/** SQL_DB_TK_GetTCSummary_Response_TimeCardInfo */
export interface SQL_DB_TK_GetTCSummary_Response_TimeCardInfo {
  /** @format date-time */
  date: string;
  /** @format  */
  employeeId: number;
  /** @format  */
  employeeName: string;
  /** @format  */
  employeeCategoryId: number;
  /** @format  */
  employeeCategoryName: string;
  /** @format  */
  periodId: number;
  /** @format  */
  periodName: string;
  /** @format date-time */
  firstClockIn: string | null;
  /** @format date-time */
  lastClockOut: string | null;
  /** @format  */
  reasonId: number | null;
  /** @format  */
  reasonName: string | null;
  /** @format boolean */
  isWageAllocationLocked: boolean;
  /** @format boolean */
  canChangeReason: boolean;
  /** @format double */
  sickHoursRequestedNotCompleted: number | null;
  /** @format double */
  sickHoursAvailableToRequest: number;
  /** @format double */
  vacationHoursRequestedNotCompleted: number | null;
  /** @format double */
  vacationHoursAvailableToRequest: number;
  /** @format double */
  sickHoursUsedNotSynced: number | null;
  /** @format double */
  vacationHoursUsedNotSynced: number | null;
}

export const PropsSQLDBTKGetTCSummaryResponseTimeCardInfo = {
  date: "date",
  employeeId: "employeeId",
  employeeName: "employeeName",
  employeeCategoryId: "employeeCategoryId",
  employeeCategoryName: "employeeCategoryName",
  periodId: "periodId",
  periodName: "periodName",
  firstClockIn: "firstClockIn",
  lastClockOut: "lastClockOut",
  reasonId: "reasonId",
  reasonName: "reasonName",
  isWageAllocationLocked: "isWageAllocationLocked",
  canChangeReason: "canChangeReason",
  sickHoursRequestedNotCompleted: "sickHoursRequestedNotCompleted",
  sickHoursAvailableToRequest: "sickHoursAvailableToRequest",
  vacationHoursRequestedNotCompleted: "vacationHoursRequestedNotCompleted",
  vacationHoursAvailableToRequest: "vacationHoursAvailableToRequest",
  sickHoursUsedNotSynced: "sickHoursUsedNotSynced",
  vacationHoursUsedNotSynced: "vacationHoursUsedNotSynced",
} as const;

/** SQL_DB_TK_GetTCSummary_Response_NonWageSummary */
export interface SQL_DB_TK_GetTCSummary_Response_NonWageSummary {
  /** @format  */
  woId: number;
  /** @format  */
  woName: string;
  /** @format  */
  piName: string;
  /** @format  */
  quantity: number;
}

export const PropsSQLDBTKGetTCSummaryResponseNonWageSummary = {
  woId: "woId",
  woName: "woName",
  piName: "piName",
  quantity: "quantity",
} as const;

/** SQL_DB_TK_GetTCSummary_Response_History */
export interface SQL_DB_TK_GetTCSummary_Response_History {
  /** @format date-time */
  date: string;
  /** @format  */
  comment: string;
  /** @format  */
  employeeId: number;
  /** @format  */
  employeeName: string;
}

export const PropsSQLDBTKGetTCSummaryResponseHistory = {
  date: "date",
  comment: "comment",
  employeeId: "employeeId",
  employeeName: "employeeName",
} as const;

/** SQL_DB_TK_GetTCSummary_Response_DefaultWOs */
export interface SQL_DB_TK_GetTCSummary_Response_DefaultWOs {
  /** @format  */
  defaultWOId: number;
  /** @format  */
  defaultWOName: string;
  /** @format  */
  percentage: number;
}

export const PropsSQLDBTKGetTCSummaryResponseDefaultWOs = {
  defaultWOId: "defaultWOId",
  defaultWOName: "defaultWOName",
  percentage: "percentage",
} as const;

/** SQL_DB_TK_GetTCSummary_Response_Details */
export interface SQL_DB_TK_GetTCSummary_Response_Details {
  /** @format int64 */
  rowNum: number;
  /** @format  */
  tcId: number;
  /** @format date-time */
  date: string;
  /** @format  */
  employeeId: number;
  /** @format  */
  employeeName: string;
  /** @format  */
  classId: number | null;
  /** @format  */
  className: string | null;
  /** @format  */
  departmentId: number | null;
  /** @format  */
  departmentName: string | null;
  /** @format  */
  categoryId: number;
  /** @format  */
  categoryName: string;
  /** @format date-time */
  start: string | null;
  /** @format date-time */
  finish: string | null;
  /** @format  */
  costTypeName: string | null;
  /** @format  */
  woId: number | null;
  /** @format  */
  woName: string | null;
  /** @format  */
  stateId: number | null;
  /** @format  */
  stateName: string | null;
  /** @format double */
  regularHours: number | null;
  /** @format double */
  overtimeHours: number | null;
  /** @format double */
  doubleHours: number | null;
  /** @format double */
  totalHours: number | null;
  /** @format boolean */
  isPW: boolean | null;
  /** @format  */
  overnight: string | null;
  /** @format boolean */
  isLockedWageType: boolean | null;
  /** @format boolean */
  isOTExclude: boolean | null;
  /** @format double */
  lunchHours: number | null;
}

export const PropsSQLDBTKGetTCSummaryResponseDetails = {
  rowNum: "rowNum",
  tcId: "tcId",
  date: "date",
  employeeId: "employeeId",
  employeeName: "employeeName",
  classId: "classId",
  className: "className",
  departmentId: "departmentId",
  departmentName: "departmentName",
  categoryId: "categoryId",
  categoryName: "categoryName",
  start: "start",
  finish: "finish",
  costTypeName: "costTypeName",
  woId: "woId",
  woName: "woName",
  stateId: "stateId",
  stateName: "stateName",
  regularHours: "regularHours",
  overtimeHours: "overtimeHours",
  doubleHours: "doubleHours",
  totalHours: "totalHours",
  isPW: "isPW",
  overnight: "overnight",
  isLockedWageType: "isLockedWageType",
  isOTExclude: "isOTExclude",
  lunchHours: "lunchHours",
} as const;

/** SQL_DB_TK_GetTCSummary_Response */
export interface SQL_DB_TK_GetTCSummary_Response {
  timeCardInfo: SQL_DB_TK_GetTCSummary_Response_TimeCardInfo[];
  nonWageSummary: SQL_DB_TK_GetTCSummary_Response_NonWageSummary[];
  history: SQL_DB_TK_GetTCSummary_Response_History[];
  defaultWOs: SQL_DB_TK_GetTCSummary_Response_DefaultWOs[];
  details: SQL_DB_TK_GetTCSummary_Response_Details[];
}

export const PropsSQLDBTKGetTCSummaryResponse = {
  timeCardInfo: "timeCardInfo",
  nonWageSummary: "nonWageSummary",
  history: "history",
  defaultWOs: "defaultWOs",
  details: "details",
} as const;

/** SQL_DB_TK_GetTCSummary_Request */
export interface SQL_DB_TK_GetTCSummary_Request {
  /** @format  */
  TCId?: number | null;
}

export const PropsSQLDBTKGetTCSummaryRequest = {
  TCId: "TCId",
} as const;

/** SQL_DB_TK_GetTimeCardAudits_Response */
export interface SQL_DB_TK_GetTimeCardAudits_Response {
  /** @format int64 */
  rowNum: number;
  /** @format  */
  auditRuleId: number;
  /** @format  */
  auditRuleName: string;
  /** @format boolean */
  isImportant: boolean;
  /** @format  */
  resultText: string | null;
  /** @format double */
  adjustment: number;
}

export const PropsSQLDBTKGetTimeCardAuditsResponse = {
  rowNum: "rowNum",
  auditRuleId: "auditRuleId",
  auditRuleName: "auditRuleName",
  isImportant: "isImportant",
  resultText: "resultText",
  adjustment: "adjustment",
} as const;

/** SQL_DB_TK_GetTimeCardAudits_Request */
export interface SQL_DB_TK_GetTimeCardAudits_Request {
  /** @format  */
  tcId?: number | null;
}

export const PropsSQLDBTKGetTimeCardAuditsRequest = {
  tcId: "tcId",
} as const;

/** SQL_DB_TK_Review_Response_TETimeLine */
export interface SQL_DB_TK_Review_Response_TETimeLine {
  /** @format  */
  tcId: number;
  /** @format  */
  id: number;
  /** @format  */
  costType: string | null;
  /** @format  */
  costTypeCode: string | null;
  /** @format  */
  costTypeName: string | null;
  /** @format  */
  costTypeColor: string | null;
  /** @format  */
  site: string | null;
  /** @format  */
  wo: string | null;
  /** @format boolean */
  hasAllocation: boolean;
  /** @format  */
  location: string | null;
  /** @format  */
  event: string;
  /** @format date-time */
  start: string;
  /** @format date-time */
  finish: string;
  /** @format double */
  hours: number;
  /** @format double */
  approvedHours: number;
  /** @format  */
  startMinutes: number;
  /** @format  */
  finishMinutes: number;
  /** @format  */
  comments: string | null;
  /** @format boolean */
  isKnownLocation: boolean;
}

export const PropsSQLDBTKReviewResponseTETimeLine = {
  tcId: "tcId",
  id: "id",
  costType: "costType",
  costTypeCode: "costTypeCode",
  costTypeName: "costTypeName",
  costTypeColor: "costTypeColor",
  site: "site",
  wo: "wo",
  hasAllocation: "hasAllocation",
  location: "location",
  event: "event",
  start: "start",
  finish: "finish",
  hours: "hours",
  approvedHours: "approvedHours",
  startMinutes: "startMinutes",
  finishMinutes: "finishMinutes",
  comments: "comments",
  isKnownLocation: "isKnownLocation",
} as const;

/** SQL_DB_TK_Review_Response_AdjustedTimeLine */
export interface SQL_DB_TK_Review_Response_AdjustedTimeLine {
  /** @format  */
  tcId: number;
  /** @format  */
  id: number;
  /** @format  */
  costType: string | null;
  /** @format  */
  costTypeCode: string | null;
  /** @format  */
  costTypeName: string | null;
  /** @format  */
  costTypeColor: string | null;
  /** @format date-time */
  start: string;
  /** @format date-time */
  finish: string;
  /** @format double */
  hours: number;
  /** @format double */
  approvedHours: number;
  /** @format  */
  startMinutes: number;
  /** @format  */
  finishMinutes: number;
}

export const PropsSQLDBTKReviewResponseAdjustedTimeLine = {
  tcId: "tcId",
  id: "id",
  costType: "costType",
  costTypeCode: "costTypeCode",
  costTypeName: "costTypeName",
  costTypeColor: "costTypeColor",
  start: "start",
  finish: "finish",
  hours: "hours",
  approvedHours: "approvedHours",
  startMinutes: "startMinutes",
  finishMinutes: "finishMinutes",
} as const;

/** SQL_DB_TK_Review_Response_DispatchInfo */
export interface SQL_DB_TK_Review_Response_DispatchInfo {
  /** @format  */
  tcId: number;
  /** @format  */
  id: number | null;
  /** @format  */
  woId: number | null;
  /** @format  */
  name: string;
  /** @format date-time */
  clockInAfter: string | null;
  /** @format date-time */
  clockOutBefore: string | null;
  /** @format  */
  clockInAfterMinutes: number | null;
  /** @format  */
  clockOutBeforeMinutes: number | null;
}

export const PropsSQLDBTKReviewResponseDispatchInfo = {
  tcId: "tcId",
  id: "id",
  woId: "woId",
  name: "name",
  clockInAfter: "clockInAfter",
  clockOutBefore: "clockOutBefore",
  clockInAfterMinutes: "clockInAfterMinutes",
  clockOutBeforeMinutes: "clockOutBeforeMinutes",
} as const;

/** SQL_DB_TK_Review_Response */
export interface SQL_DB_TK_Review_Response {
  /** @format  */
  id: number;
  /** @format date-time */
  date: string;
  /** @format  */
  periodId: number;
  /** @format  */
  reviewState: string;
  /** @format  */
  reviewerID: number | null;
  /** @format  */
  reviewerName: string | null;
  /** @format  */
  employeeID: number;
  /** @format  */
  employeeName: string;
  /** @format  */
  departmentId: number | null;
  /** @format  */
  employeeClassId: number | null;
  /** @format  */
  reasonId: number | null;
  /** @format  */
  reasonName: string | null;
  /** @format boolean */
  isPermitted: boolean;
  /** @format boolean */
  isRelated: boolean;
  /** @format boolean */
  canReprocess: boolean;
  /** @format boolean */
  hasPW: boolean;
  /** @format boolean */
  isCrewLead: boolean;
  /** @format  */
  allocationStatus: string | null;
  /** @format  */
  warnings: string | null;
  /** @format  */
  activeRequests: number;
  /** @format  */
  importantAuditResults: number;
  /** @format date-time */
  firstClockIn: string | null;
  /** @format date-time */
  lastClockOut: string | null;
  /** @format double */
  clockedHours: number | null;
  /** @format double */
  approvedHours: number | null;
  /** @format double */
  lunchHours: number | null;
  /** @format double */
  manualLunch: number | null;
  /** @format double */
  auditedHours: number;
  /** @format double */
  totalHours: number;
  /** @format  */
  commentCount: number | null;
  /** @format  */
  notConfirmedAttestationCount: number | null;
  /** @format boolean */
  canReturn: boolean;
  /** @format boolean */
  canComplete: boolean;
  teTimeLine: SQL_DB_TK_Review_Response_TETimeLine[];
  adjustedTimeLine: SQL_DB_TK_Review_Response_AdjustedTimeLine[];
  dispatchInfo: SQL_DB_TK_Review_Response_DispatchInfo[];
}

export const PropsSQLDBTKReviewResponse = {
  id: "id",
  date: "date",
  periodId: "periodId",
  reviewState: "reviewState",
  reviewerID: "reviewerID",
  reviewerName: "reviewerName",
  employeeID: "employeeID",
  employeeName: "employeeName",
  departmentId: "departmentId",
  employeeClassId: "employeeClassId",
  reasonId: "reasonId",
  reasonName: "reasonName",
  isPermitted: "isPermitted",
  isRelated: "isRelated",
  canReprocess: "canReprocess",
  hasPW: "hasPW",
  isCrewLead: "isCrewLead",
  allocationStatus: "allocationStatus",
  warnings: "warnings",
  activeRequests: "activeRequests",
  importantAuditResults: "importantAuditResults",
  firstClockIn: "firstClockIn",
  lastClockOut: "lastClockOut",
  clockedHours: "clockedHours",
  approvedHours: "approvedHours",
  lunchHours: "lunchHours",
  manualLunch: "manualLunch",
  auditedHours: "auditedHours",
  totalHours: "totalHours",
  commentCount: "commentCount",
  notConfirmedAttestationCount: "notConfirmedAttestationCount",
  canReturn: "canReturn",
  canComplete: "canComplete",
  teTimeLine: "teTimeLine",
  adjustedTimeLine: "adjustedTimeLine",
  dispatchInfo: "dispatchInfo",
} as const;

/** SQL_DB_TK_Review_Request */
export interface SQL_DB_TK_Review_Request {
  /** @format  */
  tcId?: number | null;
  /** @format date-time */
  date?: string | null;
  /** @format date-time */
  finishDate?: string | null;
  /** @format  */
  dispatchId?: number | null;
  /** @format  */
  reviewerId?: number | null;
  /** @format  */
  resourceId?: number | null;
  /** @format  */
  periodId?: number | null;
  /** @format  */
  reviewStateId?: string | null;
  /** @format boolean */
  hasActiveRequest?: boolean | null;
  /** @format boolean */
  hasReason?: boolean | null;
  /** @format boolean */
  hasClosureAuditResults?: boolean | null;
  /** @format boolean */
  ascendingOrder?: boolean | null;
  /** @format boolean */
  notCompleted?: boolean | null;
  /** @format  */
  requestType?: string | null;
  /** @format boolean */
  hasMissingCostType?: boolean | null;
  /** @format boolean */
  hasMissingWO?: boolean | null;
  /** @format boolean */
  hasMealPRemium?: boolean | null;
  /** @format boolean */
  hasAdjustedTime?: boolean | null;
  /** @format  */
  departmentId?: number | null;
  /** @format  */
  employeeClassId?: number | null;
}

export const PropsSQLDBTKReviewRequest = {
  tcId: "tcId",
  date: "date",
  finishDate: "finishDate",
  dispatchId: "dispatchId",
  reviewerId: "reviewerId",
  resourceId: "resourceId",
  periodId: "periodId",
  reviewStateId: "reviewStateId",
  hasActiveRequest: "hasActiveRequest",
  hasReason: "hasReason",
  hasClosureAuditResults: "hasClosureAuditResults",
  ascendingOrder: "ascendingOrder",
  notCompleted: "notCompleted",
  requestType: "requestType",
  hasMissingCostType: "hasMissingCostType",
  hasMissingWO: "hasMissingWO",
  hasMealPRemium: "hasMealPRemium",
  hasAdjustedTime: "hasAdjustedTime",
  departmentId: "departmentId",
  employeeClassId: "employeeClassId",
} as const;

/** SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation */
export interface SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation {
  /** @format int64 */
  rowNum: number;
  /** @format  */
  tcId: number;
  /** @format date-time */
  date: string;
  /** @format  */
  employeeId: number;
  /** @format  */
  employeeName: string;
  /** @format  */
  classId: number | null;
  /** @format  */
  className: string | null;
  /** @format  */
  departmentId: number | null;
  /** @format  */
  departmentName: string | null;
  /** @format  */
  categoryId: number;
  /** @format  */
  categoryName: string;
  /** @format date-time */
  start: string | null;
  /** @format date-time */
  finish: string | null;
  /** @format  */
  costTypeName: string | null;
  /** @format  */
  woId: number | null;
  /** @format  */
  woName: string | null;
  /** @format  */
  stateId: number | null;
  /** @format  */
  stateName: string | null;
  /** @format double */
  regularHours: number | null;
  /** @format double */
  overtimeHours: number | null;
  /** @format double */
  doubleHours: number | null;
  /** @format double */
  totalHours: number | null;
  /** @format boolean */
  isPW: boolean | null;
  /** @format  */
  overnight: string | null;
  /** @format boolean */
  isLockedWageType: boolean | null;
  /** @format boolean */
  isOTExclude: boolean | null;
  /** @format double */
  lunchHours: number | null;
}

export const PropsSQLDBTKTimeCardsForCompareResponseTCAllocation = {
  rowNum: "rowNum",
  tcId: "tcId",
  date: "date",
  employeeId: "employeeId",
  employeeName: "employeeName",
  classId: "classId",
  className: "className",
  departmentId: "departmentId",
  departmentName: "departmentName",
  categoryId: "categoryId",
  categoryName: "categoryName",
  start: "start",
  finish: "finish",
  costTypeName: "costTypeName",
  woId: "woId",
  woName: "woName",
  stateId: "stateId",
  stateName: "stateName",
  regularHours: "regularHours",
  overtimeHours: "overtimeHours",
  doubleHours: "doubleHours",
  totalHours: "totalHours",
  isPW: "isPW",
  overnight: "overnight",
  isLockedWageType: "isLockedWageType",
  isOTExclude: "isOTExclude",
  lunchHours: "lunchHours",
} as const;

/** SQL_DB_TK_TimeCardsForCompare_Response_TETimeLine */
export interface SQL_DB_TK_TimeCardsForCompare_Response_TETimeLine {
  /** @format  */
  tcId: number;
  /** @format  */
  id: number;
  /** @format  */
  costType: string | null;
  /** @format  */
  costTypeCode: string | null;
  /** @format  */
  costTypeName: string | null;
  /** @format  */
  costTypeColor: string | null;
  /** @format  */
  site: string | null;
  /** @format  */
  wo: string | null;
  /** @format boolean */
  hasAllocation: boolean;
  /** @format  */
  location: string | null;
  /** @format  */
  event: string;
  /** @format date-time */
  start: string;
  /** @format date-time */
  finish: string;
  /** @format double */
  hours: number;
  /** @format double */
  approvedHours: number;
  /** @format  */
  startMinutes: number;
  /** @format  */
  finishMinutes: number;
  /** @format  */
  comments: string | null;
  /** @format boolean */
  isKnownLocation: boolean;
}

export const PropsSQLDBTKTimeCardsForCompareResponseTETimeLine = {
  tcId: "tcId",
  id: "id",
  costType: "costType",
  costTypeCode: "costTypeCode",
  costTypeName: "costTypeName",
  costTypeColor: "costTypeColor",
  site: "site",
  wo: "wo",
  hasAllocation: "hasAllocation",
  location: "location",
  event: "event",
  start: "start",
  finish: "finish",
  hours: "hours",
  approvedHours: "approvedHours",
  startMinutes: "startMinutes",
  finishMinutes: "finishMinutes",
  comments: "comments",
  isKnownLocation: "isKnownLocation",
} as const;

/** SQL_DB_TK_TimeCardsForCompare_Response_AdjustedTimeLine */
export interface SQL_DB_TK_TimeCardsForCompare_Response_AdjustedTimeLine {
  /** @format  */
  tcId: number;
  /** @format  */
  id: number;
  /** @format  */
  costType: string | null;
  /** @format  */
  costTypeCode: string | null;
  /** @format  */
  costTypeName: string | null;
  /** @format  */
  costTypeColor: string | null;
  /** @format date-time */
  start: string;
  /** @format date-time */
  finish: string;
  /** @format double */
  hours: number;
  /** @format double */
  approvedHours: number;
  /** @format  */
  startMinutes: number;
  /** @format  */
  finishMinutes: number;
}

export const PropsSQLDBTKTimeCardsForCompareResponseAdjustedTimeLine = {
  tcId: "tcId",
  id: "id",
  costType: "costType",
  costTypeCode: "costTypeCode",
  costTypeName: "costTypeName",
  costTypeColor: "costTypeColor",
  start: "start",
  finish: "finish",
  hours: "hours",
  approvedHours: "approvedHours",
  startMinutes: "startMinutes",
  finishMinutes: "finishMinutes",
} as const;

/** SQL_DB_TK_TimeCardsForCompare_Response_DispatchInfo */
export interface SQL_DB_TK_TimeCardsForCompare_Response_DispatchInfo {
  /** @format  */
  tcId: number;
  /** @format  */
  id: number;
  /** @format  */
  woId: number;
  /** @format  */
  name: string;
  /** @format date-time */
  clockInAfter: string | null;
  /** @format date-time */
  clockOutBefore: string | null;
  /** @format  */
  clockInAfterMinutes: number | null;
  /** @format  */
  clockOutBeforeMinutes: number | null;
}

export const PropsSQLDBTKTimeCardsForCompareResponseDispatchInfo = {
  tcId: "tcId",
  id: "id",
  woId: "woId",
  name: "name",
  clockInAfter: "clockInAfter",
  clockOutBefore: "clockOutBefore",
  clockInAfterMinutes: "clockInAfterMinutes",
  clockOutBeforeMinutes: "clockOutBeforeMinutes",
} as const;

/** SQL_DB_TK_TimeCardsForCompare_Response */
export interface SQL_DB_TK_TimeCardsForCompare_Response {
  /** @format  */
  tcId: number;
  /** @format  */
  employeeId: number;
  /** @format  */
  employeeName: string;
  /** @format date-time */
  date: string;
  tcAllocation: SQL_DB_TK_TimeCardsForCompare_Response_TCAllocation[];
  teTimeLine: SQL_DB_TK_TimeCardsForCompare_Response_TETimeLine[];
  adjustedTimeLine: SQL_DB_TK_TimeCardsForCompare_Response_AdjustedTimeLine[];
  dispatchInfo: SQL_DB_TK_TimeCardsForCompare_Response_DispatchInfo[];
}

export const PropsSQLDBTKTimeCardsForCompareResponse = {
  tcId: "tcId",
  employeeId: "employeeId",
  employeeName: "employeeName",
  date: "date",
  tcAllocation: "tcAllocation",
  teTimeLine: "teTimeLine",
  adjustedTimeLine: "adjustedTimeLine",
  dispatchInfo: "dispatchInfo",
} as const;

/** SQL_DB_TK_TimeCardsForCompare_Request */
export interface SQL_DB_TK_TimeCardsForCompare_Request {
  /** @format  */
  tcIds?: string | null;
}

export const PropsSQLDBTKTimeCardsForCompareRequest = {
  tcIds: "tcIds",
} as const;

/** SQL_GetInfo_TKTimeCards_Response */
export interface SQL_GetInfo_TKTimeCards_Response {
  /** @format boolean */
  canReprocess: boolean;
  /** @format boolean */
  canReturn: boolean;
  /** @format boolean */
  canComplete: boolean;
  /** @format  */
  importantAuditResults: number;
  /** @format  */
  activeRequestsCount: number;
  /** @format  */
  notConfirmedAttestationCount: number;
}

export const PropsSQLGetInfoTKTimeCardsResponse = {
  canReprocess: "canReprocess",
  canReturn: "canReturn",
  canComplete: "canComplete",
  importantAuditResults: "importantAuditResults",
  activeRequestsCount: "activeRequestsCount",
  notConfirmedAttestationCount: "notConfirmedAttestationCount",
} as const;

/** SQL_GetInfo_TKTimeCards_Request */
export interface SQL_GetInfo_TKTimeCards_Request {
  /** @format  */
  tcId?: number | null;
}

export const PropsSQLGetInfoTKTimeCardsRequest = {
  tcId: "tcId",
} as const;

/** SQL_Map_GetAllAddresses_Response */
export interface SQL_Map_GetAllAddresses_Response {
  /** @format  */
  addressId: number;
  /** @format  */
  address: string;
  /** @format double */
  lat: number;
  /** @format double */
  lng: number;
  /** @format  */
  objectId: number;
  /** @format  */
  objectName: string;
  /** @format  */
  objectType: string;
  /** @format  */
  color: string | null;
  /** @format  */
  geoFenceRadius: number;
  /** @format  */
  boundaries: string | null;
}

export const PropsSQLMapGetAllAddressesResponse = {
  addressId: "addressId",
  address: "address",
  lat: "lat",
  lng: "lng",
  objectId: "objectId",
  objectName: "objectName",
  objectType: "objectType",
  color: "color",
  geoFenceRadius: "geoFenceRadius",
  boundaries: "boundaries",
} as const;

/** SQL_Map_GetAllAddresses_Request */
export interface SQL_Map_GetAllAddresses_Request {
  /** @format double */
  clientRectTopLat?: number | null;
  /** @format double */
  clientRectBottomLat?: number | null;
  /** @format double */
  clientRectLeftLng?: number | null;
  /** @format double */
  clientRectRightLng?: number | null;
  /** @format  */
  objectType?: string | null;
}

export const PropsSQLMapGetAllAddressesRequest = {
  clientRectTopLat: "clientRectTopLat",
  clientRectBottomLat: "clientRectBottomLat",
  clientRectLeftLng: "clientRectLeftLng",
  clientRectRightLng: "clientRectRightLng",
  objectType: "objectType",
} as const;

/** SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders */
export interface SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders {
  /** @format boolean */
  isImportant: boolean;
  /** @format  */
  lastCrewLeadId: number | null;
  /** @format  */
  lastCrewLeadName: string | null;
  /** @format  */
  woName: string;
  /** @format  */
  woId: number;
  /** @format  */
  woTypeName: string;
  /** @format  */
  woTypeId: number;
  /** @format  */
  woCategoryName: string | null;
  /** @format  */
  woCategoryId: number | null;
}

export const PropsSQLMapGetIncompleteWOSitesResponseBuildPlansWorkOrders = {
  isImportant: "isImportant",
  lastCrewLeadId: "lastCrewLeadId",
  lastCrewLeadName: "lastCrewLeadName",
  woName: "woName",
  woId: "woId",
  woTypeName: "woTypeName",
  woTypeId: "woTypeId",
  woCategoryName: "woCategoryName",
  woCategoryId: "woCategoryId",
} as const;

/** SQL_Map_GetIncompleteWOSites_Response_BuildPlans */
export interface SQL_Map_GetIncompleteWOSites_Response_BuildPlans {
  /** @format  */
  projectName: string;
  /** @format  */
  scenarioId: number;
  /** @format  */
  scenarioName: string;
  /** @format  */
  customerId: number | null;
  /** @format  */
  customerName: string | null;
  /** @format  */
  classId: number | null;
  /** @format  */
  className: string | null;
  /** @format  */
  carrierId: number | null;
  /** @format  */
  carrierName: string | null;
  /** @format  */
  bpId: number;
  /** @format  */
  bpName: string;
  /** @format  */
  bpoId: number;
  /** @format  */
  bpoName: string;
  /** @format  */
  constructionStatusId: number | null;
  /** @format  */
  constructionStatusName: string | null;
  /** @format  */
  cxStatusComment: string | null;
  /** @format  */
  permitStatusId: number | null;
  /** @format  */
  permitStatusName: string | null;
  /** @format  */
  permitStatusComment: string | null;
  workOrders: SQL_Map_GetIncompleteWOSites_Response_BuildPlans_WorkOrders[];
}

export const PropsSQLMapGetIncompleteWOSitesResponseBuildPlans = {
  projectName: "projectName",
  scenarioId: "scenarioId",
  scenarioName: "scenarioName",
  customerId: "customerId",
  customerName: "customerName",
  classId: "classId",
  className: "className",
  carrierId: "carrierId",
  carrierName: "carrierName",
  bpId: "bpId",
  bpName: "bpName",
  bpoId: "bpoId",
  bpoName: "bpoName",
  constructionStatusId: "constructionStatusId",
  constructionStatusName: "constructionStatusName",
  cxStatusComment: "cxStatusComment",
  permitStatusId: "permitStatusId",
  permitStatusName: "permitStatusName",
  permitStatusComment: "permitStatusComment",
  workOrders: "workOrders",
} as const;

/** SQL_Map_GetIncompleteWOSites_Response */
export interface SQL_Map_GetIncompleteWOSites_Response {
  /** @format  */
  siteId: number;
  /** @format  */
  siteName: string;
  /** @format  */
  addressId: number;
  /** @format  */
  address: string;
  /** @format  */
  geoFenceRadius: number;
  /** @format  */
  boundaries: string | null;
  /** @format double */
  lat: number;
  /** @format double */
  lng: number;
  buildPlans: SQL_Map_GetIncompleteWOSites_Response_BuildPlans[];
}

export const PropsSQLMapGetIncompleteWOSitesResponse = {
  siteId: "siteId",
  siteName: "siteName",
  addressId: "addressId",
  address: "address",
  geoFenceRadius: "geoFenceRadius",
  boundaries: "boundaries",
  lat: "lat",
  lng: "lng",
  buildPlans: "buildPlans",
} as const;

/** SQL_Map_GetIncompleteWOSites_Request */
export interface SQL_Map_GetIncompleteWOSites_Request {
  /** @format double */
  clientRectTopLat?: number | null;
  /** @format double */
  clientRectBottomLat?: number | null;
  /** @format double */
  clientRectLeftLng?: number | null;
  /** @format double */
  clientRectRightLng?: number | null;
}

export const PropsSQLMapGetIncompleteWOSitesRequest = {
  clientRectTopLat: "clientRectTopLat",
  clientRectBottomLat: "clientRectBottomLat",
  clientRectLeftLng: "clientRectLeftLng",
  clientRectRightLng: "clientRectRightLng",
} as const;

/** SQL_Profile_MyCertificates_Response */
export interface SQL_Profile_MyCertificates_Response {
  /** @format  */
  id: number;
  /** @format  */
  number: string | null;
  /** @format  */
  typeName: string;
  /** @format date-time */
  datePassed: string | null;
  /** @format date-time */
  expired: string | null;
  /** @format  */
  issuedStateName: string | null;
  /** @format  */
  documentId: number | null;
  /** @format  */
  documentExtension: string | null;
  /** @format date-time */
  documentLastUpdate: string | null;
}

export const PropsSQLProfileMyCertificatesResponse = {
  id: "id",
  number: "number",
  typeName: "typeName",
  datePassed: "datePassed",
  expired: "expired",
  issuedStateName: "issuedStateName",
  documentId: "documentId",
  documentExtension: "documentExtension",
  documentLastUpdate: "documentLastUpdate",
} as const;

/** SQL_Profile_MyInfo_Response */
export interface SQL_Profile_MyInfo_Response {
  /** @format  */
  name: string;
  /** @format  */
  email: string | null;
  /** @format  */
  phone: string | null;
  /** @format  */
  login: string;
}

export const PropsSQLProfileMyInfoResponse = {
  name: "name",
  email: "email",
  phone: "phone",
  login: "login",
} as const;

/** SQL_Profile_MyRoles_Response */
export interface SQL_Profile_MyRoles_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format  */
  description: string | null;
}

export const PropsSQLProfileMyRolesResponse = {
  id: "id",
  name: "name",
  description: "description",
} as const;

/** SQL_Settings_Response */
export interface SQL_Settings_Response {
  /** @format  */
  name: string;
  /** @format  */
  value: string | null;
  /** @format boolean */
  isMandatory: boolean;
}

export const PropsSQLSettingsResponse = {
  name: "name",
  value: "value",
  isMandatory: "isMandatory",
} as const;

/** SQL_TK_Attestations_Response */
export interface SQL_TK_Attestations_Response {
  /** @format  */
  attestationId: number;
  /** @format  */
  type: string;
  /** @format  */
  text: string;
  /** @format boolean */
  allowDecline: boolean;
  /** @format boolean */
  alwaysShowComment: boolean;
  /** @format  */
  acceptTitle: string;
  /** @format  */
  declineTitle: string;
}

export const PropsSQLTKAttestationsResponse = {
  attestationId: "attestationId",
  type: "type",
  text: "text",
  allowDecline: "allowDecline",
  alwaysShowComment: "alwaysShowComment",
  acceptTitle: "acceptTitle",
  declineTitle: "declineTitle",
} as const;

/** SQL_TK_CheckInStatus_Response_CheckInInfo */
export interface SQL_TK_CheckInStatus_Response_CheckInInfo {
  /** @format date-time */
  startServer: string;
  /** @format double */
  duration: number;
  /** @format  */
  id: number;
}

export const PropsSQLTKCheckInStatusResponseCheckInInfo = {
  startServer: "startServer",
  duration: "duration",
  id: "id",
} as const;

/** SQL_TK_CheckInStatus_Response */
export interface SQL_TK_CheckInStatus_Response {
  /** @format  */
  disallowedReason: string | null;
  checkInInfo?: SQL_TK_CheckInStatus_Response_CheckInInfo;
}

export const PropsSQLTKCheckInStatusResponse = {
  disallowedReason: "disallowedReason",
  checkInInfo: "checkInInfo",
} as const;

/** SQL_TK_ClockInStatus_Response_WorkshiftInfo_Lunches */
export interface SQL_TK_ClockInStatus_Response_WorkshiftInfo_Lunches {
  /** @format  */
  id: number;
  /** @format date-time */
  startServer: string;
  /** @format date-time */
  finishServer: string;
  /** @format boolean */
  confirmedLunch: boolean;
}

export const PropsSQLTKClockInStatusResponseWorkshiftInfoLunches = {
  id: "id",
  startServer: "startServer",
  finishServer: "finishServer",
  confirmedLunch: "confirmedLunch",
} as const;

/** SQL_TK_ClockInStatus_Response_WorkshiftInfo_ActiveManual */
export interface SQL_TK_ClockInStatus_Response_WorkshiftInfo_ActiveManual {
  /** @format  */
  id: number;
  /** @format date-time */
  startServer: string;
  /** @format  */
  workOrderName: string;
}

export const PropsSQLTKClockInStatusResponseWorkshiftInfoActiveManual = {
  id: "id",
  startServer: "startServer",
  workOrderName: "workOrderName",
} as const;

/** SQL_TK_ClockInStatus_Response_WorkshiftInfo_LunchSchedule */
export interface SQL_TK_ClockInStatus_Response_WorkshiftInfo_LunchSchedule {
  /** @format date-time */
  serverTime: string;
  /** @format  */
  text: string;
  /** @format  */
  isCompleted: number;
}

export const PropsSQLTKClockInStatusResponseWorkshiftInfoLunchSchedule = {
  serverTime: "serverTime",
  text: "text",
  isCompleted: "isCompleted",
} as const;

/** SQL_TK_ClockInStatus_Response_WorkshiftInfo_LunchOptions */
export interface SQL_TK_ClockInStatus_Response_WorkshiftInfo_LunchOptions {
  /** @format  */
  id: number;
  /** @format boolean */
  isEarlyFinishAllowed: boolean;
  /** @format boolean */
  isWaiveAllowed: boolean;
  /** @format  */
  duration: number | null;
  /** @format boolean */
  requireConfirmation: boolean;
}

export const PropsSQLTKClockInStatusResponseWorkshiftInfoLunchOptions = {
  id: "id",
  isEarlyFinishAllowed: "isEarlyFinishAllowed",
  isWaiveAllowed: "isWaiveAllowed",
  duration: "duration",
  requireConfirmation: "requireConfirmation",
} as const;

/** SQL_TK_ClockInStatus_Response_WorkshiftInfo */
export interface SQL_TK_ClockInStatus_Response_WorkshiftInfo {
  /** @format  */
  id: number;
  /** @format  */
  deviceTimeDiviationInSeconds: number;
  /** @format date-time */
  startServer: string;
  /** @format date-time */
  clockOutBeforeServer: string;
  lunches: SQL_TK_ClockInStatus_Response_WorkshiftInfo_Lunches[];
  activeManual?: SQL_TK_ClockInStatus_Response_WorkshiftInfo_ActiveManual;
  lunchSchedule: SQL_TK_ClockInStatus_Response_WorkshiftInfo_LunchSchedule[];
  lunchOptions?: SQL_TK_ClockInStatus_Response_WorkshiftInfo_LunchOptions;
}

export const PropsSQLTKClockInStatusResponseWorkshiftInfo = {
  id: "id",
  deviceTimeDiviationInSeconds: "deviceTimeDiviationInSeconds",
  startServer: "startServer",
  clockOutBeforeServer: "clockOutBeforeServer",
  lunches: "lunches",
  activeManual: "activeManual",
  lunchSchedule: "lunchSchedule",
  lunchOptions: "lunchOptions",
} as const;

/** SQL_TK_ClockInStatus_Response */
export interface SQL_TK_ClockInStatus_Response {
  /** @format  */
  disallowedReason: string | null;
  workshiftInfo?: SQL_TK_ClockInStatus_Response_WorkshiftInfo;
}

export const PropsSQLTKClockInStatusResponse = {
  disallowedReason: "disallowedReason",
  workshiftInfo: "workshiftInfo",
} as const;

/** SQL_TK_ClockInStatus_Request */
export interface SQL_TK_ClockInStatus_Request {
  /** @format date-time */
  deviceTime?: string | null;
}

export const PropsSQLTKClockInStatusRequest = {
  deviceTime: "deviceTime",
} as const;

/** SQL_TK_GetActivePayrollPeriods_Response */
export interface SQL_TK_GetActivePayrollPeriods_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLTKGetActivePayrollPeriodsResponse = {
  id: "id",
  name: "name",
} as const;

/** SQL_TK_GetActivePayrollPeriods_Request */
export interface SQL_TK_GetActivePayrollPeriods_Request {
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
}

export const PropsSQLTKGetActivePayrollPeriodsRequest = {
  startDate: "startDate",
  endDate: "endDate",
} as const;

/** SQL_TK_GetAvailableWOs_Response */
export interface SQL_TK_GetAvailableWOs_Response {
  /** @format  */
  name: string;
  /** @format  */
  id: number;
  /** @format  */
  code: string;
  /** @format  */
  number: string;
  /** @format  */
  defaultStateCode: string | null;
  /** @format  */
  groupName: string;
}

export const PropsSQLTKGetAvailableWOsResponse = {
  name: "name",
  id: "id",
  code: "code",
  number: "number",
  defaultStateCode: "defaultStateCode",
  groupName: "groupName",
} as const;

/** SQL_TK_GetAvailableWOs_Request */
export interface SQL_TK_GetAvailableWOs_Request {
  /** @format  */
  TCId?: number | null;
}

export const PropsSQLTKGetAvailableWOsRequest = {
  TCId: "TCId",
} as const;

/** SQL_TK_GetDataForAdjustment_Response_CostTypes */
export interface SQL_TK_GetDataForAdjustment_Response_CostTypes {
  /** @format  */
  name: string;
  /** @format  */
  id: number;
  /** @format  */
  code: string;
  /** @format  */
  color: string;
  /** @format boolean */
  isNonWork: boolean;
}

export const PropsSQLTKGetDataForAdjustmentResponseCostTypes = {
  name: "name",
  id: "id",
  code: "code",
  color: "color",
  isNonWork: "isNonWork",
} as const;

/** SQL_TK_GetDataForAdjustment_Response_States */
export interface SQL_TK_GetDataForAdjustment_Response_States {
  /** @format  */
  code: string;
}

export const PropsSQLTKGetDataForAdjustmentResponseStates = {
  code: "code",
} as const;

/** SQL_TK_GetDataForAdjustment_Response_NonWorkWOs */
export interface SQL_TK_GetDataForAdjustment_Response_NonWorkWOs {
  /** @format  */
  staticWOId: number;
  /** @format  */
  staticWOCode: string;
  /** @format  */
  staticWOName: string;
}

export const PropsSQLTKGetDataForAdjustmentResponseNonWorkWOs = {
  staticWOId: "staticWOId",
  staticWOCode: "staticWOCode",
  staticWOName: "staticWOName",
} as const;

/** SQL_TK_GetDataForAdjustment_Response */
export interface SQL_TK_GetDataForAdjustment_Response {
  costTypes: SQL_TK_GetDataForAdjustment_Response_CostTypes[];
  states: SQL_TK_GetDataForAdjustment_Response_States[];
  nonWorkWOs: SQL_TK_GetDataForAdjustment_Response_NonWorkWOs[];
}

export const PropsSQLTKGetDataForAdjustmentResponse = {
  costTypes: "costTypes",
  states: "states",
  nonWorkWOs: "nonWorkWOs",
} as const;

/** SQL_TK_GetDataForAdjustment_Request */
export interface SQL_TK_GetDataForAdjustment_Request {
  /** @format  */
  TCId?: number | null;
}

export const PropsSQLTKGetDataForAdjustmentRequest = {
  TCId: "TCId",
} as const;

/** SQL_TK_GetDispatches_Response */
export interface SQL_TK_GetDispatches_Response {
  /** @format  */
  id: number;
  /** @format date-time */
  date: string;
  /** @format  */
  workOrderName: string;
}

export const PropsSQLTKGetDispatchesResponse = {
  id: "id",
  date: "date",
  workOrderName: "workOrderName",
} as const;

/** SQL_TK_GetDispatches_Request */
export interface SQL_TK_GetDispatches_Request {
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  finishDate?: string | null;
  /** @format  */
  employeeId?: number | null;
}

export const PropsSQLTKGetDispatchesRequest = {
  startDate: "startDate",
  finishDate: "finishDate",
  employeeId: "employeeId",
} as const;

/** SQL_TK_GetRequestAllocation_Response */
export interface SQL_TK_GetRequestAllocation_Response {
  /** @format  */
  workOrderId: number;
  /** @format  */
  workOrderName: string;
  /** @format  */
  percentage: number;
  /** @format  */
  requestId: number;
}

export const PropsSQLTKGetRequestAllocationResponse = {
  workOrderId: "workOrderId",
  workOrderName: "workOrderName",
  percentage: "percentage",
  requestId: "requestId",
} as const;

/** SQL_TK_GetRequestAllocation_Request */
export interface SQL_TK_GetRequestAllocation_Request {
  /** @format  */
  tcId?: number | null;
}

export const PropsSQLTKGetRequestAllocationRequest = {
  tcId: "tcId",
} as const;

/** SQL_TK_GetRequestData_Response */
export interface SQL_TK_GetRequestData_Response {
  /** @format date-time */
  createdLocal: string;
  /** @format  */
  requestType: string;
  /** @format  */
  description: string | null;
  /** @format  */
  reasonId: number | null;
  /** @format  */
  reasonName: string | null;
  /** @format  */
  payrollItemId: number | null;
  /** @format  */
  payrollItemName: string | null;
  /** @format  */
  qty: number | null;
  /** @format  */
  workOrderId: number | null;
  /** @format  */
  workOrderName: string | null;
  /** @format date-time */
  reviewStarted: string | null;
  /** @format  */
  reviewStage: string;
  /** @format  */
  rejectionComment: string | null;
  /** @format  */
  solicitation: string | null;
  /** @format  */
  requestId: number;
  /** @format date-time */
  clockIn: string | null;
  /** @format date-time */
  clockOut: string | null;
  /** @format date-time */
  lunchStart: string | null;
  /** @format date-time */
  lunchFinish: string | null;
  /** @format date-time */
  timeCardDate: string;
  /** @format boolean */
  canReviewerReject: boolean;
  /** @format boolean */
  canAdminReject: boolean;
  /** @format boolean */
  canApprove: boolean;
  /** @format boolean */
  canApplyandApprove: boolean;
  /** @format boolean */
  canComplete: boolean;
  /** @format boolean */
  canApplyandComplete: boolean;
  /** @format boolean */
  canOverrideandComplete: boolean;
  /** @format boolean */
  canOverrideandApprove: boolean;
  /** @format boolean */
  canRevoke: boolean;
}

export const PropsSQLTKGetRequestDataResponse = {
  createdLocal: "createdLocal",
  requestType: "requestType",
  description: "description",
  reasonId: "reasonId",
  reasonName: "reasonName",
  payrollItemId: "payrollItemId",
  payrollItemName: "payrollItemName",
  qty: "qty",
  workOrderId: "workOrderId",
  workOrderName: "workOrderName",
  reviewStarted: "reviewStarted",
  reviewStage: "reviewStage",
  rejectionComment: "rejectionComment",
  solicitation: "solicitation",
  requestId: "requestId",
  clockIn: "clockIn",
  clockOut: "clockOut",
  lunchStart: "lunchStart",
  lunchFinish: "lunchFinish",
  timeCardDate: "timeCardDate",
  canReviewerReject: "canReviewerReject",
  canAdminReject: "canAdminReject",
  canApprove: "canApprove",
  canApplyandApprove: "canApplyandApprove",
  canComplete: "canComplete",
  canApplyandComplete: "canApplyandComplete",
  canOverrideandComplete: "canOverrideandComplete",
  canOverrideandApprove: "canOverrideandApprove",
  canRevoke: "canRevoke",
} as const;

/** SQL_TK_GetRequestData_Request */
export interface SQL_TK_GetRequestData_Request {
  /** @format  */
  tcId?: number | null;
}

export const PropsSQLTKGetRequestDataRequest = {
  tcId: "tcId",
} as const;

/** SQL_TK_GetResources_Response */
export interface SQL_TK_GetResources_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format  */
  departmentId: number | null;
  /** @format  */
  departmentName: string | null;
  /** @format  */
  employeeClassId: number | null;
  /** @format  */
  employeeClassName: string | null;
}

export const PropsSQLTKGetResourcesResponse = {
  id: "id",
  name: "name",
  departmentId: "departmentId",
  departmentName: "departmentName",
  employeeClassId: "employeeClassId",
  employeeClassName: "employeeClassName",
} as const;

/** SQL_TK_GetReviewers_Response */
export interface SQL_TK_GetReviewers_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLTKGetReviewersResponse = {
  id: "id",
  name: "name",
} as const;

/** SQL_TK_GetSiteData_Response_SiteData */
export interface SQL_TK_GetSiteData_Response_SiteData {
  /** @format  */
  siteId: string;
  /** @format  */
  objectId: number;
  /** @format  */
  siteName: string;
  /** @format  */
  carrierId: number | null;
  /** @format  */
  carrierName: string | null;
  /** @format  */
  regionId: number;
  /** @format  */
  regionName: string;
  /** @format  */
  marketId: number;
  /** @format  */
  marketName: string;
  /** @format  */
  addressId: number;
  /** @format  */
  directionsFromLocalOffice: string | null;
  /** @format  */
  specialAccessInstruction: string | null;
  /** @format  */
  notes: string | null;
  /** @format boolean */
  isCustomName: boolean;
  /** @format  */
  jurisdiction: string | null;
  /** @format  */
  responsibleName: string | null;
  /** @format  */
  siteTypeName: string | null;
  /** @format  */
  siteTypeId: number | null;
}

export const PropsSQLTKGetSiteDataResponseSiteData = {
  siteId: "siteId",
  objectId: "objectId",
  siteName: "siteName",
  carrierId: "carrierId",
  carrierName: "carrierName",
  regionId: "regionId",
  regionName: "regionName",
  marketId: "marketId",
  marketName: "marketName",
  addressId: "addressId",
  directionsFromLocalOffice: "directionsFromLocalOffice",
  specialAccessInstruction: "specialAccessInstruction",
  notes: "notes",
  isCustomName: "isCustomName",
  jurisdiction: "jurisdiction",
  responsibleName: "responsibleName",
  siteTypeName: "siteTypeName",
  siteTypeId: "siteTypeId",
} as const;

/** SQL_TK_GetSiteData_Response_SiteBuildPlans */
export interface SQL_TK_GetSiteData_Response_SiteBuildPlans {
  /** @format  */
  buildPlanId: number;
  /** @format  */
  buildPlanName: string;
}

export const PropsSQLTKGetSiteDataResponseSiteBuildPlans = {
  buildPlanId: "buildPlanId",
  buildPlanName: "buildPlanName",
} as const;

/** SQL_TK_GetSiteData_Response_RelatedSites */
export interface SQL_TK_GetSiteData_Response_RelatedSites {
  /** @format  */
  id: number;
  /** @format  */
  siteID: string;
}

export const PropsSQLTKGetSiteDataResponseRelatedSites = {
  id: "id",
  siteID: "siteID",
} as const;

/** SQL_TK_GetSiteData_Response */
export interface SQL_TK_GetSiteData_Response {
  siteData: SQL_TK_GetSiteData_Response_SiteData[];
  siteBuildPlans: SQL_TK_GetSiteData_Response_SiteBuildPlans[];
  relatedSites: SQL_TK_GetSiteData_Response_RelatedSites[];
}

export const PropsSQLTKGetSiteDataResponse = {
  siteData: "siteData",
  siteBuildPlans: "siteBuildPlans",
  relatedSites: "relatedSites",
} as const;

/** SQL_TK_GetSiteData_Request */
export interface SQL_TK_GetSiteData_Request {
  /** @format  */
  siteId?: number | null;
}

export const PropsSQLTKGetSiteDataRequest = {
  siteId: "siteId",
} as const;

/** SQL_TK_GetTCAttestations_Response */
export interface SQL_TK_GetTCAttestations_Response {
  /** @format int64 */
  rowNum: number;
  /** @format  */
  attestationId: number;
  /** @format  */
  type: string;
  /** @format  */
  title: string;
  /** @format boolean */
  confirmed: boolean;
  /** @format  */
  comment: string | null;
  /** @format  */
  text: string | null;
}

export const PropsSQLTKGetTCAttestationsResponse = {
  rowNum: "rowNum",
  attestationId: "attestationId",
  type: "type",
  title: "title",
  confirmed: "confirmed",
  comment: "comment",
  text: "text",
} as const;

/** SQL_TK_GetTCAttestations_Request */
export interface SQL_TK_GetTCAttestations_Request {
  /** @format  */
  tcId?: number | null;
}

export const PropsSQLTKGetTCAttestationsRequest = {
  tcId: "tcId",
} as const;

/** SQL_TK_MyCalendar_Response */
export interface SQL_TK_MyCalendar_Response {
  /** @format date-time */
  date: string;
  /** @format double */
  approvedHours: number;
  /** @format boolean */
  hasRequests: boolean;
  /** @format boolean */
  hasActiveRequest: boolean;
  /** @format boolean */
  hasReason: boolean;
  /** @format boolean */
  isCompleted: boolean;
  /** @format boolean */
  hasSolicitedRequest: boolean;
}

export const PropsSQLTKMyCalendarResponse = {
  date: "date",
  approvedHours: "approvedHours",
  hasRequests: "hasRequests",
  hasActiveRequest: "hasActiveRequest",
  hasReason: "hasReason",
  isCompleted: "isCompleted",
  hasSolicitedRequest: "hasSolicitedRequest",
} as const;

/** SQL_TK_MyHoursBalance_Response */
export interface SQL_TK_MyHoursBalance_Response {
  /** @format double */
  sickHourRequestedNotCompleted: number;
  /** @format double */
  sickHoursAvailable: number;
  /** @format double */
  vacationHoursRequestedNotCompleted: number;
  /** @format double */
  vacationHoursAvailable: number;
}

export const PropsSQLTKMyHoursBalanceResponse = {
  sickHourRequestedNotCompleted: "sickHourRequestedNotCompleted",
  sickHoursAvailable: "sickHoursAvailable",
  vacationHoursRequestedNotCompleted: "vacationHoursRequestedNotCompleted",
  vacationHoursAvailable: "vacationHoursAvailable",
} as const;

/** SQL_TK_MyPayrollPeriods_Response */
export interface SQL_TK_MyPayrollPeriods_Response {
  /** @format  */
  id: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  finishDate: string;
  /** @format date-time */
  paidDate: string | null;
  /** @format date-time */
  closedDate: string | null;
  /** @format date-time */
  receivedDate: string | null;
  /** @format  */
  stageName: string;
  /** @format double */
  approvedHours: number;
}

export const PropsSQLTKMyPayrollPeriodsResponse = {
  id: "id",
  startDate: "startDate",
  finishDate: "finishDate",
  paidDate: "paidDate",
  closedDate: "closedDate",
  receivedDate: "receivedDate",
  stageName: "stageName",
  approvedHours: "approvedHours",
} as const;

/** SQL_TK_MySolicitations_Response */
export interface SQL_TK_MySolicitations_Response {
  /** @format date-time */
  date: string;
}

export const PropsSQLTKMySolicitationsResponse = {
  date: "date",
} as const;

/** SQL_TK_MyTimeCard_Response_ActualAllocation */
export interface SQL_TK_MyTimeCard_Response_ActualAllocation {
  /** @format date-time */
  periodLocalStartDate: string;
  /** @format date-time */
  periodLocalFinishDate: string;
  /** @format  */
  workOrderName: string | null;
  /** @format  */
  costTypeName: string | null;
  /** @format double */
  hours: number;
}

export const PropsSQLTKMyTimeCardResponseActualAllocation = {
  periodLocalStartDate: "periodLocalStartDate",
  periodLocalFinishDate: "periodLocalFinishDate",
  workOrderName: "workOrderName",
  costTypeName: "costTypeName",
  hours: "hours",
} as const;

/** SQL_TK_MyTimeCard_Response_ApprovedAllocation */
export interface SQL_TK_MyTimeCard_Response_ApprovedAllocation {
  /** @format  */
  workOrderName: string;
  /** @format  */
  costTypeName: string;
  /** @format double */
  hours: number;
}

export const PropsSQLTKMyTimeCardResponseApprovedAllocation = {
  workOrderName: "workOrderName",
  costTypeName: "costTypeName",
  hours: "hours",
} as const;

/** SQL_TK_MyTimeCard_Response_NonAllocatedTime */
export interface SQL_TK_MyTimeCard_Response_NonAllocatedTime {
  /** @format  */
  id: number;
  /** @format date-time */
  localStartDate: string;
  /** @format date-time */
  localFinishDate: string;
  /** @format  */
  type: string;
}

export const PropsSQLTKMyTimeCardResponseNonAllocatedTime = {
  id: "id",
  localStartDate: "localStartDate",
  localFinishDate: "localFinishDate",
  type: "type",
} as const;

/** SQL_TK_MyTimeCard_Response_Requests_WorkOrderAllocation */
export interface SQL_TK_MyTimeCard_Response_Requests_WorkOrderAllocation {
  /** @format  */
  percentage: number;
  /** @format  */
  workOrderName: string;
}

export const PropsSQLTKMyTimeCardResponseRequestsWorkOrderAllocation = {
  percentage: "percentage",
  workOrderName: "workOrderName",
} as const;

/** SQL_TK_MyTimeCard_Response_Requests */
export interface SQL_TK_MyTimeCard_Response_Requests {
  /** @format  */
  id: number;
  /** @format date-time */
  created: string;
  /** @format  */
  typeName: string;
  /** @format  */
  stageName: string;
  /** @format date-time */
  clockIn: string | null;
  /** @format date-time */
  clockOut: string | null;
  /** @format date-time */
  lunchStart: string | null;
  /** @format date-time */
  lunchStop: string | null;
  /** @format  */
  solicitation: string | null;
  workOrderAllocation: SQL_TK_MyTimeCard_Response_Requests_WorkOrderAllocation[];
  /** @format  */
  workOrderName: string | null;
  /** @format  */
  payrollItemId: number | null;
  /** @format  */
  payrollItemName: string | null;
  /** @format  */
  reasonId: number | null;
  /** @format  */
  reasonName: string | null;
  /** @format  */
  description: string | null;
  /** @format  */
  rejectionComment: string | null;
  /** @format  */
  quantity: number | null;
  /** @format boolean */
  allowRevoke: boolean;
  /** @format  */
  typeCode: string;
}

export const PropsSQLTKMyTimeCardResponseRequests = {
  id: "id",
  created: "created",
  typeName: "typeName",
  stageName: "stageName",
  clockIn: "clockIn",
  clockOut: "clockOut",
  lunchStart: "lunchStart",
  lunchStop: "lunchStop",
  solicitation: "solicitation",
  workOrderAllocation: "workOrderAllocation",
  workOrderName: "workOrderName",
  payrollItemId: "payrollItemId",
  payrollItemName: "payrollItemName",
  reasonId: "reasonId",
  reasonName: "reasonName",
  description: "description",
  rejectionComment: "rejectionComment",
  quantity: "quantity",
  allowRevoke: "allowRevoke",
  typeCode: "typeCode",
} as const;

/** SQL_TK_MyTimeCard_Response_NonWageAllocation */
export interface SQL_TK_MyTimeCard_Response_NonWageAllocation {
  /** @format  */
  workOrderName: string;
  /** @format  */
  payrollItemName: string;
  /** @format  */
  quantity: number;
}

export const PropsSQLTKMyTimeCardResponseNonWageAllocation = {
  workOrderName: "workOrderName",
  payrollItemName: "payrollItemName",
  quantity: "quantity",
} as const;

/** SQL_TK_MyTimeCard_Response */
export interface SQL_TK_MyTimeCard_Response {
  /** @format date-time */
  date: string;
  /** @format  */
  reasonName: string | null;
  /** @format boolean */
  isCompleted: boolean;
  /** @format double */
  approvedHours: number;
  /** @format double */
  actualHours: number;
  actualAllocation: SQL_TK_MyTimeCard_Response_ActualAllocation[];
  approvedAllocation: SQL_TK_MyTimeCard_Response_ApprovedAllocation[];
  nonAllocatedTime: SQL_TK_MyTimeCard_Response_NonAllocatedTime[];
  requests: SQL_TK_MyTimeCard_Response_Requests[];
  nonWageAllocation: SQL_TK_MyTimeCard_Response_NonWageAllocation[];
}

export const PropsSQLTKMyTimeCardResponse = {
  date: "date",
  reasonName: "reasonName",
  isCompleted: "isCompleted",
  approvedHours: "approvedHours",
  actualHours: "actualHours",
  actualAllocation: "actualAllocation",
  approvedAllocation: "approvedAllocation",
  nonAllocatedTime: "nonAllocatedTime",
  requests: "requests",
  nonWageAllocation: "nonWageAllocation",
} as const;

/** SQL_TK_MyTimeCard_Request */
export interface SQL_TK_MyTimeCard_Request {
  /** @format date-time */
  date?: string | null;
}

export const PropsSQLTKMyTimeCardRequest = {
  date: "date",
} as const;

/** SQL_TK_RequestAvaliablePayrollItems_Response */
export interface SQL_TK_RequestAvaliablePayrollItems_Response {
  /** @format  */
  name: string;
  /** @format  */
  id: number;
  /** @format boolean */
  isNonWorkWorkOrderForRequests: boolean;
}

export const PropsSQLTKRequestAvaliablePayrollItemsResponse = {
  name: "name",
  id: "id",
  isNonWorkWorkOrderForRequests: "isNonWorkWorkOrderForRequests",
} as const;

/** SQL_TK_RequestAvaliableReasons_Response */
export interface SQL_TK_RequestAvaliableReasons_Response {
  /** @format  */
  name: string;
  /** @format  */
  id: number;
}

export const PropsSQLTKRequestAvaliableReasonsResponse = {
  name: "name",
  id: "id",
} as const;

/** SQL_TK_TimeCardAvailableWorkOrders_Response */
export interface SQL_TK_TimeCardAvailableWorkOrders_Response {
  /** @format  */
  name: string;
  /** @format  */
  id: number;
}

export const PropsSQLTKTimeCardAvailableWorkOrdersResponse = {
  name: "name",
  id: "id",
} as const;

/** SQL_TK_TimeCardAvailableWorkOrders_Request */
export interface SQL_TK_TimeCardAvailableWorkOrders_Request {
  /** @format date-time */
  date?: string | null;
}

export const PropsSQLTKTimeCardAvailableWorkOrdersRequest = {
  date: "date",
} as const;

/** SQL_TK_TimeCardsToAllocate_Response */
export interface SQL_TK_TimeCardsToAllocate_Response {
  /** @format date-time */
  date: string;
}

export const PropsSQLTKTimeCardsToAllocateResponse = {
  date: "date",
} as const;

/** SQL_TK_TimeCardsToAllocate_Request */
export interface SQL_TK_TimeCardsToAllocate_Request {
  /** @format date-time */
  userDate?: string | null;
}

export const PropsSQLTKTimeCardsToAllocateRequest = {
  userDate: "userDate",
} as const;

/** SQL_WH_Asset_Response */
export interface SQL_WH_Asset_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format  */
  number: string;
  /** @format  */
  serialNumber: string | null;
  /** @format  */
  status: string | null;
  /** @format  */
  category: string;
  /** @format  */
  imageDocumentId: number | null;
}

export const PropsSQLWHAssetResponse = {
  id: "id",
  name: "name",
  number: "number",
  serialNumber: "serialNumber",
  status: "status",
  category: "category",
  imageDocumentId: "imageDocumentId",
} as const;

/** SQL_WH_Asset_Request */
export interface SQL_WH_Asset_Request {
  /** @format  */
  assetId?: number | null;
}

export const PropsSQLWHAssetRequest = {
  assetId: "assetId",
} as const;

/** SQL_WH_Assets_Response */
export interface SQL_WH_Assets_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format  */
  number: string;
  /** @format  */
  serialNumber: string | null;
  /** @format  */
  status: string | null;
  /** @format  */
  category: string;
}

export const PropsSQLWHAssetsResponse = {
  id: "id",
  name: "name",
  number: "number",
  serialNumber: "serialNumber",
  status: "status",
  category: "category",
} as const;

/** SQL_WH_Assets_Request */
export interface SQL_WH_Assets_Request {
  /** @format  */
  warehouseId?: number | null;
}

export const PropsSQLWHAssetsRequest = {
  warehouseId: "warehouseId",
} as const;

/** SQL_WH_BOM_AvailableBuildPlans_Response */
export interface SQL_WH_BOM_AvailableBuildPlans_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWHBOMAvailableBuildPlansResponse = {
  id: "id",
  name: "name",
} as const;

/** SQL_WH_BOM_AvailableMaterials_Response */
export interface SQL_WH_BOM_AvailableMaterials_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format  */
  categoryId: number;
  /** @format  */
  category: string;
  /** @format  */
  uomId: number;
  /** @format  */
  uom: string;
  /** @format  */
  preferredVendorId: number | null;
  /** @format  */
  preferredVendor: string | null;
  /** @format double */
  cogs: number;
  /** @format  */
  internalPartNo: string;
  /** @format  */
  vendorPartNumbers: string | null;
  /** @format double */
  warehouseQTY: number;
  /** @format double */
  totalQTY: number;
  /** @format boolean */
  isSNRequired: boolean;
  /** @format  */
  title: string;
}

export const PropsSQLWHBOMAvailableMaterialsResponse = {
  id: "id",
  name: "name",
  categoryId: "categoryId",
  category: "category",
  uomId: "uomId",
  uom: "uom",
  preferredVendorId: "preferredVendorId",
  preferredVendor: "preferredVendor",
  cogs: "cogs",
  internalPartNo: "internalPartNo",
  vendorPartNumbers: "vendorPartNumbers",
  warehouseQTY: "warehouseQTY",
  totalQTY: "totalQTY",
  isSNRequired: "isSNRequired",
  title: "title",
} as const;

/** SQL_WH_BOM_AvailableMaterials_Request */
export interface SQL_WH_BOM_AvailableMaterials_Request {
  /** @format  */
  warehouseId?: number | null;
}

export const PropsSQLWHBOMAvailableMaterialsRequest = {
  warehouseId: "warehouseId",
} as const;

/** SQL_WH_BOM_AvailableTemplates_Response_Materials */
export interface SQL_WH_BOM_AvailableTemplates_Response_Materials {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format double */
  quantity: number;
}

export const PropsSQLWHBOMAvailableTemplatesResponseMaterials = {
  id: "id",
  name: "name",
  quantity: "quantity",
} as const;

/** SQL_WH_BOM_AvailableTemplates_Response */
export interface SQL_WH_BOM_AvailableTemplates_Response {
  /** @format  */
  name: string;
  materials: SQL_WH_BOM_AvailableTemplates_Response_Materials[];
}

export const PropsSQLWHBOMAvailableTemplatesResponse = {
  name: "name",
  materials: "materials",
} as const;

/** SQL_WH_BOM_AvailableTemplates_Request */
export interface SQL_WH_BOM_AvailableTemplates_Request {
  /** @format  */
  buildPlanId?: number | null;
}

export const PropsSQLWHBOMAvailableTemplatesRequest = {
  buildPlanId: "buildPlanId",
} as const;

/** SQL_WH_BOM_AvailableWarehouses_Response */
export interface SQL_WH_BOM_AvailableWarehouses_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWHBOMAvailableWarehousesResponse = {
  id: "id",
  name: "name",
} as const;

/** SQL_WH_MAT_AvailableVendors_Response */
export interface SQL_WH_MAT_AvailableVendors_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWHMATAvailableVendorsResponse = {
  id: "id",
  name: "name",
} as const;

/** SQL_WH_MAT_AvailableVendors_Request */
export interface SQL_WH_MAT_AvailableVendors_Request {
  /** @format  */
  vendorId?: number | null;
}

export const PropsSQLWHMATAvailableVendorsRequest = {
  vendorId: "vendorId",
} as const;

/** SQL_WH_Material_Response_WarehousesQTY */
export interface SQL_WH_Material_Response_WarehousesQTY {
  /** @format double */
  qty: number;
  /** @format  */
  warehouse: string;
}

export const PropsSQLWHMaterialResponseWarehousesQTY = {
  qty: "qty",
  warehouse: "warehouse",
} as const;

/** SQL_WH_Material_Response_Vendors */
export interface SQL_WH_Material_Response_Vendors {
  /** @format  */
  vendorId: number;
  /** @format  */
  vendorURL: string | null;
  /** @format  */
  vendorPartNo: string | null;
  /** @format double */
  vendorPrice: number | null;
  /** @format  */
  mfgProductNo: string | null;
  /** @format  */
  vendorName: string;
}

export const PropsSQLWHMaterialResponseVendors = {
  vendorId: "vendorId",
  vendorURL: "vendorURL",
  vendorPartNo: "vendorPartNo",
  vendorPrice: "vendorPrice",
  mfgProductNo: "mfgProductNo",
  vendorName: "vendorName",
} as const;

/** SQL_WH_Material_Response */
export interface SQL_WH_Material_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format  */
  categoryId: number;
  /** @format  */
  category: string;
  /** @format  */
  uomId: number;
  /** @format  */
  uom: string;
  /** @format  */
  preferredVendorId: number | null;
  /** @format  */
  preferredVendor: string | null;
  /** @format double */
  cogs: number;
  /** @format  */
  internalPartNo: string;
  /** @format  */
  url: string | null;
  /** @format  */
  description: string | null;
  /** @format boolean */
  isCPE: boolean;
  /** @format boolean */
  isSNRequired: boolean;
  /** @format  */
  imageDocumentId: number | null;
  warehousesQTY: SQL_WH_Material_Response_WarehousesQTY[];
  vendors: SQL_WH_Material_Response_Vendors[];
}

export const PropsSQLWHMaterialResponse = {
  id: "id",
  name: "name",
  categoryId: "categoryId",
  category: "category",
  uomId: "uomId",
  uom: "uom",
  preferredVendorId: "preferredVendorId",
  preferredVendor: "preferredVendor",
  cogs: "cogs",
  internalPartNo: "internalPartNo",
  url: "url",
  description: "description",
  isCPE: "isCPE",
  isSNRequired: "isSNRequired",
  imageDocumentId: "imageDocumentId",
  warehousesQTY: "warehousesQTY",
  vendors: "vendors",
} as const;

/** SQL_WH_Material_Request */
export interface SQL_WH_Material_Request {
  /** @format  */
  materialId?: number | null;
}

export const PropsSQLWHMaterialRequest = {
  materialId: "materialId",
} as const;

/** SQL_WH_MaterialFieldsOptions_Response_Categories */
export interface SQL_WH_MaterialFieldsOptions_Response_Categories {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWHMaterialFieldsOptionsResponseCategories = {
  id: "id",
  name: "name",
} as const;

/** SQL_WH_MaterialFieldsOptions_Response_Vendors */
export interface SQL_WH_MaterialFieldsOptions_Response_Vendors {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWHMaterialFieldsOptionsResponseVendors = {
  id: "id",
  name: "name",
} as const;

/** SQL_WH_MaterialFieldsOptions_Response_UOMs */
export interface SQL_WH_MaterialFieldsOptions_Response_UOMs {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWHMaterialFieldsOptionsResponseUOMs = {
  id: "id",
  name: "name",
} as const;

/** SQL_WH_MaterialFieldsOptions_Response */
export interface SQL_WH_MaterialFieldsOptions_Response {
  categories: SQL_WH_MaterialFieldsOptions_Response_Categories[];
  vendors: SQL_WH_MaterialFieldsOptions_Response_Vendors[];
  uoMs: SQL_WH_MaterialFieldsOptions_Response_UOMs[];
}

export const PropsSQLWHMaterialFieldsOptionsResponse = {
  categories: "categories",
  vendors: "vendors",
  uoMs: "uoMs",
} as const;

/** SQL_WH_MaterialsList_Response */
export interface SQL_WH_MaterialsList_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format  */
  categoryId: number;
  /** @format  */
  internalPartNo: string;
  /** @format  */
  category: string;
  /** @format  */
  uom: string;
  /** @format double */
  qty: number;
}

export const PropsSQLWHMaterialsListResponse = {
  id: "id",
  name: "name",
  categoryId: "categoryId",
  internalPartNo: "internalPartNo",
  category: "category",
  uom: "uom",
  qty: "qty",
} as const;

/** SQL_WH_MaterialsList_Request */
export interface SQL_WH_MaterialsList_Request {
  /** @format  */
  warehouseId?: number | null;
}

export const PropsSQLWHMaterialsListRequest = {
  warehouseId: "warehouseId",
} as const;

/** SQL_WH_MI_AvailableBuildPlans_Response */
export interface SQL_WH_MI_AvailableBuildPlans_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWHMIAvailableBuildPlansResponse = {
  id: "id",
  name: "name",
} as const;

/** SQL_WH_MI_AvailableMaterials_Response */
export interface SQL_WH_MI_AvailableMaterials_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWHMIAvailableMaterialsResponse = {
  id: "id",
  name: "name",
} as const;

/** SQL_WH_MI_AvailableMaterials_Request */
export interface SQL_WH_MI_AvailableMaterials_Request {
  /** @format  */
  materialId?: number | null;
}

export const PropsSQLWHMIAvailableMaterialsRequest = {
  materialId: "materialId",
} as const;

/** SQL_WH_MI_AvailableToAssignToBP_Response */
export interface SQL_WH_MI_AvailableToAssignToBP_Response {
  /** @format  */
  buildPlanId: number | null;
  /** @format  */
  buildPlanName: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format  */
  daysPast: number | null;
  /** @format  */
  id: number;
  /** @format  */
  materialId: number;
  /** @format  */
  materialName: string;
  /** @format  */
  shipmentPackageId: number;
  /** @format  */
  shipmentPackageNumber: string;
  /** @format  */
  sn: string;
  /** @format  */
  stageTitle: string;
  /** @format  */
  vendorId: number;
  /** @format  */
  vendorName: string;
  /** @format  */
  mfgProductNo: string | null;
  /** @format  */
  vendorPartNo: string | null;
  /** @format  */
  warehouseAreaId: number | null;
  /** @format  */
  warehouseAreaNumber: string | null;
  /** @format  */
  warehouseId: number;
  /** @format  */
  warehouseName: string;
}

export const PropsSQLWHMIAvailableToAssignToBPResponse = {
  buildPlanId: "buildPlanId",
  buildPlanName: "buildPlanName",
  createdAt: "createdAt",
  daysPast: "daysPast",
  id: "id",
  materialId: "materialId",
  materialName: "materialName",
  shipmentPackageId: "shipmentPackageId",
  shipmentPackageNumber: "shipmentPackageNumber",
  sn: "sn",
  stageTitle: "stageTitle",
  vendorId: "vendorId",
  vendorName: "vendorName",
  mfgProductNo: "mfgProductNo",
  vendorPartNo: "vendorPartNo",
  warehouseAreaId: "warehouseAreaId",
  warehouseAreaNumber: "warehouseAreaNumber",
  warehouseId: "warehouseId",
  warehouseName: "warehouseName",
} as const;

/** SQL_WH_MI_AvailableToAssignToBP_Request */
export interface SQL_WH_MI_AvailableToAssignToBP_Request {
  /** @format  */
  buildPlanId?: number | null;
}

export const PropsSQLWHMIAvailableToAssignToBPRequest = {
  buildPlanId: "buildPlanId",
} as const;

/** SQL_WH_MI_AvailableVendors_Response */
export interface SQL_WH_MI_AvailableVendors_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWHMIAvailableVendorsResponse = {
  id: "id",
  name: "name",
} as const;

/** SQL_WH_MI_AvailableVendors_Request */
export interface SQL_WH_MI_AvailableVendors_Request {
  /** @format  */
  materialId?: number | null;
}

export const PropsSQLWHMIAvailableVendorsRequest = {
  materialId: "materialId",
} as const;

/** SQL_WH_MI_Dashboard_Response */
export interface SQL_WH_MI_Dashboard_Response {
  /** @format  */
  buildPlanId: number | null;
  /** @format  */
  buildPlanName: string | null;
  /** @format  */
  siteId: number | null;
  /** @format  */
  siteName: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format  */
  daysPast: number | null;
  /** @format  */
  id: number;
  /** @format  */
  materialId: number;
  /** @format  */
  materialName: string;
  /** @format  */
  shipmentPackageId: number;
  /** @format  */
  shipmentPackageNumber: string;
  /** @format  */
  sn: string;
  /** @format  */
  stageTitle: string;
  /** @format  */
  vendorId: number;
  /** @format  */
  vendorName: string;
  /** @format  */
  mfgProductNo: string | null;
  /** @format  */
  vendorPartNo: string | null;
  /** @format  */
  warehouseAreaId: number | null;
  /** @format  */
  warehouseAreaNumber: string | null;
  /** @format  */
  warehouseId: number | null;
  /** @format  */
  warehouseName: string | null;
  /** @format  */
  boM_Id: number | null;
  /** @format  */
  boM_Number: string | null;
  /** @format date-time */
  boM_Date: string | null;
  /** @format date-time */
  boM_DueDate: string | null;
  /** @format  */
  boM_DaysPast: number | null;
  /** @format  */
  canChangeBP: number;
}

export const PropsSQLWHMIDashboardResponse = {
  buildPlanId: "buildPlanId",
  buildPlanName: "buildPlanName",
  siteId: "siteId",
  siteName: "siteName",
  createdAt: "createdAt",
  daysPast: "daysPast",
  id: "id",
  materialId: "materialId",
  materialName: "materialName",
  shipmentPackageId: "shipmentPackageId",
  shipmentPackageNumber: "shipmentPackageNumber",
  sn: "sn",
  stageTitle: "stageTitle",
  vendorId: "vendorId",
  vendorName: "vendorName",
  mfgProductNo: "mfgProductNo",
  vendorPartNo: "vendorPartNo",
  warehouseAreaId: "warehouseAreaId",
  warehouseAreaNumber: "warehouseAreaNumber",
  warehouseId: "warehouseId",
  warehouseName: "warehouseName",
  boM_Id: "boM_Id",
  boM_Number: "boM_Number",
  boM_Date: "boM_Date",
  boM_DueDate: "boM_DueDate",
  boM_DaysPast: "boM_DaysPast",
  canChangeBP: "canChangeBP",
} as const;

/** SQL_WH_MI_Dashboard_Request */
export interface SQL_WH_MI_Dashboard_Request {
  /** @format  */
  buildPlanId?: number | null;
}

export const PropsSQLWHMIDashboardRequest = {
  buildPlanId: "buildPlanId",
} as const;

/** SQL_WH_MyWarehouses_Response */
export interface SQL_WH_MyWarehouses_Response {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWHMyWarehousesResponse = {
  id: "id",
  name: "name",
} as const;

/** SQL_WO_ChecklistResult_Response */
export interface SQL_WO_ChecklistResult_Response {
  /** @format  */
  workOrderId: number;
  /** @format  */
  checklistId: number;
  /** @format  */
  checklistItemId: number;
  /** @format  */
  id: number;
  /** @format  */
  dispatchId: number;
  /** @format  */
  index: number;
  /** @format  */
  status: string;
  /** @format boolean */
  hasMedia: boolean;
  /** @format  */
  comments: string | null;
  /** @format  */
  reviewerComments: string | null;
  /** @format  */
  documentId: number | null;
}

export const PropsSQLWOChecklistResultResponse = {
  workOrderId: "workOrderId",
  checklistId: "checklistId",
  checklistItemId: "checklistItemId",
  id: "id",
  dispatchId: "dispatchId",
  index: "index",
  status: "status",
  hasMedia: "hasMedia",
  comments: "comments",
  reviewerComments: "reviewerComments",
  documentId: "documentId",
} as const;

/** SQL_WO_ChecklistResult_Request */
export interface SQL_WO_ChecklistResult_Request {
  /** @format  */
  resultId?: number | null;
}

export const PropsSQLWOChecklistResultRequest = {
  resultId: "resultId",
} as const;

/** SQL_WO_Checklists_Response_ChecklistItems_WorkResults */
export interface SQL_WO_Checklists_Response_ChecklistItems_WorkResults {
  /** @format  */
  checklistId: number;
  /** @format  */
  checklistItemId: number;
  /** @format  */
  id: number;
  /** @format  */
  dispatchId: number;
  /** @format  */
  index: number;
  /** @format  */
  status: string;
  /** @format boolean */
  hasMedia: boolean;
  /** @format  */
  comments: string | null;
  /** @format  */
  reviewerComments: string | null;
  /** @format  */
  documentId: number | null;
}

export const PropsSQLWOChecklistsResponseChecklistItemsWorkResults = {
  checklistId: "checklistId",
  checklistItemId: "checklistItemId",
  id: "id",
  dispatchId: "dispatchId",
  index: "index",
  status: "status",
  hasMedia: "hasMedia",
  comments: "comments",
  reviewerComments: "reviewerComments",
  documentId: "documentId",
} as const;

/** SQL_WO_Checklists_Response_ChecklistItems */
export interface SQL_WO_Checklists_Response_ChecklistItems {
  /** @format  */
  checklistId: number;
  /** @format  */
  checklistItemId: number;
  /** @format  */
  checklistItemName: string;
  /** @format double */
  orderNumber: number;
  /** @format  */
  description: string | null;
  /** @format boolean */
  isRequiresTimestamp: boolean;
  /** @format boolean */
  isRequiresCoordinates: boolean;
  /** @format boolean */
  isRequiresSite: boolean;
  /** @format boolean */
  isRequiresSiteAddress: boolean;
  /** @format boolean */
  isActive: boolean;
  /** @format  */
  sampleDocumentId: number | null;
  workResults: SQL_WO_Checklists_Response_ChecklistItems_WorkResults[];
}

export const PropsSQLWOChecklistsResponseChecklistItems = {
  checklistId: "checklistId",
  checklistItemId: "checklistItemId",
  checklistItemName: "checklistItemName",
  orderNumber: "orderNumber",
  description: "description",
  isRequiresTimestamp: "isRequiresTimestamp",
  isRequiresCoordinates: "isRequiresCoordinates",
  isRequiresSite: "isRequiresSite",
  isRequiresSiteAddress: "isRequiresSiteAddress",
  isActive: "isActive",
  sampleDocumentId: "sampleDocumentId",
  workResults: "workResults",
} as const;

/** SQL_WO_Checklists_Response */
export interface SQL_WO_Checklists_Response {
  /** @format  */
  checklistId: number;
  /** @format  */
  checkListName: string;
  /** @format boolean */
  isRecurring: boolean;
  /** @format  */
  dispatchId: number | null;
  /** @format date-time */
  dispatchDate: string | null;
  /** @format  */
  orderNumber: number;
  checklistItems: SQL_WO_Checklists_Response_ChecklistItems[];
}

export const PropsSQLWOChecklistsResponse = {
  checklistId: "checklistId",
  checkListName: "checkListName",
  isRecurring: "isRecurring",
  dispatchId: "dispatchId",
  dispatchDate: "dispatchDate",
  orderNumber: "orderNumber",
  checklistItems: "checklistItems",
} as const;

/** SQL_WO_Checklists_Request */
export interface SQL_WO_Checklists_Request {
  /** @format  */
  workOrderId?: number | null;
}

export const PropsSQLWOChecklistsRequest = {
  workOrderId: "workOrderId",
} as const;

/** SQL_WO_ChecklistTemplates_Response */
export interface SQL_WO_ChecklistTemplates_Response {
  /** @format  */
  checkListTemplateId: number;
  /** @format  */
  checkListTemplateName: string;
}

export const PropsSQLWOChecklistTemplatesResponse = {
  checkListTemplateId: "checkListTemplateId",
  checkListTemplateName: "checkListTemplateName",
} as const;

/** SQL_WO_ChecklistTemplates_Request */
export interface SQL_WO_ChecklistTemplates_Request {
  /** @format  */
  workOrderId?: number | null;
}

export const PropsSQLWOChecklistTemplatesRequest = {
  workOrderId: "workOrderId",
} as const;

/** SQL_WO_My_Response_CrewLead */
export interface SQL_WO_My_Response_CrewLead {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWOMyResponseCrewLead = {
  id: "id",
  name: "name",
} as const;

/** SQL_WO_My_Response_Crew */
export interface SQL_WO_My_Response_Crew {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
}

export const PropsSQLWOMyResponseCrew = {
  id: "id",
  name: "name",
} as const;

/** SQL_WO_My_Response_WorkOrder_BuildPlan */
export interface SQL_WO_My_Response_WorkOrder_BuildPlan {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format  */
  progress: number;
  /** @format double */
  actualHours: number;
  /** @format double */
  budgetHours: number;
}

export const PropsSQLWOMyResponseWorkOrderBuildPlan = {
  id: "id",
  name: "name",
  progress: "progress",
  actualHours: "actualHours",
  budgetHours: "budgetHours",
} as const;

/** SQL_WO_My_Response_WorkOrder_Site */
export interface SQL_WO_My_Response_WorkOrder_Site {
  /** @format  */
  id: number;
  /** @format  */
  name: string;
  /** @format  */
  accessInstructions: string | null;
  /** @format  */
  typeName: string | null;
  /** @format  */
  addressString: string;
  /** @format double */
  lat: number;
  /** @format double */
  lng: number;
  /** @format  */
  responsibleName: string | null;
  /** @format  */
  jurisdiction: string | null;
}

export const PropsSQLWOMyResponseWorkOrderSite = {
  id: "id",
  name: "name",
  accessInstructions: "accessInstructions",
  typeName: "typeName",
  addressString: "addressString",
  lat: "lat",
  lng: "lng",
  responsibleName: "responsibleName",
  jurisdiction: "jurisdiction",
} as const;

/** SQL_WO_My_Response_WorkOrder */
export interface SQL_WO_My_Response_WorkOrder {
  /** @format  */
  id: number;
  /** @format  */
  number: string;
  /** @format  */
  typeName: string;
  /** @format boolean */
  hasRejectedResults: boolean;
  /** @format  */
  statusText: string | null;
  /** @format date-time */
  completedDate: string | null;
  /** @format  */
  description: string | null;
  /** @format  */
  scenarioName: string;
  /** @format  */
  projectName: string;
  buildPlan?: SQL_WO_My_Response_WorkOrder_BuildPlan;
  site?: SQL_WO_My_Response_WorkOrder_Site;
}

export const PropsSQLWOMyResponseWorkOrder = {
  id: "id",
  number: "number",
  typeName: "typeName",
  hasRejectedResults: "hasRejectedResults",
  statusText: "statusText",
  completedDate: "completedDate",
  description: "description",
  scenarioName: "scenarioName",
  projectName: "projectName",
  buildPlan: "buildPlan",
  site: "site",
} as const;

/** SQL_WO_My_Response */
export interface SQL_WO_My_Response {
  /** @format  */
  id: number;
  /** @format date-time */
  date: string;
  /** @format boolean */
  isActive: boolean;
  /** @format boolean */
  isPublished: boolean;
  /** @format boolean */
  allowOvernight: boolean;
  /** @format date-time */
  arriveAfter: string | null;
  /** @format date-time */
  arriveBefore: string | null;
  /** @format date-time */
  clockInAfter: string | null;
  /** @format date-time */
  clockOutBefore: string | null;
  /** @format date-time */
  departAfter: string | null;
  /** @format date-time */
  departBefore: string | null;
  /** @format  */
  comments: string | null;
  crewLead?: SQL_WO_My_Response_CrewLead;
  crew: SQL_WO_My_Response_Crew[];
  workOrder?: SQL_WO_My_Response_WorkOrder;
}

export const PropsSQLWOMyResponse = {
  id: "id",
  date: "date",
  isActive: "isActive",
  isPublished: "isPublished",
  allowOvernight: "allowOvernight",
  arriveAfter: "arriveAfter",
  arriveBefore: "arriveBefore",
  clockInAfter: "clockInAfter",
  clockOutBefore: "clockOutBefore",
  departAfter: "departAfter",
  departBefore: "departBefore",
  comments: "comments",
  crewLead: "crewLead",
  crew: "crew",
  workOrder: "workOrder",
} as const;

/** SQL_WO_Tasks_Response */
export interface SQL_WO_Tasks_Response {
  /** @format  */
  taskId: number;
  /** @format  */
  taskName: string;
  /** @format boolean */
  isCompleted: boolean;
  /** @format  */
  description: string | null;
  /** @format  */
  statusText: string | null;
}

export const PropsSQLWOTasksResponse = {
  taskId: "taskId",
  taskName: "taskName",
  isCompleted: "isCompleted",
  description: "description",
  statusText: "statusText",
} as const;

/** SQL_WO_Tasks_Request */
export interface SQL_WO_Tasks_Request {
  /** @format  */
  workOrderId?: number | null;
}

export const PropsSQLWOTasksRequest = {
  workOrderId: "workOrderId",
} as const;

export interface FxScriptException {
  Message?: string;
  Level?: "Information" | "Warning" | "Exception";
  InnerException?: object;
}

export const PropsFxScriptException = {
  Message: "Message",
  Level: "Level",
  InnerException: "InnerException",
} as const;

/** DESKTOP_WH_BOM_CreatePurchaseRequest_Materials */
export interface DESKTOP_WH_BOM_CreatePurchaseRequest_Materials {
  /** @format  */
  Id: number;
  /** @format  */
  Quantity: number;
}

export const PropsDESKTOPWHBOMCreatePurchaseRequestMaterials = {
  Id: "Id",
  Quantity: "Quantity",
} as const;

/** Inbox_DeleteLinks_LinkIds */
export interface Inbox_DeleteLinks_LinkIds {
  /** @format  */
  Id: number;
}

export const PropsInboxDeleteLinksLinkIds = {
  Id: "Id",
} as const;

/** Inbox_MarkJobsAsRead_JobIds */
export interface Inbox_MarkJobsAsRead_JobIds {
  /** @format  */
  Id: number;
}

export const PropsInboxMarkJobsAsReadJobIds = {
  Id: "Id",
} as const;

/** TestDescriptionScript_required_subData_required_Child_required */
export interface TestDescriptionScript_required_subData_required_Child_required {
  IntArr?: number[];
  /** @format  */
  Str?: string;
}

export const PropsTestDescriptionScriptRequiredSubDataRequiredChildRequired = {
  IntArr: "IntArr",
  Str: "Str",
} as const;

/** TestDescriptionScript_required_subData_required_Child */
export interface TestDescriptionScript_required_subData_required_Child {
  required?: TestDescriptionScript_required_subData_required_Child_required;
}

export const PropsTestDescriptionScriptRequiredSubDataRequiredChild = {
  required: "required",
} as const;

/** TestDescriptionScript_required_subData_required */
export interface TestDescriptionScript_required_subData_required {
  /** @format  */
  JustInt?: number;
  /** @format  */
  JustString?: string;
  Child?: TestDescriptionScript_required_subData_required_Child;
}

export const PropsTestDescriptionScriptRequiredSubDataRequired = {
  JustInt: "JustInt",
  JustString: "JustString",
  Child: "Child",
} as const;

/** TestDescriptionScript_required_subData_optional */
export interface TestDescriptionScript_required_subData_optional {
  /** @format  */
  JustIntOpt?: number;
  /** @format  */
  JustStringOpt?: string;
}

export const PropsTestDescriptionScriptRequiredSubDataOptional = {
  JustIntOpt: "JustIntOpt",
  JustStringOpt: "JustStringOpt",
} as const;

/** TestDescriptionScript_required_subData */
export interface TestDescriptionScript_required_subData {
  required?: TestDescriptionScript_required_subData_required;
  optional?: TestDescriptionScript_required_subData_optional;
}

export const PropsTestDescriptionScriptRequiredSubData = {
  required: "required",
  optional: "optional",
} as const;

/** TestDescriptionScript_required */
export interface TestDescriptionScript_required {
  /** @format  */
  justIntP?: number;
  justIntArrP?: number[];
  subData?: TestDescriptionScript_required_subData[];
}

export const PropsTestDescriptionScriptRequired = {
  justIntP: "justIntP",
  justIntArrP: "justIntArrP",
  subData: "subData",
} as const;

/** TK_AllocateTime_Allocation */
export interface TK_AllocateTime_Allocation {
  /** @format  */
  WorkOrderId: number;
  /** @format  */
  Percentage: number;
}

export const PropsTKAllocateTimeAllocation = {
  WorkOrderId: "WorkOrderId",
  Percentage: "Percentage",
} as const;

/** TK_ClockIn_Attestations */
export interface TK_ClockIn_Attestations {
  /** @format  */
  Id: number;
  /** @format boolean */
  Accepted: boolean;
  /** @format  */
  Comment?: string;
}

export const PropsTKClockInAttestations = {
  Id: "Id",
  Accepted: "Accepted",
  Comment: "Comment",
} as const;

/** TK_ClockOut_Attestations */
export interface TK_ClockOut_Attestations {
  /** @format  */
  Id: number;
  /** @format boolean */
  Accepted: boolean;
  /** @format  */
  Comment?: string;
}

export const PropsTKClockOutAttestations = {
  Id: "Id",
  Accepted: "Accepted",
  Comment: "Comment",
} as const;

/** TK_NewRequest_Solicitation */
export interface TK_NewRequest_Solicitation {
  /** @format  */
  EmployeeID: number;
  /** @format date-time */
  RequestDate: string;
  /** @format  */
  RequestType: string;
  /** @format  */
  Comment: string;
  /** @format  */
  PayrollItemID?: number;
  /** @format  */
  ReasonID?: number;
}

export const PropsTKNewRequestSolicitation = {
  EmployeeID: "EmployeeID",
  RequestDate: "RequestDate",
  RequestType: "RequestType",
  Comment: "Comment",
  PayrollItemID: "PayrollItemID",
  ReasonID: "ReasonID",
} as const;

/** TK_NewRequest_TAR_WOAllocation */
export interface TK_NewRequest_TAR_WOAllocation {
  /** @format  */
  WOId: number;
  /** @format  */
  Percentage: number;
}

export const PropsTKNewRequestTARWOAllocation = {
  WOId: "WOId",
  Percentage: "Percentage",
} as const;

/** TK_NewRequest_TAR */
export interface TK_NewRequest_TAR {
  /** @format date-time */
  RequestDate: string;
  /** @format  */
  Comment: string;
  /** @format date-time */
  LunchStart?: string;
  /** @format date-time */
  LunchEnd?: string;
  /** @format date-time */
  ClockIn?: string;
  /** @format date-time */
  ClockOut?: string;
  WOAllocation?: TK_NewRequest_TAR_WOAllocation[];
}

export const PropsTKNewRequestTAR = {
  RequestDate: "RequestDate",
  Comment: "Comment",
  LunchStart: "LunchStart",
  LunchEnd: "LunchEnd",
  ClockIn: "ClockIn",
  ClockOut: "ClockOut",
  WOAllocation: "WOAllocation",
} as const;

/** TK_NewRequest_TimeOff */
export interface TK_NewRequest_TimeOff {
  /** @format date-time */
  RequestDateFrom: string;
  /** @format date-time */
  RequestDateTo: string;
  /** @format  */
  Comment: string;
  /** @format  */
  ReasonID: number;
}

export const PropsTKNewRequestTimeOff = {
  RequestDateFrom: "RequestDateFrom",
  RequestDateTo: "RequestDateTo",
  Comment: "Comment",
  ReasonID: "ReasonID",
} as const;

/** TK_NewRequest_PayrollItem */
export interface TK_NewRequest_PayrollItem {
  /** @format date-time */
  RequestDate: string;
  /** @format  */
  Comment: string;
  /** @format  */
  PayrollItemID: number;
  /** @format  */
  Quantity: number;
  /** @format  */
  WorkOrderID?: number;
}

export const PropsTKNewRequestPayrollItem = {
  RequestDate: "RequestDate",
  Comment: "Comment",
  PayrollItemID: "PayrollItemID",
  Quantity: "Quantity",
  WorkOrderID: "WorkOrderID",
} as const;

/** WH_ReturnMaterialItems_Materials */
export interface WH_ReturnMaterialItems_Materials {
  /** @format  */
  Id: number;
  /** @format  */
  Quantity: number;
}

export const PropsWHReturnMaterialItemsMaterials = {
  Id: "Id",
  Quantity: "Quantity",
} as const;

/** Script_ASSETS_NewTransferChecklistResult_Request */
export interface Script_ASSETS_NewTransferChecklistResult_Request {
  /** @format  */
  AssetId: number;
  /** @format  */
  ChecklistItemId: number;
  /** @format  */
  TempDocumentId: number;
}

export const PropsScriptASSETSNewTransferChecklistResultRequest = {
  AssetId: "AssetId",
  ChecklistItemId: "ChecklistItemId",
  TempDocumentId: "TempDocumentId",
} as const;

/** Script_ASSETS_RequestChangeCondition_Request */
export interface Script_ASSETS_RequestChangeCondition_Request {
  /** @format  */
  AssetId: number;
  /** @format  */
  Description: string;
}

export const PropsScriptASSETSRequestChangeConditionRequest = {
  AssetId: "AssetId",
  Description: "Description",
} as const;

/** Script_ASSETS_Transfer_Request */
export interface Script_ASSETS_Transfer_Request {
  /** @format  */
  AssetId: number;
  /** @format  */
  Action: string;
  /** @format  */
  TransferToEmployeeId?: number;
  /** @format  */
  Comments?: string;
}

export const PropsScriptASSETSTransferRequest = {
  AssetId: "AssetId",
  Action: "Action",
  TransferToEmployeeId: "TransferToEmployeeId",
  Comments: "Comments",
} as const;

/** Script_COD_Create_Request */
export interface Script_COD_Create_Request {
  /** @format date-time */
  Date: string;
  /** @format  */
  BuildPlanId: number;
  /** @format  */
  Description: string;
  /** @format double */
  Amount: number;
  /** @format  */
  CardId: number;
  /** @format  */
  StoreName: string;
  /** @format  */
  CategoryId: number;
  Documents?: number[];
}

export const PropsScriptCODCreateRequest = {
  Date: "Date",
  BuildPlanId: "BuildPlanId",
  Description: "Description",
  Amount: "Amount",
  CardId: "CardId",
  StoreName: "StoreName",
  CategoryId: "CategoryId",
  Documents: "Documents",
} as const;

/** Script_COD_Delete_Request */
export interface Script_COD_Delete_Request {
  /** @format  */
  Id: number;
}

export const PropsScriptCODDeleteRequest = {
  Id: "Id",
} as const;

/** Script_COD_NewDocument_Request */
export interface Script_COD_NewDocument_Request {
  /** @format  */
  Id: number;
}

export const PropsScriptCODNewDocumentRequest = {
  Id: "Id",
} as const;

/** Script_COD_Submit_Request */
export interface Script_COD_Submit_Request {
  /** @format  */
  Id: number;
}

export const PropsScriptCODSubmitRequest = {
  Id: "Id",
} as const;

/** Script_DESKTOP_FinancialDistribution_Show_Request */
export interface Script_DESKTOP_FinancialDistribution_Show_Request {
  /** @format  */
  ConterraId: number;
}

export const PropsScriptDESKTOPFinancialDistributionShowRequest = {
  ConterraId: "ConterraId",
} as const;

/** Script_DESKTOP_WH_BOM_CreatePurchaseRequest_Request */
export interface Script_DESKTOP_WH_BOM_CreatePurchaseRequest_Request {
  /** @format  */
  BomId: number;
  /** @format  */
  LocationId: number;
  /** @format  */
  BuildPlanId: number;
  /** @format date-time */
  DueDate: string;
  Materials: DESKTOP_WH_BOM_CreatePurchaseRequest_Materials[];
}

export const PropsScriptDESKTOPWHBOMCreatePurchaseRequestRequest = {
  BomId: "BomId",
  LocationId: "LocationId",
  BuildPlanId: "BuildPlanId",
  DueDate: "DueDate",
  Materials: "Materials",
} as const;

/** Script_DEV_RaiseException_Request */
export type Script_DEV_RaiseException_Request = any;

/** Script_DEV_RaiseInformation_Request */
export type Script_DEV_RaiseInformation_Request = any;

/** Script_DEV_RaiseSystemException_Request */
export type Script_DEV_RaiseSystemException_Request = any;

/** Script_DEV_RaiseWarning_Request */
export type Script_DEV_RaiseWarning_Request = any;

/** Script_Document_GetTempDocumentID_Request */
export type Script_Document_GetTempDocumentID_Request = any;

/** Script_Document_ManageIsPublished_Request */
export interface Script_Document_ManageIsPublished_Request {
  /** @format  */
  Id: number;
  /** @format boolean */
  IsPublished: boolean;
}

export const PropsScriptDocumentManageIsPublishedRequest = {
  Id: "Id",
  IsPublished: "IsPublished",
} as const;

/** Script_Documents_CreateNew_Request */
export interface Script_Documents_CreateNew_Request {
  /** @format  */
  SourceId: number;
  /** @format  */
  FileName: string;
  /** @format boolean */
  IsPublished?: boolean;
  /** @format  */
  DocKindId?: number;
}

export const PropsScriptDocumentsCreateNewRequest = {
  SourceId: "SourceId",
  FileName: "FileName",
  IsPublished: "IsPublished",
  DocKindId: "DocKindId",
} as const;

/** Script_GetURLForMobile_Request */
export interface Script_GetURLForMobile_Request {
  /** @format  */
  Type: string;
  /** @format  */
  ObjectId?: number;
}

export const PropsScriptGetURLForMobileRequest = {
  Type: "Type",
  ObjectId: "ObjectId",
} as const;

/** Script_Inbox_DeleteLinks_Request */
export interface Script_Inbox_DeleteLinks_Request {
  LinkIds: Inbox_DeleteLinks_LinkIds[];
}

export const PropsScriptInboxDeleteLinksRequest = {
  LinkIds: "LinkIds",
} as const;

/** Script_Inbox_GetJobTreeHTML_Request */
export interface Script_Inbox_GetJobTreeHTML_Request {
  /** @format  */
  JobId: number;
}

export const PropsScriptInboxGetJobTreeHTMLRequest = {
  JobId: "JobId",
} as const;

/** Script_Inbox_MarkJobsAsRead_Request */
export interface Script_Inbox_MarkJobsAsRead_Request {
  JobIds: Inbox_MarkJobsAsRead_JobIds[];
  /** @format boolean */
  IsRead?: boolean;
}

export const PropsScriptInboxMarkJobsAsReadRequest = {
  JobIds: "JobIds",
  IsRead: "IsRead",
} as const;

/** Script_TestDescriptionScript_Request */
export interface Script_TestDescriptionScript_Request {
  required?: TestDescriptionScript_required;
}

export const PropsScriptTestDescriptionScriptRequest = {
  required: "required",
} as const;

/** Script_TestLongScript_Request */
export interface Script_TestLongScript_Request {
  /** @format  */
  delay: number;
}

export const PropsScriptTestLongScriptRequest = {
  delay: "delay",
} as const;

/** Script_TestStringScript_Request */
export interface Script_TestStringScript_Request {
  /** @format  */
  delay: number;
}

export const PropsScriptTestStringScriptRequest = {
  delay: "delay",
} as const;

/** Script_TK_AllocateTime_Request */
export interface Script_TK_AllocateTime_Request {
  /** @format date-time */
  Date: string;
  Allocation: TK_AllocateTime_Allocation[];
}

export const PropsScriptTKAllocateTimeRequest = {
  Date: "Date",
  Allocation: "Allocation",
} as const;

/** Script_TK_CheckIn_Request */
export type Script_TK_CheckIn_Request = any;

/** Script_TK_ClockIn_Request */
export interface Script_TK_ClockIn_Request {
  Attestations: TK_ClockIn_Attestations[];
}

export const PropsScriptTKClockInRequest = {
  Attestations: "Attestations",
} as const;

/** Script_TK_ClockOut_Request */
export interface Script_TK_ClockOut_Request {
  Attestations: TK_ClockOut_Attestations[];
  /** @format  */
  TimeAdjustmentInSeconds?: number;
}

export const PropsScriptTKClockOutRequest = {
  Attestations: "Attestations",
  TimeAdjustmentInSeconds: "TimeAdjustmentInSeconds",
} as const;

/** Script_TK_CreateLunch_Request */
export interface Script_TK_CreateLunch_Request {
  /** @format  */
  Duration: number;
  /** @format  */
  TimeAdjustmentInSeconds?: number;
}

export const PropsScriptTKCreateLunchRequest = {
  Duration: "Duration",
  TimeAdjustmentInSeconds: "TimeAdjustmentInSeconds",
} as const;

/** Script_TK_DeleteLunch_Request */
export interface Script_TK_DeleteLunch_Request {
  /** @format  */
  LunchId: number;
}

export const PropsScriptTKDeleteLunchRequest = {
  LunchId: "LunchId",
} as const;

/** Script_TK_FinishLunch_Request */
export interface Script_TK_FinishLunch_Request {
  /** @format  */
  LunchId: number;
}

export const PropsScriptTKFinishLunchRequest = {
  LunchId: "LunchId",
} as const;

/** Script_TK_FinishManual_Request */
export type Script_TK_FinishManual_Request = any;

/** Script_TK_NewRequest_Request */
export interface Script_TK_NewRequest_Request {
  Solicitation?: TK_NewRequest_Solicitation;
  TAR?: TK_NewRequest_TAR;
  TimeOff?: TK_NewRequest_TimeOff;
  PayrollItem?: TK_NewRequest_PayrollItem;
}

export const PropsScriptTKNewRequestRequest = {
  Solicitation: "Solicitation",
  TAR: "TAR",
  TimeOff: "TimeOff",
  PayrollItem: "PayrollItem",
} as const;

/** Script_TK_RevokeRequest_Request */
export interface Script_TK_RevokeRequest_Request {
  /** @format  */
  RequestId: number;
}

export const PropsScriptTKRevokeRequestRequest = {
  RequestId: "RequestId",
} as const;

/** Script_TK_SendComment_Request */
export interface Script_TK_SendComment_Request {
  /** @format  */
  Comment: string;
}

export const PropsScriptTKSendCommentRequest = {
  Comment: "Comment",
} as const;

/** Script_TK_SetTimeEntryLocation_Request */
export interface Script_TK_SetTimeEntryLocation_Request {
  /** @format  */
  TimeEntryId: number;
  /** @format  */
  LocationId: number;
}

export const PropsScriptTKSetTimeEntryLocationRequest = {
  TimeEntryId: "TimeEntryId",
  LocationId: "LocationId",
} as const;

/** Script_TK_StartManual_Request */
export interface Script_TK_StartManual_Request {
  /** @format  */
  WOId: number;
}

export const PropsScriptTKStartManualRequest = {
  WOId: "WOId",
} as const;

/** Script_TK_WaiveLunch_Request */
export interface Script_TK_WaiveLunch_Request {
  /** @format  */
  Comment: string;
}

export const PropsScriptTKWaiveLunchRequest = {
  Comment: "Comment",
} as const;

/** Script_WH_BOM_GetBuildPlanWarehouse_Request */
export interface Script_WH_BOM_GetBuildPlanWarehouse_Request {
  /** @format  */
  BuildPlanId: number;
}

export const PropsScriptWHBOMGetBuildPlanWarehouseRequest = {
  BuildPlanId: "BuildPlanId",
} as const;

/** Script_WH_BOMPullMaterials_Request */
export interface Script_WH_BOMPullMaterials_Request {
  /** @format  */
  BomId: number;
  /** @format  */
  WarehouseId: number;
  /** @format  */
  MaterialId: number;
  /** @format  */
  PickQuantity: number;
}

export const PropsScriptWHBOMPullMaterialsRequest = {
  BomId: "BomId",
  WarehouseId: "WarehouseId",
  MaterialId: "MaterialId",
  PickQuantity: "PickQuantity",
} as const;

/** Script_WH_BOMReturnMaterial_Request */
export interface Script_WH_BOMReturnMaterial_Request {
  /** @format  */
  BomId: number;
  /** @format  */
  WarehouseId: number;
  /** @format  */
  MaterialId: number;
  /** @format  */
  Quantity: number;
}

export const PropsScriptWHBOMReturnMaterialRequest = {
  BomId: "BomId",
  WarehouseId: "WarehouseId",
  MaterialId: "MaterialId",
  Quantity: "Quantity",
} as const;

/** Script_WH_PushMaterialItems_Request */
export interface Script_WH_PushMaterialItems_Request {
  /** @format  */
  PackageId: number;
  /** @format  */
  WarehouseId: number;
  /** @format  */
  MaterialId: number;
  /** @format  */
  Count: number;
}

export const PropsScriptWHPushMaterialItemsRequest = {
  PackageId: "PackageId",
  WarehouseId: "WarehouseId",
  MaterialId: "MaterialId",
  Count: "Count",
} as const;

/** Script_WH_RemoveVendorFromMaterial_Request */
export interface Script_WH_RemoveVendorFromMaterial_Request {
  /** @format  */
  MaterialId: number;
  /** @format  */
  VendorId: number;
}

export const PropsScriptWHRemoveVendorFromMaterialRequest = {
  MaterialId: "MaterialId",
  VendorId: "VendorId",
} as const;

/** Script_WH_ReturnMaterialItems_Request */
export interface Script_WH_ReturnMaterialItems_Request {
  /** @format  */
  WarehouseId: number;
  Materials: WH_ReturnMaterialItems_Materials[];
}

export const PropsScriptWHReturnMaterialItemsRequest = {
  WarehouseId: "WarehouseId",
  Materials: "Materials",
} as const;

/** Script_WH_UpsertMaterial_Request */
export interface Script_WH_UpsertMaterial_Request {
  /** @format  */
  Name: string;
  /** @format  */
  CategoryId: number;
  /** @format  */
  UOMId: number;
  /** @format boolean */
  IsCPE: boolean;
  /** @format boolean */
  IsSNRequired: boolean;
  /** @format  */
  Id?: number;
  /** @format  */
  Description?: string;
  /** @format  */
  PreferredVendorId?: number;
  /** @format  */
  URL?: string;
  /** @format double */
  COGS?: number;
}

export const PropsScriptWHUpsertMaterialRequest = {
  Name: "Name",
  CategoryId: "CategoryId",
  UOMId: "UOMId",
  IsCPE: "IsCPE",
  IsSNRequired: "IsSNRequired",
  Id: "Id",
  Description: "Description",
  PreferredVendorId: "PreferredVendorId",
  URL: "URL",
  COGS: "COGS",
} as const;

/** Script_WH_UpsertMaterialVendor_Request */
export interface Script_WH_UpsertMaterialVendor_Request {
  /** @format  */
  MaterialId: number;
  /** @format  */
  VendorId: number;
  /** @format  */
  PartNo?: string;
  /** @format  */
  URL?: string;
  /** @format double */
  Price?: number;
  /** @format  */
  MFGProductNo?: string;
}

export const PropsScriptWHUpsertMaterialVendorRequest = {
  MaterialId: "MaterialId",
  VendorId: "VendorId",
  PartNo: "PartNo",
  URL: "URL",
  Price: "Price",
  MFGProductNo: "MFGProductNo",
} as const;

/** Script_WO_Complete_Request */
export interface Script_WO_Complete_Request {
  /** @format  */
  WorkOrderId: number;
}

export const PropsScriptWOCompleteRequest = {
  WorkOrderId: "WorkOrderId",
} as const;

/** Script_WO_CreateChecklist_Request */
export interface Script_WO_CreateChecklist_Request {
  /** @format  */
  WorkOrderId: number;
  /** @format  */
  ChecklistTemplateId: number;
  /** @format  */
  AdditionalClassifier: string;
}

export const PropsScriptWOCreateChecklistRequest = {
  WorkOrderId: "WorkOrderId",
  ChecklistTemplateId: "ChecklistTemplateId",
  AdditionalClassifier: "AdditionalClassifier",
} as const;

/** Script_WO_CreateChecklistResult_Request */
export interface Script_WO_CreateChecklistResult_Request {
  /** @format  */
  ChecklistId: number;
  /** @format  */
  ChecklistItemId: number;
  /** @format  */
  DispatchId?: number;
}

export const PropsScriptWOCreateChecklistResultRequest = {
  ChecklistId: "ChecklistId",
  ChecklistItemId: "ChecklistItemId",
  DispatchId: "DispatchId",
} as const;

/** Script_WO_CreateChecklistResultFile_Request */
export interface Script_WO_CreateChecklistResultFile_Request {
  /** @format  */
  ChecklistResultId: number;
  /** @format double */
  Lat?: number;
  /** @format double */
  Lng?: number;
  /** @format date-time */
  Created?: string;
}

export const PropsScriptWOCreateChecklistResultFileRequest = {
  ChecklistResultId: "ChecklistResultId",
  Lat: "Lat",
  Lng: "Lng",
  Created: "Created",
} as const;

/** Script_WO_UpdateChecklistResultComment_Request */
export interface Script_WO_UpdateChecklistResultComment_Request {
  /** @format  */
  ChecklistResultId: number;
  /** @format  */
  Comments: string;
}

export const PropsScriptWOUpdateChecklistResultCommentRequest = {
  ChecklistResultId: "ChecklistResultId",
  Comments: "Comments",
} as const;

/** Script_WO_UpdateComments_Request */
export interface Script_WO_UpdateComments_Request {
  /** @format  */
  WorkOrderId: number;
  /** @format  */
  Comments: string;
}

export const PropsScriptWOUpdateCommentsRequest = {
  WorkOrderId: "WorkOrderId",
  Comments: "Comments",
} as const;

/** Script_WO_UpdateProgress_Request */
export interface Script_WO_UpdateProgress_Request {
  /** @format  */
  BuildPlanId: number;
  /** @format  */
  Progress: number;
}

export const PropsScriptWOUpdateProgressRequest = {
  BuildPlanId: "BuildPlanId",
  Progress: "Progress",
} as const;

/** Script_WO_UpdateTask_Request */
export interface Script_WO_UpdateTask_Request {
  /** @format  */
  BuildPlanId: number;
  /** @format  */
  TaskId: number;
  /** @format boolean */
  IsCompleted: boolean;
  /** @format  */
  StatusText: string;
}

export const PropsScriptWOUpdateTaskRequest = {
  BuildPlanId: "BuildPlanId",
  TaskId: "TaskId",
  IsCompleted: "IsCompleted",
  StatusText: "StatusText",
} as const;

import { AxiosRequestConfig, ResponseType } from "axios";
import { BBPromise as BBPromiseInterface } from "../../interfaces";

interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: Record<string | number, any>;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

interface HttpClient {
  request: <T>({
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams) => BBPromiseInterface<T>;
}

/**
 * @title FieldClix API Description
 * @version v1
 */

export class Api_conterra {
  private readonly http: HttpClient;
  scripts = {
    /**
         * No description
         *
         * @tags Scripts
         * @name AssetsNewTransferChecklistResult
         * @request POST:/Script/ASSETS_NewTransferChecklistResult
         * @secure

         */
    assetsNewTransferChecklistResult: (
      data: Script_ASSETS_NewTransferChecklistResult_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/ASSETS_NewTransferChecklistResult`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name AssetsRequestChangeCondition
         * @request POST:/Script/ASSETS_RequestChangeCondition
         * @secure

         */
    assetsRequestChangeCondition: (
      data: Script_ASSETS_RequestChangeCondition_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/ASSETS_RequestChangeCondition`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name AssetsTransfer
         * @request POST:/Script/ASSETS_Transfer
         * @secure

         */
    assetsTransfer: (
      data: Script_ASSETS_Transfer_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/ASSETS_Transfer`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name CodCreate
         * @request POST:/Script/COD_Create
         * @secure

         */
    codCreate: (data: Script_COD_Create_Request, params: RequestParams = {}) =>
      this.http.request<string>({
        path: `/Script/COD_Create`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name CodDelete
         * @request POST:/Script/COD_Delete
         * @secure

         */
    codDelete: (data: Script_COD_Delete_Request, params: RequestParams = {}) =>
      this.http.request<string>({
        path: `/Script/COD_Delete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name CodNewDocument
         * @request POST:/Script/COD_NewDocument
         * @secure

         */
    codNewDocument: (
      data: Script_COD_NewDocument_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/COD_NewDocument`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name CodSubmit
         * @request POST:/Script/COD_Submit
         * @secure

         */
    codSubmit: (data: Script_COD_Submit_Request, params: RequestParams = {}) =>
      this.http.request<string>({
        path: `/Script/COD_Submit`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name DesktopFinancialDistributionShow
         * @request POST:/Script/DESKTOP_FinancialDistribution_Show
         * @secure

         */
    desktopFinancialDistributionShow: (
      data: Script_DESKTOP_FinancialDistribution_Show_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/DESKTOP_FinancialDistribution_Show`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name DesktopWhBomCreatePurchaseRequest
         * @request POST:/Script/DESKTOP_WH_BOM_CreatePurchaseRequest
         * @secure

         */
    desktopWhBomCreatePurchaseRequest: (
      data: Script_DESKTOP_WH_BOM_CreatePurchaseRequest_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/DESKTOP_WH_BOM_CreatePurchaseRequest`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name DevRaiseException
         * @request POST:/Script/DEV_RaiseException
         * @secure

         */
    devRaiseException: (
      data: Script_DEV_RaiseException_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/DEV_RaiseException`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name DevRaiseInformation
         * @request POST:/Script/DEV_RaiseInformation
         * @secure

         */
    devRaiseInformation: (
      data: Script_DEV_RaiseInformation_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/DEV_RaiseInformation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name DevRaiseSystemException
         * @request POST:/Script/DEV_RaiseSystemException
         * @secure

         */
    devRaiseSystemException: (
      data: Script_DEV_RaiseSystemException_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/DEV_RaiseSystemException`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name DevRaiseWarning
         * @request POST:/Script/DEV_RaiseWarning
         * @secure

         */
    devRaiseWarning: (
      data: Script_DEV_RaiseWarning_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/DEV_RaiseWarning`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name DocumentGetTempDocumentId
         * @request POST:/Script/Document_GetTempDocumentID
         * @secure

         */
    documentGetTempDocumentId: (
      data: Script_Document_GetTempDocumentID_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/Document_GetTempDocumentID`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name DocumentManageIsPublished
         * @request POST:/Script/Document_ManageIsPublished
         * @secure

         */
    documentManageIsPublished: (
      data: Script_Document_ManageIsPublished_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/Document_ManageIsPublished`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name DocumentsCreateNew
         * @request POST:/Script/Documents_CreateNew
         * @secure

         */
    documentsCreateNew: (
      data: Script_Documents_CreateNew_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/Documents_CreateNew`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name GetUrlForMobile
         * @request POST:/Script/GetURLForMobile
         * @secure

         */
    getUrlForMobile: (
      data: Script_GetURLForMobile_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/GetURLForMobile`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name InboxDeleteLinks
         * @request POST:/Script/Inbox_DeleteLinks
         * @secure

         */
    inboxDeleteLinks: (
      data: Script_Inbox_DeleteLinks_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/Inbox_DeleteLinks`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name InboxGetJobTreeHtml
         * @request POST:/Script/Inbox_GetJobTreeHTML
         * @secure

         */
    inboxGetJobTreeHtml: (
      data: Script_Inbox_GetJobTreeHTML_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/Inbox_GetJobTreeHTML`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name InboxMarkJobsAsRead
         * @request POST:/Script/Inbox_MarkJobsAsRead
         * @secure

         */
    inboxMarkJobsAsRead: (
      data: Script_Inbox_MarkJobsAsRead_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/Inbox_MarkJobsAsRead`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TestDescriptionScript
         * @request POST:/Script/TestDescriptionScript
         * @secure

         */
    testDescriptionScript: (
      data: Script_TestDescriptionScript_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TestDescriptionScript`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TestLongScript
         * @request POST:/Script/TestLongScript
         * @secure

         */
    testLongScript: (
      data: Script_TestLongScript_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TestLongScript`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TestStringScript
         * @request POST:/Script/TestStringScript
         * @secure

         */
    testStringScript: (
      data: Script_TestStringScript_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TestStringScript`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkAllocateTime
         * @request POST:/Script/TK_AllocateTime
         * @secure

         */
    tkAllocateTime: (
      data: Script_TK_AllocateTime_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_AllocateTime`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkCheckIn
         * @request POST:/Script/TK_CheckIn
         * @secure

         */
    tkCheckIn: (data: Script_TK_CheckIn_Request, params: RequestParams = {}) =>
      this.http.request<string>({
        path: `/Script/TK_CheckIn`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkClockIn
         * @request POST:/Script/TK_ClockIn
         * @secure

         */
    tkClockIn: (data: Script_TK_ClockIn_Request, params: RequestParams = {}) =>
      this.http.request<string>({
        path: `/Script/TK_ClockIn`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkClockOut
         * @request POST:/Script/TK_ClockOut
         * @secure

         */
    tkClockOut: (
      data: Script_TK_ClockOut_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_ClockOut`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkCreateLunch
         * @request POST:/Script/TK_CreateLunch
         * @secure

         */
    tkCreateLunch: (
      data: Script_TK_CreateLunch_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_CreateLunch`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkDeleteLunch
         * @request POST:/Script/TK_DeleteLunch
         * @secure

         */
    tkDeleteLunch: (
      data: Script_TK_DeleteLunch_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_DeleteLunch`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkFinishLunch
         * @request POST:/Script/TK_FinishLunch
         * @secure

         */
    tkFinishLunch: (
      data: Script_TK_FinishLunch_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_FinishLunch`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkFinishManual
         * @request POST:/Script/TK_FinishManual
         * @secure

         */
    tkFinishManual: (
      data: Script_TK_FinishManual_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_FinishManual`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkNewRequest
         * @request POST:/Script/TK_NewRequest
         * @secure

         */
    tkNewRequest: (
      data: Script_TK_NewRequest_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_NewRequest`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkRevokeRequest
         * @request POST:/Script/TK_RevokeRequest
         * @secure

         */
    tkRevokeRequest: (
      data: Script_TK_RevokeRequest_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_RevokeRequest`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkSendComment
         * @request POST:/Script/TK_SendComment
         * @secure

         */
    tkSendComment: (
      data: Script_TK_SendComment_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_SendComment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkSetTimeEntryLocation
         * @request POST:/Script/TK_SetTimeEntryLocation
         * @secure

         */
    tkSetTimeEntryLocation: (
      data: Script_TK_SetTimeEntryLocation_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_SetTimeEntryLocation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkStartManual
         * @request POST:/Script/TK_StartManual
         * @secure

         */
    tkStartManual: (
      data: Script_TK_StartManual_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_StartManual`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name TkWaiveLunch
         * @request POST:/Script/TK_WaiveLunch
         * @secure

         */
    tkWaiveLunch: (
      data: Script_TK_WaiveLunch_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/TK_WaiveLunch`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WhBomGetBuildPlanWarehouse
         * @request POST:/Script/WH_BOM_GetBuildPlanWarehouse
         * @secure

         */
    whBomGetBuildPlanWarehouse: (
      data: Script_WH_BOM_GetBuildPlanWarehouse_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WH_BOM_GetBuildPlanWarehouse`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WhBomPullMaterials
         * @request POST:/Script/WH_BOMPullMaterials
         * @secure

         */
    whBomPullMaterials: (
      data: Script_WH_BOMPullMaterials_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WH_BOMPullMaterials`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WhBomReturnMaterial
         * @request POST:/Script/WH_BOMReturnMaterial
         * @secure

         */
    whBomReturnMaterial: (
      data: Script_WH_BOMReturnMaterial_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WH_BOMReturnMaterial`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WhPushMaterialItems
         * @request POST:/Script/WH_PushMaterialItems
         * @secure

         */
    whPushMaterialItems: (
      data: Script_WH_PushMaterialItems_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WH_PushMaterialItems`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WhRemoveVendorFromMaterial
         * @request POST:/Script/WH_RemoveVendorFromMaterial
         * @secure

         */
    whRemoveVendorFromMaterial: (
      data: Script_WH_RemoveVendorFromMaterial_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WH_RemoveVendorFromMaterial`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WhReturnMaterialItems
         * @request POST:/Script/WH_ReturnMaterialItems
         * @secure

         */
    whReturnMaterialItems: (
      data: Script_WH_ReturnMaterialItems_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WH_ReturnMaterialItems`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WhUpsertMaterial
         * @request POST:/Script/WH_UpsertMaterial
         * @secure

         */
    whUpsertMaterial: (
      data: Script_WH_UpsertMaterial_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WH_UpsertMaterial`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WhUpsertMaterialVendor
         * @request POST:/Script/WH_UpsertMaterialVendor
         * @secure

         */
    whUpsertMaterialVendor: (
      data: Script_WH_UpsertMaterialVendor_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WH_UpsertMaterialVendor`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WoComplete
         * @request POST:/Script/WO_Complete
         * @secure

         */
    woComplete: (
      data: Script_WO_Complete_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WO_Complete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WoCreateChecklist
         * @request POST:/Script/WO_CreateChecklist
         * @secure

         */
    woCreateChecklist: (
      data: Script_WO_CreateChecklist_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WO_CreateChecklist`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WoCreateChecklistResult
         * @request POST:/Script/WO_CreateChecklistResult
         * @secure

         */
    woCreateChecklistResult: (
      data: Script_WO_CreateChecklistResult_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WO_CreateChecklistResult`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WoCreateChecklistResultFile
         * @request POST:/Script/WO_CreateChecklistResultFile
         * @secure

         */
    woCreateChecklistResultFile: (
      data: Script_WO_CreateChecklistResultFile_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WO_CreateChecklistResultFile`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WoUpdateChecklistResultComment
         * @request POST:/Script/WO_UpdateChecklistResultComment
         * @secure

         */
    woUpdateChecklistResultComment: (
      data: Script_WO_UpdateChecklistResultComment_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WO_UpdateChecklistResultComment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WoUpdateComments
         * @request POST:/Script/WO_UpdateComments
         * @secure

         */
    woUpdateComments: (
      data: Script_WO_UpdateComments_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WO_UpdateComments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WoUpdateProgress
         * @request POST:/Script/WO_UpdateProgress
         * @secure

         */
    woUpdateProgress: (
      data: Script_WO_UpdateProgress_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WO_UpdateProgress`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
         * No description
         *
         * @tags Scripts
         * @name WoUpdateTask
         * @request POST:/Script/WO_UpdateTask
         * @secure

         */
    woUpdateTask: (
      data: Script_WO_UpdateTask_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<string>({
        path: `/Script/WO_UpdateTask`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  sql = {
    /**
         * No description
         *
         * @tags SQL
         * @name AssetsAllowedHolders
         * @request POST:/SQL/ASSETS_AllowedHolders
         * @secure

         */
    assetsAllowedHolders: (params: RequestParams = {}) =>
      this.http.request<SQL_ASSETS_AllowedHolders_Response[]>({
        path: `/SQL/ASSETS_AllowedHolders`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name AssetsMy
         * @request POST:/SQL/ASSETS_My
         * @secure

         */
    assetsMy: (params: RequestParams = {}) =>
      this.http.request<SQL_ASSETS_My_Response[]>({
        path: `/SQL/ASSETS_My`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name AssetsTransferChecklist
         * @request POST:/SQL/ASSETS_TransferChecklist
         * @secure

         */
    assetsTransferChecklist: (
      data: SQL_ASSETS_TransferChecklist_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_ASSETS_TransferChecklist_Response[]>({
        path: `/SQL/ASSETS_TransferChecklist`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name ClpmCreateZipLink
         * @request POST:/SQL/CLPM_CreateZipLink
         * @secure

         */
    clpmCreateZipLink: (
      data: SQL_CLPM_CreateZipLink_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_CLPM_CreateZipLink_Response[]>({
        path: `/SQL/CLPM_CreateZipLink`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name CodForNewRequest
         * @request POST:/SQL/COD_ForNewRequest
         * @secure

         */
    codForNewRequest: (params: RequestParams = {}) =>
      this.http.request<SQL_COD_ForNewRequest_Response[]>({
        path: `/SQL/COD_ForNewRequest`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name CodMy
         * @request POST:/SQL/COD_My
         * @secure

         */
    codMy: (params: RequestParams = {}) =>
      this.http.request<SQL_COD_My_Response[]>({
        path: `/SQL/COD_My`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbAudits
         * @request POST:/SQL/DB_Audits
         * @secure

         */
    dbAudits: (data: SQL_DB_Audits_Request, params: RequestParams = {}) =>
      this.http.request<SQL_DB_Audits_Response[]>({
        path: `/SQL/DB_Audits`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbCalendarDispatchInfo
         * @request POST:/SQL/DB_Calendar_DispatchInfo
         * @secure

         */
    dbCalendarDispatchInfo: (
      data: SQL_DB_Calendar_DispatchInfo_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_Calendar_DispatchInfo_Response[]>({
        path: `/SQL/DB_Calendar_DispatchInfo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbCalendarGetData
         * @request POST:/SQL/DB_Calendar_GetData
         * @secure

         */
    dbCalendarGetData: (
      data: SQL_DB_Calendar_GetData_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_Calendar_GetData_Response[]>({
        path: `/SQL/DB_Calendar_GetData`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbCalendarGetDataDashboard
         * @request POST:/SQL/DB_Calendar_GetData_Dashboard
         * @secure

         */
    dbCalendarGetDataDashboard: (
      data: SQL_DB_Calendar_GetData_Dashboard_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_Calendar_GetData_Dashboard_Response[]>({
        path: `/SQL/DB_Calendar_GetData_Dashboard`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbCalendarResources
         * @request POST:/SQL/DB_Calendar_Resources
         * @secure

         */
    dbCalendarResources: (
      data: SQL_DB_Calendar_Resources_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_Calendar_Resources_Response[]>({
        path: `/SQL/DB_Calendar_Resources`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbCalendarWoCertificateInfo
         * @request POST:/SQL/DB_Calendar_WOCertificateInfo
         * @secure

         */
    dbCalendarWoCertificateInfo: (
      data: SQL_DB_Calendar_WOCertificateInfo_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_Calendar_WOCertificateInfo_Response[]>({
        path: `/SQL/DB_Calendar_WOCertificateInfo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbGetCounters
         * @request POST:/SQL/DB_GetCounters
         * @secure

         */
    dbGetCounters: (params: RequestParams = {}) =>
      this.http.request<SQL_DB_GetCounters_Response[]>({
        path: `/SQL/DB_GetCounters`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbInbox
         * @request POST:/SQL/DB_Inbox
         * @secure

         */
    dbInbox: (params: RequestParams = {}) =>
      this.http.request<SQL_DB_Inbox_Response[]>({
        path: `/SQL/DB_Inbox`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbInboxGetJobInfo
         * @request POST:/SQL/DB_Inbox_GetJobInfo
         * @secure

         */
    dbInboxGetJobInfo: (
      data: SQL_DB_Inbox_GetJobInfo_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_Inbox_GetJobInfo_Response[]>({
        path: `/SQL/DB_Inbox_GetJobInfo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbShortCuts
         * @request POST:/SQL/DB_ShortCuts
         * @secure

         */
    dbShortCuts: (params: RequestParams = {}) =>
      this.http.request<SQL_DB_ShortCuts_Response[]>({
        path: `/SQL/DB_ShortCuts`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbStatus
         * @request POST:/SQL/DB_Status
         * @secure

         */
    dbStatus: (data: SQL_DB_Status_Request, params: RequestParams = {}) =>
      this.http.request<SQL_DB_Status_Response[]>({
        path: `/SQL/DB_Status`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbStatusMilestones
         * @request POST:/SQL/DB_Status_Milestones
         * @secure

         */
    dbStatusMilestones: (params: RequestParams = {}) =>
      this.http.request<SQL_DB_Status_Milestones_Response[]>({
        path: `/SQL/DB_Status_Milestones`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbTimeCardTimeDetails
         * @request POST:/SQL/DB_TimeCardTimeDetails
         * @secure

         */
    dbTimeCardTimeDetails: (
      data: SQL_DB_TimeCardTimeDetails_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_TimeCardTimeDetails_Response[]>({
        path: `/SQL/DB_TimeCardTimeDetails`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbTkGetTcAdjustmentInfo
         * @request POST:/SQL/DB_TK_GetTCAdjustmentInfo
         * @secure

         */
    dbTkGetTcAdjustmentInfo: (
      data: SQL_DB_TK_GetTCAdjustmentInfo_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_TK_GetTCAdjustmentInfo_Response[]>({
        path: `/SQL/DB_TK_GetTCAdjustmentInfo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbTkGetTcSummary
         * @request POST:/SQL/DB_TK_GetTCSummary
         * @secure

         */
    dbTkGetTcSummary: (
      data: SQL_DB_TK_GetTCSummary_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_TK_GetTCSummary_Response[]>({
        path: `/SQL/DB_TK_GetTCSummary`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbTkGetTimeCardAudits
         * @request POST:/SQL/DB_TK_GetTimeCardAudits
         * @secure

         */
    dbTkGetTimeCardAudits: (
      data: SQL_DB_TK_GetTimeCardAudits_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_TK_GetTimeCardAudits_Response[]>({
        path: `/SQL/DB_TK_GetTimeCardAudits`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbTkReview
         * @request POST:/SQL/DB_TK_Review
         * @secure

         */
    dbTkReview: (data: SQL_DB_TK_Review_Request, params: RequestParams = {}) =>
      this.http.request<SQL_DB_TK_Review_Response[]>({
        path: `/SQL/DB_TK_Review`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name DbTkTimeCardsForCompare
         * @request POST:/SQL/DB_TK_TimeCardsForCompare
         * @secure

         */
    dbTkTimeCardsForCompare: (
      data: SQL_DB_TK_TimeCardsForCompare_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_DB_TK_TimeCardsForCompare_Response[]>({
        path: `/SQL/DB_TK_TimeCardsForCompare`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name GetInfoTkTimeCards
         * @request POST:/SQL/GetInfo_TKTimeCards
         * @secure

         */
    getInfoTkTimeCards: (
      data: SQL_GetInfo_TKTimeCards_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_GetInfo_TKTimeCards_Response[]>({
        path: `/SQL/GetInfo_TKTimeCards`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name MapGetAllAddresses
         * @request POST:/SQL/Map_GetAllAddresses
         * @secure

         */
    mapGetAllAddresses: (
      data: SQL_Map_GetAllAddresses_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_Map_GetAllAddresses_Response[]>({
        path: `/SQL/Map_GetAllAddresses`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name MapGetIncompleteWoSites
         * @request POST:/SQL/Map_GetIncompleteWOSites
         * @secure

         */
    mapGetIncompleteWoSites: (
      data: SQL_Map_GetIncompleteWOSites_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_Map_GetIncompleteWOSites_Response[]>({
        path: `/SQL/Map_GetIncompleteWOSites`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name ProfileMyCertificates
         * @request POST:/SQL/Profile_MyCertificates
         * @secure

         */
    profileMyCertificates: (params: RequestParams = {}) =>
      this.http.request<SQL_Profile_MyCertificates_Response[]>({
        path: `/SQL/Profile_MyCertificates`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name ProfileMyInfo
         * @request POST:/SQL/Profile_MyInfo
         * @secure

         */
    profileMyInfo: (params: RequestParams = {}) =>
      this.http.request<SQL_Profile_MyInfo_Response[]>({
        path: `/SQL/Profile_MyInfo`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name ProfileMyRoles
         * @request POST:/SQL/Profile_MyRoles
         * @secure

         */
    profileMyRoles: (params: RequestParams = {}) =>
      this.http.request<SQL_Profile_MyRoles_Response[]>({
        path: `/SQL/Profile_MyRoles`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name Settings
         * @request POST:/SQL/Settings
         * @secure

         */
    settings: (params: RequestParams = {}) =>
      this.http.request<SQL_Settings_Response[]>({
        path: `/SQL/Settings`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkAttestations
         * @request POST:/SQL/TK_Attestations
         * @secure

         */
    tkAttestations: (params: RequestParams = {}) =>
      this.http.request<SQL_TK_Attestations_Response[]>({
        path: `/SQL/TK_Attestations`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkCheckInStatus
         * @request POST:/SQL/TK_CheckInStatus
         * @secure

         */
    tkCheckInStatus: (params: RequestParams = {}) =>
      this.http.request<SQL_TK_CheckInStatus_Response[]>({
        path: `/SQL/TK_CheckInStatus`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkClockInStatus
         * @request POST:/SQL/TK_ClockInStatus
         * @secure

         */
    tkClockInStatus: (
      data: SQL_TK_ClockInStatus_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_ClockInStatus_Response[]>({
        path: `/SQL/TK_ClockInStatus`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkGetActivePayrollPeriods
         * @request POST:/SQL/TK_GetActivePayrollPeriods
         * @secure

         */
    tkGetActivePayrollPeriods: (
      data: SQL_TK_GetActivePayrollPeriods_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_GetActivePayrollPeriods_Response[]>({
        path: `/SQL/TK_GetActivePayrollPeriods`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkGetAvailableWOs
         * @request POST:/SQL/TK_GetAvailableWOs
         * @secure

         */
    tkGetAvailableWOs: (
      data: SQL_TK_GetAvailableWOs_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_GetAvailableWOs_Response[]>({
        path: `/SQL/TK_GetAvailableWOs`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkGetDataForAdjustment
         * @request POST:/SQL/TK_GetDataForAdjustment
         * @secure

         */
    tkGetDataForAdjustment: (
      data: SQL_TK_GetDataForAdjustment_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_GetDataForAdjustment_Response[]>({
        path: `/SQL/TK_GetDataForAdjustment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkGetDispatches
         * @request POST:/SQL/TK_GetDispatches
         * @secure

         */
    tkGetDispatches: (
      data: SQL_TK_GetDispatches_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_GetDispatches_Response[]>({
        path: `/SQL/TK_GetDispatches`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkGetRequestAllocation
         * @request POST:/SQL/TK_GetRequestAllocation
         * @secure

         */
    tkGetRequestAllocation: (
      data: SQL_TK_GetRequestAllocation_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_GetRequestAllocation_Response[]>({
        path: `/SQL/TK_GetRequestAllocation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkGetRequestData
         * @request POST:/SQL/TK_GetRequestData
         * @secure

         */
    tkGetRequestData: (
      data: SQL_TK_GetRequestData_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_GetRequestData_Response[]>({
        path: `/SQL/TK_GetRequestData`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkGetResources
         * @request POST:/SQL/TK_GetResources
         * @secure

         */
    tkGetResources: (params: RequestParams = {}) =>
      this.http.request<SQL_TK_GetResources_Response[]>({
        path: `/SQL/TK_GetResources`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkGetReviewers
         * @request POST:/SQL/TK_GetReviewers
         * @secure

         */
    tkGetReviewers: (params: RequestParams = {}) =>
      this.http.request<SQL_TK_GetReviewers_Response[]>({
        path: `/SQL/TK_GetReviewers`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkGetSiteData
         * @request POST:/SQL/TK_GetSiteData
         * @secure

         */
    tkGetSiteData: (
      data: SQL_TK_GetSiteData_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_GetSiteData_Response[]>({
        path: `/SQL/TK_GetSiteData`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkGetTcAttestations
         * @request POST:/SQL/TK_GetTCAttestations
         * @secure

         */
    tkGetTcAttestations: (
      data: SQL_TK_GetTCAttestations_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_GetTCAttestations_Response[]>({
        path: `/SQL/TK_GetTCAttestations`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkMyCalendar
         * @request POST:/SQL/TK_MyCalendar
         * @secure

         */
    tkMyCalendar: (params: RequestParams = {}) =>
      this.http.request<SQL_TK_MyCalendar_Response[]>({
        path: `/SQL/TK_MyCalendar`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkMyHoursBalance
         * @request POST:/SQL/TK_MyHoursBalance
         * @secure

         */
    tkMyHoursBalance: (params: RequestParams = {}) =>
      this.http.request<SQL_TK_MyHoursBalance_Response[]>({
        path: `/SQL/TK_MyHoursBalance`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkMyPayrollPeriods
         * @request POST:/SQL/TK_MyPayrollPeriods
         * @secure

         */
    tkMyPayrollPeriods: (params: RequestParams = {}) =>
      this.http.request<SQL_TK_MyPayrollPeriods_Response[]>({
        path: `/SQL/TK_MyPayrollPeriods`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkMySolicitations
         * @request POST:/SQL/TK_MySolicitations
         * @secure

         */
    tkMySolicitations: (params: RequestParams = {}) =>
      this.http.request<SQL_TK_MySolicitations_Response[]>({
        path: `/SQL/TK_MySolicitations`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkMyTimeCard
         * @request POST:/SQL/TK_MyTimeCard
         * @secure

         */
    tkMyTimeCard: (
      data: SQL_TK_MyTimeCard_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_MyTimeCard_Response[]>({
        path: `/SQL/TK_MyTimeCard`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkRequestAvaliablePayrollItems
         * @request POST:/SQL/TK_RequestAvaliablePayrollItems
         * @secure

         */
    tkRequestAvaliablePayrollItems: (params: RequestParams = {}) =>
      this.http.request<SQL_TK_RequestAvaliablePayrollItems_Response[]>({
        path: `/SQL/TK_RequestAvaliablePayrollItems`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkRequestAvaliableReasons
         * @request POST:/SQL/TK_RequestAvaliableReasons
         * @secure

         */
    tkRequestAvaliableReasons: (params: RequestParams = {}) =>
      this.http.request<SQL_TK_RequestAvaliableReasons_Response[]>({
        path: `/SQL/TK_RequestAvaliableReasons`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkTimeCardAvailableWorkOrders
         * @request POST:/SQL/TK_TimeCardAvailableWorkOrders
         * @secure

         */
    tkTimeCardAvailableWorkOrders: (
      data: SQL_TK_TimeCardAvailableWorkOrders_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_TimeCardAvailableWorkOrders_Response[]>({
        path: `/SQL/TK_TimeCardAvailableWorkOrders`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name TkTimeCardsToAllocate
         * @request POST:/SQL/TK_TimeCardsToAllocate
         * @secure

         */
    tkTimeCardsToAllocate: (
      data: SQL_TK_TimeCardsToAllocate_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_TK_TimeCardsToAllocate_Response[]>({
        path: `/SQL/TK_TimeCardsToAllocate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhAsset
         * @request POST:/SQL/WH_Asset
         * @secure

         */
    whAsset: (data: SQL_WH_Asset_Request, params: RequestParams = {}) =>
      this.http.request<SQL_WH_Asset_Response[]>({
        path: `/SQL/WH_Asset`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhAssets
         * @request POST:/SQL/WH_Assets
         * @secure

         */
    whAssets: (data: SQL_WH_Assets_Request, params: RequestParams = {}) =>
      this.http.request<SQL_WH_Assets_Response[]>({
        path: `/SQL/WH_Assets`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhBomAvailableBuildPlans
         * @request POST:/SQL/WH_BOM_AvailableBuildPlans
         * @secure

         */
    whBomAvailableBuildPlans: (params: RequestParams = {}) =>
      this.http.request<SQL_WH_BOM_AvailableBuildPlans_Response[]>({
        path: `/SQL/WH_BOM_AvailableBuildPlans`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhBomAvailableMaterials
         * @request POST:/SQL/WH_BOM_AvailableMaterials
         * @secure

         */
    whBomAvailableMaterials: (
      data: SQL_WH_BOM_AvailableMaterials_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WH_BOM_AvailableMaterials_Response[]>({
        path: `/SQL/WH_BOM_AvailableMaterials`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhBomAvailableTemplates
         * @request POST:/SQL/WH_BOM_AvailableTemplates
         * @secure

         */
    whBomAvailableTemplates: (
      data: SQL_WH_BOM_AvailableTemplates_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WH_BOM_AvailableTemplates_Response[]>({
        path: `/SQL/WH_BOM_AvailableTemplates`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhBomAvailableWarehouses
         * @request POST:/SQL/WH_BOM_AvailableWarehouses
         * @secure

         */
    whBomAvailableWarehouses: (params: RequestParams = {}) =>
      this.http.request<SQL_WH_BOM_AvailableWarehouses_Response[]>({
        path: `/SQL/WH_BOM_AvailableWarehouses`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhMatAvailableVendors
         * @request POST:/SQL/WH_MAT_AvailableVendors
         * @secure

         */
    whMatAvailableVendors: (
      data: SQL_WH_MAT_AvailableVendors_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WH_MAT_AvailableVendors_Response[]>({
        path: `/SQL/WH_MAT_AvailableVendors`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhMaterial
         * @request POST:/SQL/WH_Material
         * @secure

         */
    whMaterial: (data: SQL_WH_Material_Request, params: RequestParams = {}) =>
      this.http.request<SQL_WH_Material_Response[]>({
        path: `/SQL/WH_Material`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhMaterialFieldsOptions
         * @request POST:/SQL/WH_MaterialFieldsOptions
         * @secure

         */
    whMaterialFieldsOptions: (params: RequestParams = {}) =>
      this.http.request<SQL_WH_MaterialFieldsOptions_Response[]>({
        path: `/SQL/WH_MaterialFieldsOptions`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhMaterialsList
         * @request POST:/SQL/WH_MaterialsList
         * @secure

         */
    whMaterialsList: (
      data: SQL_WH_MaterialsList_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WH_MaterialsList_Response[]>({
        path: `/SQL/WH_MaterialsList`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhMiAvailableBuildPlans
         * @request POST:/SQL/WH_MI_AvailableBuildPlans
         * @secure

         */
    whMiAvailableBuildPlans: (params: RequestParams = {}) =>
      this.http.request<SQL_WH_MI_AvailableBuildPlans_Response[]>({
        path: `/SQL/WH_MI_AvailableBuildPlans`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhMiAvailableMaterials
         * @request POST:/SQL/WH_MI_AvailableMaterials
         * @secure

         */
    whMiAvailableMaterials: (
      data: SQL_WH_MI_AvailableMaterials_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WH_MI_AvailableMaterials_Response[]>({
        path: `/SQL/WH_MI_AvailableMaterials`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhMiAvailableToAssignToBp
         * @request POST:/SQL/WH_MI_AvailableToAssignToBP
         * @secure

         */
    whMiAvailableToAssignToBp: (
      data: SQL_WH_MI_AvailableToAssignToBP_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WH_MI_AvailableToAssignToBP_Response[]>({
        path: `/SQL/WH_MI_AvailableToAssignToBP`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhMiAvailableVendors
         * @request POST:/SQL/WH_MI_AvailableVendors
         * @secure

         */
    whMiAvailableVendors: (
      data: SQL_WH_MI_AvailableVendors_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WH_MI_AvailableVendors_Response[]>({
        path: `/SQL/WH_MI_AvailableVendors`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhMiDashboard
         * @request POST:/SQL/WH_MI_Dashboard
         * @secure

         */
    whMiDashboard: (
      data: SQL_WH_MI_Dashboard_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WH_MI_Dashboard_Response[]>({
        path: `/SQL/WH_MI_Dashboard`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WhMyWarehouses
         * @request POST:/SQL/WH_MyWarehouses
         * @secure

         */
    whMyWarehouses: (params: RequestParams = {}) =>
      this.http.request<SQL_WH_MyWarehouses_Response[]>({
        path: `/SQL/WH_MyWarehouses`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WoChecklistResult
         * @request POST:/SQL/WO_ChecklistResult
         * @secure

         */
    woChecklistResult: (
      data: SQL_WO_ChecklistResult_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WO_ChecklistResult_Response[]>({
        path: `/SQL/WO_ChecklistResult`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WoChecklists
         * @request POST:/SQL/WO_Checklists
         * @secure

         */
    woChecklists: (
      data: SQL_WO_Checklists_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WO_Checklists_Response[]>({
        path: `/SQL/WO_Checklists`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WoChecklistTemplates
         * @request POST:/SQL/WO_ChecklistTemplates
         * @secure

         */
    woChecklistTemplates: (
      data: SQL_WO_ChecklistTemplates_Request,
      params: RequestParams = {}
    ) =>
      this.http.request<SQL_WO_ChecklistTemplates_Response[]>({
        path: `/SQL/WO_ChecklistTemplates`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WoMy
         * @request POST:/SQL/WO_My
         * @secure

         */
    woMy: (params: RequestParams = {}) =>
      this.http.request<SQL_WO_My_Response[]>({
        path: `/SQL/WO_My`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
         * No description
         *
         * @tags SQL
         * @name WoTasks
         * @request POST:/SQL/WO_Tasks
         * @secure

         */
    woTasks: (data: SQL_WO_Tasks_Request, params: RequestParams = {}) =>
      this.http.request<SQL_WO_Tasks_Response[]>({
        path: `/SQL/WO_Tasks`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };

  constructor(http: HttpClient) {
    this.http = http;
  }
}
